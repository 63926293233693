import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, fontFamily, winterColor } from 'variables/_index'
import WinterContactButton from './atom/winterContactButton'
import WinterDocumentButton from './atom/winterDocumentButton'
import { siteStructure } from 'utils/site'
import { QuoteLeft, QuoteRight } from 'components/icon'

export interface ContactSectionLastProps {
  readonly balloonImageElement: JSX.Element
  readonly headImageElement: JSX.Element
}

type ContactSectionLastPropsClassName = ContactSectionLastProps & {
  readonly className: string
}

function Component({ className, balloonImageElement, headImageElement }: ContactSectionLastPropsClassName) {
  return (
    <div className={className}>
      <div className="c-illust">{headImageElement}</div>
      <div className="c-contact-area">
        <div className="l-inner">
          <p className="c-lead-text">
            冬期講習
            <span className="is-color">
              <span className="is-num">4</span>日間
            </span>
            を
          </p>
          <div className="c-main-text">
            <QuoteLeft className="c-main-text__quote-left" color={'#333'} />
            <p className="c-main-text__content">
              <span className="is-em">無料</span>で<span className="is-em">体験!</span>
            </p>
            <QuoteRight className="c-main-text__quote-right" color={'#333'} />
          </div>
          <div className="c-buttons">
            <div className="c-buttons__item">
              <WinterContactButton href={siteStructure.summerContact.path} imageElement={balloonImageElement} />
            </div>
            <div className="c-buttons__item">
              <WinterDocumentButton href={siteStructure.pamphlet1.path} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<ContactSectionLastPropsClassName>`
  text-align: center;
  padding-top: ${rem(30)};
  position: relative;

  @media ${breakpoints.lg} {
    padding-top: ${rem(40)};
  }

  .c-illust {
    position: relative;
    z-index: 2;

    img {
    }

    @media ${breakpoints.lg} {
    }
  }

  .l-inner {
    width: ${rem(300)};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(576.13)};
    }
  }

  .c-lead-text {
    font-size: ${rem(20)};
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1;
    text-align: center;
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(32)};
    }

    .is-color {
      color: ${winterColor.main};
    }

    .is-num {
      font-size: ${rem(30)};

      @media ${breakpoints.lg} {
        font-size: ${rem(52)};
      }
    }
  }

  .c-main-text {
    letter-spacing: 0.1em;
    font-size: ${rem(23)};
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: ${rem(4)};

    @media ${breakpoints.lg} {
      font-size: ${rem(36)};
      margin-top: ${rem(8)};
    }
  }

  .c-main-text__content {
    margin: 0;

    .is-em {
      font-size: ${rem(30)};
      color: ${winterColor.main};

      @media ${breakpoints.lg} {
        font-size: ${rem(48)};
      }
    }
  }

  .c-main-text__quote-left,
  .c-main-text__quote-right {
    margin-top: ${rem(10)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(14)};
    }
  }

  .c-main-text__quote-left {
    margin-right: ${rem(20 / 2)};
  }

  .c-main-text__quote-right {
    margin-left: ${rem(20 / 2)};
  }

  .c-buttons {
    margin-top: ${rem(12)};
    display: flex;
    flex-direction: column;
    gap: ${rem(10)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
      gap: ${rem(16)};
    }
  }

  .c-buttons__item {
  }

  .c-contact-area {
    margin-top: ${rem(-10)};
    background-color: ${winterColor.accent};
    padding-top: ${rem(20)};
    padding-bottom: ${rem(30)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(-40)};
      padding-top: ${rem(50)};
      padding-bottom: ${rem(40)};
    }
  }
`

export const ContactSectionLast: (props: ContactSectionLastProps) => JSX.Element = StyledComponent

export default ContactSectionLast
