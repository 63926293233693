import React from 'react'
import styled from 'styled-components'
import { breakpoints, rem, innerSeasonWidth, fontFamily } from 'variables/_index'
import HeadImageSP from 'assets/images/winter/campaign/head_sp.svg'
import HeadImagePC from 'assets/images/winter/campaign/head_pc.svg'
import SnowImageSP from 'assets/images/winter/campaign/snow_sp.svg'

export interface CampaignSectionProps {
  readonly npProps?: string
}

type CampaignSectionPropsClassName = CampaignSectionProps & {
  readonly className: string
}

function Component({ className }: CampaignSectionPropsClassName) {
  return (
    <div className={className}>
      <h2 className="c-logo">
        <picture>
          <source media="(min-width: 46.5rem)" srcSet={HeadImagePC} />
          <img className="c-logo__image" src={HeadImageSP} alt="今だけ 冬の特別キャンペーン！" width="241.16" height="146.31" />
        </picture>
      </h2>
      <div className="c-items">
        <div className="c-items__card">
          <h3 className="c-head">特典1</h3>
          <p className="c-box">
            <span className="c-box__text">
              <span className="is-color">
                <span className="is-number">4</span>日間
              </span>
              <span className="is-small">の</span>冬期講習<span className="is-small">の</span>授業<span className="is-small">を</span>
              <br />
              <span className="is-bold">
                <span className="is-color">
                  <span className="is-large">無料</span>
                  <span className="is-middle2">で</span>
                  <span className="is-large">体験</span>
                </span>
                <span className="is-middle">できる!</span>
              </span>
            </span>
          </p>
        </div>
        <div className="c-items__card">
          <h3 className="c-head is-second">特典2</h3>
          <p className="c-box">
            <span className="c-box__text">
              講習受講後<span className="is-small">の</span>入会<span className="is-small">で</span>
              <br />
              <span className="is-bold">
                <span className="is-small2">入会金22,000円を</span>
                <span className="is-color">
                  <span className="is-large">全額免除!</span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CampaignSectionPropsClassName>`
  padding-top: ${rem(80)};
  padding-bottom: ${rem(50)};
  position: relative;
  width: ${innerSeasonWidth.sm};
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media ${breakpoints.lg} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
    width: ${rem(1080)};
    padding-left: ${rem(40)};
    padding-right: ${rem(40)};
  }

  .c-logo {
  }

  .c-logo__image {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: ${rem(244)};

    @media ${breakpoints.lg} {
      width: ${rem(618.31)};
    }
  }

  .c-head {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(24)};
    letter-spacing: 0.3em;
    text-indent: 0.3em;
    line-height: 1.4;
    text-align: center;
    color: #ff3232;
    margin-top: ${rem(15)};
    margin-bottom: ${rem(10)};

    &::before,
    &::after {
      content: '';
      width: ${rem(18)};
      height: ${rem(15.6)};
      background: url(${SnowImageSP}) repeat center center / contain;

      @media ${breakpoints.lg} {
        width: ${rem(27.1)};
        height: ${rem(23.49)};
      }
    }

    &::before {
      margin-right: ${rem(6)};
    }

    &::after {
      margin-left: ${rem(6)};
    }

    @media ${breakpoints.lg} {
      margin-top: ${rem(0)};
      font-size: ${rem(32)};
    }
  }

  .c-box {
    margin: 0;
    font-family: ${fontFamily.zenKaku};
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1.4;
    border: 1.5px solid #ff3232;
    text-align: center;
    padding: ${rem(15)};
    border-radius: ${rem(10)};
    font-size: ${rem(20)};
    min-height: ${rem(118)};
    display: grid;
    place-items: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(26)};
      min-height: ${rem(156)};
      border-radius: ${rem(20)};
      border-width: 2px;
    }

    .is-bold {
      font-weight: 700;
    }

    .is-color {
      color: #ff3232;
    }

    .is-number {
      font-size: ${rem(30)};
      font-weight: 700;

      @media ${breakpoints.lg} {
        font-size: ${rem(40)};
      }
    }

    .is-small {
      font-size: ${rem(16)};

      @media ${breakpoints.lg} {
        font-size: ${rem(20)};
      }
    }

    .is-small2 {
      font-size: ${rem(17)};

      @media ${breakpoints.lg} {
        font-size: ${rem(22)};
      }
    }

    .is-middle {
      font-size: ${rem(22)};

      @media ${breakpoints.lg} {
        font-size: ${rem(30)};
      }
    }

    .is-middle2 {
      font-size: ${rem(24)};

      @media ${breakpoints.lg} {
        font-size: ${rem(30)};
      }
    }

    .is-large {
      font-size: ${rem(30)};

      @media ${breakpoints.lg} {
        font-size: ${rem(40)};
      }
    }
  }

  .c-items {
    @media ${breakpoints.lg} {
      display: flex;
      gap: ${rem(40)};
      margin-top: ${rem(24)};
    }
  }

  .c-items__card {
    @media ${breakpoints.lg} {
      flex: 1;
    }
  }
`

export const CampaignSection: (props: CampaignSectionProps) => JSX.Element = StyledComponent

export default CampaignSection
