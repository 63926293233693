import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, winterColor, fontFamily, breakpoints, innerSeasonWidth } from 'variables/_index'
import HeadingTab from '../atom/headingTab'
import OverviewSection from 'components/section/winter/overviewJuniorSection'
import CourseJuniorHighSchoolCalc from './courseJuniorHighSchoolCalc'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import TeacherCard from '../atom/teacherCard'
import ArrowNextImageSP from 'assets/images/winter/course/junior/arrow-next-sp.svg'
import ArrowPrevImageSP from 'assets/images/winter/course/junior/arrow-prev-sp.svg'
import ArrowNextImagePC from 'assets/images/winter/voice/slide-right-pc.svg'
import ArrowPrevImagePC from 'assets/images/winter/voice/slide-left-pc.svg'
import PriceButton from '../atom/priceButton'
import SecretGirl from '../atom/secretGirlJunior'
import classNames from 'classnames'
import textThreePointImage from 'assets/images/winter/course/text-three-point.svg'
import textOneImage from 'assets/images/winter/course/text-one.svg'
import textTwoImage from 'assets/images/winter/course/text-two.svg'
import textThreeImage from 'assets/images/winter/course/text-three.svg'
import arrowIcon from 'assets/images/winter/course/junior/icon-arrow.svg'
import cycleImage from 'assets/images/winter/course/junior/cycle-image.png'
import resultImageSP from 'assets/images/winter/course/junior/result-image-sp.jpg'
import resultImagePC from 'assets/images/winter/course/junior/result-image-pc.jpg'
import voiceKImage from 'assets/images/winter/course/junior/k_san.svg'
import voiceTImage from 'assets/images/winter/course/junior/t_san.svg'
import voiceNImage from 'assets/images/winter/course/junior/n_san.svg'
import fukidashiImage from 'assets/images/winter/course/junior/fukidashi.svg'
import point3_2SPImage from 'assets/images/winter/course/junior/point3-2-sp.png'
import point3_2PCImage from 'assets/images/winter/course/junior/point3-2-pc.jpg'
import ContactGirl from '../atom/contactGirl'
import ContactSectionSimple from '../contactSectionSimple'

export interface CourseJuniorHighSchoolProps {
  readonly noProps?: string
}

type CourseJuniorHighSchoolPropsClassName = CourseJuniorHighSchoolProps & {
  readonly className: string
}

function Component({ className }: CourseJuniorHighSchoolPropsClassName) {
  return (
    <div className={className}>
      <div className="l-inner">
        <div className="e-head">
          <HeadingTab text="中学生コース" headingLevel="h3" />
        </div>
        <div className="e-price-button">
          <PriceButton href="a-overview" />
        </div>
      </div>
      <div className="e-content-area0">
        <div className="l-inner">
          <h4 className="e-content-head">
            冬期講習の
            <span className="is-number">
              <img src={textThreePointImage} alt="3" />
            </span>
            <span className="is-point">つのポイント</span>
          </h4>
          <div className="e-calc">
            <CourseJuniorHighSchoolCalc />
          </div>
        </div>
        <div className="e-content-area1">
          <div className="l-inner">
            <div className="c-secret-illust">
              <SecretGirl />
            </div>
            <div className="c-secret-head">
              <p className="c-secret-head__lead">勉強の“つまずき”を解消できる</p>
              <h5 className="c-secret-head__title">
                <img className="c-secret-head__number" src={textOneImage} alt="1" />
                点数があがるしくみ
              </h5>
            </div>
            <div className="c-secret1-box">
              <p className="c-secret1-box__text1">
                <strong className="is-marker">
                  中学入学時から今までに学習してきた範囲をすべて総復習<span className="is-palt">。</span>
                </strong>
                &nbsp;苦手を見つけ、苦手克服サイクルで「わかった!」に変えます。
              </p>
              <p className="c-secret1-box__cycle-head">（苦手克服サイクル）</p>
              <img className="c-secret1-box__cycle-content" src={cycleImage} />
              <img className="c-secret1-box__arrow" src={arrowIcon} alt="" />
              <p className="c-secret1-box__text2">
                <strong className="is-marker">苦手克服サイクルをくり返すことで苦手がなくなり</strong>
                基礎学力が身につく!
              </p>
              <img className="c-secret1-box__arrow" src={arrowIcon} alt="" />
              <p className="c-secret1-box__text3">
                <strong className="is-marker">3学期の学校の授業がよくわかる</strong>ようになり
              </p>
              <picture>
                <source media="(min-width: 64rem)" srcSet={resultImagePC} />
                <img src={resultImageSP} alt="定期テストで点数アップできる！" className="c-secret1-box__result-image" />
              </picture>
              <h6 className="c-secret1-box__voice-head">講習を受けた生徒の声</h6>
              <div className="c-secret1-box__voice-items">
                <div className="c-secret1-box__voice-item">
                  <div className="c-voice-card">
                    <figure className="c-voice-card__figure">
                      <img className="c-voice-card__image" src={voiceKImage} alt="" />
                      <figcaption className="c-voice-card__name">Kさん</figcaption>
                    </figure>
                    <p className="c-voice-card__body">わかりやすく解説をしてくれたことが良かったです。質問しやすい。</p>
                  </div>
                </div>
                <div className="c-secret1-box__voice-item">
                  <div className="c-voice-card">
                    <figure className="c-voice-card__figure">
                      <img className="c-voice-card__image" src={voiceTImage} alt="" />
                      <figcaption className="c-voice-card__name">Tさん</figcaption>
                    </figure>
                    <p className="c-voice-card__body">励ましてくれたり、わからないところを親身になって教えてくれた。</p>
                  </div>
                </div>
                <div className="c-secret1-box__voice-item">
                  <div className="c-voice-card">
                    <figure className="c-voice-card__figure">
                      <img className="c-voice-card__image" src={voiceNImage} alt="" />
                      <figcaption className="c-voice-card__name">Nさん</figcaption>
                    </figure>
                    <p className="c-voice-card__body">個別対応なので質問しやすい、解説も良かった。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactSectionSimple
        balloonImageElement={<StaticImage src="../../../../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
        headImageElement={<ContactGirl />}
      />

      <div className="e-content-area2">
        <div className="l-inner">
          <div className="c-secret-illust">
            <SecretGirl />
          </div>
          <div className="c-secret-head">
            <p className="c-secret-head__lead">自宅での学習を支える</p>
            <h5 className="c-secret-head__title">
              <img className="c-secret-head__number" src={textTwoImage} alt="2" />
              学習記録システム
            </h5>
          </div>
          <div className="c-secret2-box">
            <h6 className="c-secret2-box__head1">お子さま専用のカリキュラム</h6>
            <div className="c-secret2-card">
              <div className="c-secret2-card__image">
                <StaticImage src="../../../../assets/images/winter/course/junior/tablet01.png" alt="" placeholder="none" />
              </div>
              <div className="c-secret2-card__body">
                <p className="c-secret2-card__text">
                  <strong className="is-marker">
                    システムを使ってお子さま専用の授業カリキュラムを作成<span className="is-palt">。</span>
                  </strong>
                  &nbsp;講師はお子さまの理解度を確認しながら、必要な解説や学習アドバイスを行います。
                </p>
                <div className={classNames('c-secret2-card__illust1', 'u-hidden-sp')}>
                  <StaticImage src="../../../../assets/images/winter/course/junior/secret2-illust1.jpg" alt="" placeholder="none" />
                </div>
              </div>
            </div>
            <h6 className="c-secret2-box__head2">宿題サポート機能</h6>
            <div className="c-secret2-card">
              <div className="c-secret2-card__image">
                <StaticImage src="../../../../assets/images/winter/course/junior/tablet02.png" alt="" placeholder="none" />
              </div>
              <div className="c-secret2-card__body">
                <p className="c-secret2-card__text">
                  <strong className="is-marker">
                    システムで宿題の提出状況を管理<span className="is-palt">。</span>
                  </strong>
                  &nbsp;<strong className="is-marker">取り組み具合に合わせて講師がサポートする</strong>
                  ので、家庭学習が定着していないお子さまも、自主的に宿題に取り組むことができます。
                </p>
                <div className={classNames('c-secret2-card__illust2', 'u-hidden-sp')}>
                  <StaticImage src="../../../../assets/images/winter/course/junior/secret2-illust2.jpg" alt="" placeholder="none" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="e-content-area3">
        <div className="l-inner">
          <div className="c-secret-illust">
            <SecretGirl />
          </div>
          <div className="c-secret-head">
            <p className="c-secret-head__lead">勉強のやる気アップにつながる</p>
            <h5 className="c-secret-head__title">
              <img className="c-secret-head__number" src={textThreeImage} alt="3" />
              つまずかないサポート
            </h5>
          </div>
          <div className="c-secret3-box">
            <h6 className="c-secret3-box__head1">お子さまの専属チーム</h6>
            <div className="c-secret3-card">
              <div className={classNames('c-secret3-card__image', 'u-hidden-sp')}>
                <StaticImage src="../../../../assets/images/winter/course/junior/point3-1.jpg" alt="" placeholder="none" />
              </div>
              <div className="c-secret3-card__body">
                <p className="c-secret3-card__text">
                  <strong className="is-marker">
                    担任と講師がチームを組んで点数アップを目指します<span className="is-palt">。</span>
                  </strong>
                  &nbsp;お子さまに適切な授業や学習アドバイスを行うことができるので、勉強のやる気アップにつながります。
                </p>
              </div>
            </div>
            <div className="e-slide">
              <div className="e-slide-head">
                <div className="e-slide-head-pop">実際の講師を一部紹介!</div>
              </div>
              <div className="e-slide-body">
                <Swiper
                  modules={[Pagination, Navigation, Autoplay]}
                  spaceBetween={5}
                  slidesPerView={2}
                  centeredSlides={false}
                  pagination={{ clickable: true }}
                  navigation={true}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  loopAdditionalSlides={1}
                  breakpoints={{
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                      centeredSlides: false,
                      loopAdditionalSlides: 0,
                    },
                  }}
                >
                  <SwiperSlide>
                    <TeacherCard
                      title="榎本先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher5.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="神戸大学在籍"
                      text={<>生徒一人ひとりに寄り添って教えていきます!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="志賀先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher10.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="九州大学在籍"
                      text={<>楽しく学べるよう全力でサポートします!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="田中先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher1.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="京都大学在籍"
                      text={<>わかるまで丁寧に解説します!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="横井先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher2.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="名古屋大学在籍"
                      text={<>分かりやすく噛み砕いて説明します!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="岸本先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher11.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="神戸大学在籍"
                      text={<>楽しい授業を目標にしています!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="田村先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher4.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="名古屋大学在籍"
                      text={<>親しみやすい穏和な先生を目指しています!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="日下先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher12.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="金沢医科大学在籍"
                      text={<>夢を叶えるために一緒に頑張りましょう!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="村上先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher6.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="名古屋市立大学在籍"
                      text={<>一緒に頑張りましょう!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="小松先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher7.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="東京工業大学在籍"
                      text={<>丁寧にわかりやすく教えます!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="中川先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher9.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="埼玉大学在籍"
                      text={<>楽しく一緒に勉強しましょう!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="入鹿先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher14.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="広島大学在籍"
                      text={<>楽しい授業できる様に頑張っています!</>}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TeacherCard
                      title="中川先生"
                      imageElement={
                        <StaticImage
                          className="e-image"
                          src="../../../../assets/images/winter/course/junior/teacher/teacher15.png"
                          alt=""
                          placeholder="none"
                        />
                      }
                      position="九州大学在籍"
                      text={<>しっかり解説します、一緒に頑張りましょう!</>}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <h6 className="c-secret3-box__head2">オンライン自習室</h6>
            <div className="c-secret3-card">
              <div className="c-secret3-card__image">
                <picture>
                  <source media="(min-width: 64rem)" srcSet={point3_2PCImage} width="400" height="250" />
                  <img src={point3_2SPImage} alt="" loading="lazy" width="275" height="160" />
                </picture>
              </div>
              <div className="c-secret3-card__body">
                <p className="c-secret3-card__text">
                  <strong className="is-marker">講習の宿題や、学校の宿題をするときに使用</strong>
                  できます。自習室の講師と勉強を頑張る仲間がいる中で勉強できるので、授業以外の勉強も集中して取り組むことができます。
                </p>
                <div className={classNames('c-secret3-card__illust', 'u-hidden-sp')}>
                  <StaticImage src="../../../../assets/images/winter/course/junior/secret3-illust.jpg" alt="" placeholder="none" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactSectionSimple
        balloonImageElement={<StaticImage src="../../../../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
        headImageElement={<ContactGirl />}
      />

      <OverviewSection />

      <ContactSectionSimple
        balloonImageElement={<StaticImage src="../../../../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
        headImageElement={<ContactGirl />}
      />
    </div>
  )
}

const StyledComponent = styled(Component)<CourseJuniorHighSchoolPropsClassName>`
  .l-inner {
    width: calc(${innerSeasonWidth.sm} + ${rem(10)});
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(1080)};
      padding-left: ${rem(40)};
      padding-right: ${rem(40)};
    }
  }

  .e-head {
    text-align: center;
  }

  .e-price-button {
    margin-top: ${rem(28)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(56)};
    }
  }

  .e-content-head {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${rem(18)};
    font-weight: 500;
    font-family: ${fontFamily.zenKaku};
    line-height: 1.6;
    text-align: center;
    letter-spacing: 0.1em;
    color: ${winterColor.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(30)};
    }

    img {
      display: block;
      margin: 0;
      width: ${rem(34)};

      @media ${breakpoints.lg} {
        width: ${rem(46)};
      }
    }

    > .is-point {
      font-weight: 700;
      font-size: ${rem(24)};
      letter-spacing: 0;

      @media ${breakpoints.lg} {
        font-size: ${rem(36)};
        margin-left: ${rem(4)};
      }
    }

    > .is-number {
      color: ${winterColor.main};
      position: relative;

      @media ${breakpoints.lg} {
        margin-left: ${rem(-4)};
      }

      &::after {
        content: '';
        position: absolute;
        width: ${rem(5)};
        height: ${rem(5)};
        background-color: currentColor;
        border-radius: 50%;
        top: ${rem(0)};
        left: 50%;
        transform: translate(-50%);
        top: ${rem(-10)};
        margin-left: ${rem(6)};

        @media ${breakpoints.lg} {
          width: ${rem(6)};
          height: ${rem(6)};
        }
      }
    }
  }

  .e-calc {
    margin-top: ${rem(30)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(35)};
      width: ${rem(640)};
      max-width: 100%;
      margin-inline: auto;
    }
  }

  .c-secret-illust {
    text-align: center;

    @media ${breakpoints.lg} {
    }
  }

  .c-secret-head {
    width: ${rem(295)};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: ${rem(10)};
    background: #fff;
    border: 1.5px solid ${winterColor.text};
    padding: ${rem(20)} ${rem(10)};
    display: grid;
    justify-items: center;
    gap: ${rem(12)};
    position: relative;
    z-index: 2;

    @media ${breakpoints.lg} {
      width: ${rem(640)};
      padding-bottom: ${rem(20)};
      border-radius: ${rem(20)};
      border-width: 3px;
    }
  }

  .c-secret-head__lead {
    margin: 0;
    font-family: ${fontFamily.zenKaku};
    font-weight: 500;
    font-size: ${rem(16)};
    line-height: 1;
    text-align: center;
    color: ${winterColor.text};
    display: flex;
    align-items: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
    }

    &::before,
    &::after {
      content: '';
      width: 1.5px;
      height: ${rem(20)};
      background-color: #333;
      flex-shrink: 0;
      margin-top: ${rem(0)};

      @media ${breakpoints.lg} {
        width: 2px;
        height: ${rem(28)};
        margin-top: ${rem(0)};
      }
    }

    &::before {
      transform: rotate(-24deg);
      margin-right: ${rem(12)};
    }

    &::after {
      transform: rotate(24deg);
      margin-left: ${rem(8)};
    }
  }

  .c-secret-head__title {
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(20)};
    letter-spacing: 0.1em;
    line-height: 1;
    color: ${winterColor.main};
    display: flex;
    align-items: center;
    gap: ${rem(10)};

    @media ${breakpoints.lg} {
      font-size: ${rem(32)};
    }
  }

  .c-secret-head__number {
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(60)};
    }
  }

  .c-secret1-box,
  .c-secret2-box,
  .c-secret3-box {
    margin-top: ${rem(-30)};
    border-radius: ${rem(10)};
    background: #fff;
    border: 1.5px solid #333;
    padding: ${rem(30)} ${rem(30)} ${rem(38)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(-40)};
      border-width: 3px;
      border-radius: ${rem(20)};
      padding-block: ${rem(100)} ${rem(60)};
    }
  }

  .c-secret1-box {
    padding-top: ${rem(68)};

    @media ${breakpoints.lg} {
      padding-top: ${rem(94)};
      padding-inline: ${rem(178)};
    }
  }

  .c-secret2-box,
  .c-secret3-box {
    padding-top: ${rem(48)};

    @media ${breakpoints.lg} {
      padding-inline: ${rem(80)};
    }
  }

  .c-secret3-box {
  }

  .c-secret1-box__text1,
  .c-secret1-box__text2,
  .c-secret1-box__text3 {
    font-size: ${rem(16)};
    line-height: calc(28 / 16);
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
      line-height: calc(36 / 20);
    }

    .is-marker {
      text-decoration: underline;
      text-decoration-color: ${winterColor.accent};
      text-decoration-thickness: ${rem(4)};
      font-weight: inherit;
      text-underline-offset: ${rem(-1)};
      text-decoration-skip-ink: none;
    }

    .is-palt {
      font-feature-settings: 'palt';
    }
  }

  .c-secret1-box__cycle-head {
    text-align: center;
    font-family: ${fontFamily.zenKaku};
    font-weight: 500;
    font-size: ${rem(16)};
    letter-spacing: 0.2em;
    line-height: calc(20 / 16);
    text-align: center;
    color: ${winterColor.main};
    margin: ${rem(20)} 0 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
    }
  }

  .c-secret1-box__cycle-content {
    display: block;
    width: ${rem(282)};
    max-width: 100%;
    margin: ${rem(10)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(375)};
    }
  }

  .c-secret1-box__arrow {
    display: block;
    width: ${rem(35)};
    margin: ${rem(20)} auto;

    @media ${breakpoints.lg} {
      width: ${rem(47)};
    }
  }

  .c-secret1-box__text2 {
  }

  .c-secret1-box__text3 {
  }

  .c-secret1-box__result-image {
    display: block;
    width: ${rem(275)};
    max-width: 100%;
    margin: ${rem(8)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(640)};
      margin-top: 0;
    }
  }

  .c-secret1-box__voice-head {
    margin-top: ${rem(40)};
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(16)};
    letter-spacing: 0.15em;
    line-height: 1.6;
    text-align: center;
    color: ${winterColor.main};
    border-bottom: 1px solid currentColor;
    padding-bottom: ${rem(10)};
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
      font-size: ${rem(26)};
      padding-bottom: ${rem(14)};
    }

    &::before,
    &::after {
      content: '';
      width: 1.5px;
      height: ${rem(18)};
      background-color: currentColor;
      flex-shrink: 0;
      margin-top: ${rem(0)};

      @media ${breakpoints.lg} {
        width: 2px;
        height: ${rem(28)};
        margin-top: ${rem(0)};
      }
    }

    &::before {
      transform: rotate(-24deg);
      margin-right: ${rem(12)};
    }

    &::after {
      transform: rotate(24deg);
      margin-left: ${rem(8)};
    }
  }

  .c-secret1-box__voice-items {
    margin-top: ${rem(30)};
    display: grid;
    gap: ${rem(20)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(38)};
    }
  }

  .c-secret1-box__voice-item {
  }

  .c-voice-card {
    display: flex;
    gap: ${rem(16)};
    align-items: center;

    @media ${breakpoints.lg} {
      gap: ${rem(22)};
    }
  }

  .c-voice-card__figure {
    text-align: center;
    width: ${rem(50)};
    flex-shrink: 0;
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(65)};
    }
  }

  .c-voice-card__image {
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(51.86)};
    }
  }

  .c-voice-card__name {
    margin-top: ${rem(2)};
    font-family: ${fontFamily.zenKaku};
    font-size: ${rem(12)};
    line-height: calc(14 / 12);
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(14)};
    }
  }

  .c-voice-card__body {
    flex-grow: 1;
    font-size: ${rem(14)};
    line-height: calc(24 / 14);
    color: ${winterColor.main};
    padding: ${rem(12)} ${rem(18)};
    border-radius: ${rem(8)};
    background: #fff;
    border: 1.5px solid #333;
    position: relative;
    margin: 0;
    position: relative;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      min-height: ${rem(85)};
      display: flex;
      align-items: center;
      padding-inline: ${rem(30)} ${rem(10)};
    }

    /* &::before,
    &::after {
      content: '';
      display: none;
      position: absolute;
      clip-path: polygon(0 50%, 100% 100%, 100% 0);
      width: ${rem(12.46)};
      height: ${rem(15)};
      right: calc(100%);
      inset-block: 0;
      margin-block: auto;
      background: #333;
    } */

    /* &::before {
      translate: 0 0;
    } */

    &::after {
      /* translate: 2px 0; */
      /* background: #fff;
      width: 10.46px;
      height: 13px; */
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: ${rem(15.46)};
      height: ${rem(15)};
      right: calc(100% - ${rem(3)});
      inset-block: 0;
      margin-block: auto;
      background: url(${fukidashiImage}) no-repeat center center / contain;
    }
  }

  .e-content-area0 {
    margin-top: ${rem(30)};
    padding-top: ${rem(60)};
    background-color: ${winterColor.mainBackground};

    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
      padding-top: ${rem(90)};
    }
  }

  .e-content-area1,
  .e-content-area2,
  .e-content-area3 {
    padding-top: ${rem(50)};
    padding-bottom: ${rem(50)};
    background-color: ${winterColor.mainBackground};

    @media ${breakpoints.lg} {
      padding-top: ${rem(80)};
      padding-bottom: ${rem(80)};
    }
  }

  .e-content-area1 {
  }

  .e-content-area2 {
  }

  .e-content-area3 {
    padding-top: ${rem(0)};
  }

  .c-secret2-box {
  }

  .c-secret2-box__head1,
  .c-secret2-box__head2,
  .c-secret3-box__head1,
  .c-secret3-box__head2 {
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(18)};
    letter-spacing: 0.15em;
    line-height: 1.6;
    text-align: center;
    color: ${winterColor.main};
    padding-bottom: ${rem(16)};
    border-bottom: 1.5px solid #333;
    margin-inline: ${rem(-30)};
    margin-bottom: ${rem(40)};

    @media ${breakpoints.lg} {
      margin-inline: ${rem(-80)};
      font-size: ${rem(26)};
      padding-top: ${rem(14)};
      padding-bottom: ${rem(26)};
      margin-bottom: ${rem(60)};
    }
  }

  .c-secret2-card,
  .c-secret3-card {
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};

    @media ${breakpoints.lg} {
      flex-direction: row;
      gap: ${rem(40)};
    }

    &__image {
      @media ${breakpoints.lg} {
        flex: 1;
      }

      img {
        margin-bottom: 0;
      }
    }

    &__body {
      @media ${breakpoints.lg} {
        flex: 1;
      }
    }

    &__text {
      font-size: ${rem(16)};
      line-height: calc(28 / 16);
      margin: 0;

      @media ${breakpoints.lg} {
        font-size: ${rem(18)};
      }

      .is-marker {
        text-decoration: underline;
        text-decoration-color: ${winterColor.accent};
        text-decoration-thickness: ${rem(4)};
        font-weight: inherit;
        text-underline-offset: ${rem(-1)};
        text-decoration-skip-ink: none;
      }

      .is-palt {
        font-feature-settings: 'palt';
      }
    }

    &__illust {
    }
  }

  .c-secret2-card__illust1 {
    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
      text-align: center;

      img {
        width: ${rem(87.62)};
      }
    }
  }

  .c-secret2-card__illust2 {
    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
      text-align: center;

      img {
        width: ${rem(130)};
      }
    }
  }

  .c-secret2-box__head2,
  .c-secret3-box__head2 {
    padding-top: ${rem(20)};
    border-top: 1.5px solid #333;
    margin-top: ${rem(36)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
      padding-top: ${rem(26)};
    }
  }

  .c-secret3-card__illust {
    @media ${breakpoints.lg} {
      margin-top: ${rem(55)};
      text-align: center;

      img {
        width: ${rem(130)};
      }
    }
  }

  .e-slide {
    margin-top: ${rem(40)};
    margin-inline: ${rem(-30)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
      margin-inline: ${rem(-80)};
      overflow: hidden;
    }

    > .e-slide-head {
      text-align: center;

      > .e-slide-head-pop {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${rem(18)};
        font-weight: 700;
        font-family: ${fontFamily.zenKaku};
        letter-spacing: 0.15em;
        line-height: 1.6;
        text-align: center;

        @media ${breakpoints.lg} {
          font-size: ${rem(26)};
        }

        &::before,
        &::after {
          content: '';
          width: 1.5px;
          height: ${rem(40 / 2)};
          background-color: ${winterColor.main};
          flex-shrink: 0;

          @media ${breakpoints.lg} {
            height: ${rem(32)};
            width: 3px;
          }
        }

        &::before {
          transform: rotate(-24deg);
          margin-right: ${rem(10)};

          @media ${breakpoints.lg} {
            margin-right: ${rem(20)};
          }
        }

        &::after {
          transform: rotate(24deg);
          margin-left: ${rem(10)};

          @media ${breakpoints.lg} {
            margin-left: ${rem(20)};
          }
        }
      }
    }

    > .e-slide-body {
      margin-top: ${rem(16)};
      padding-left: ${rem(20)};
      padding-right: ${rem(20)};
      overflow: hidden;

      @media ${breakpoints.lg} {
        /* width: ${rem(1000)}; */
        margin-top: ${rem(24)};
        margin-left: auto;
        margin-right: auto;
        padding-left: ${rem(33)};
        padding-right: ${rem(33)};
      }

      .swiper {
        overflow: visible;
        padding-bottom: ${rem(32)};

        @media ${breakpoints.lg} {
          padding-bottom: ${rem(42)};
        }
      }

      .swiper-slide {
        height: auto;

        > [class^='teacherCard'] {
          height: 100%;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: ${rem(22)};
        height: ${rem(22)};

        @media ${breakpoints.lg} {
          width: ${rem(30)};
          height: ${rem(30)};
        }
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        display: none;
      }

      .swiper-button-prev {
        background-image: url(${ArrowPrevImageSP});
        background-repeat: no-repeat;
        background-size: contain;
        left: auto;
        right: calc(50% + calc(142 / 375 * 100vw));

        @media ${breakpoints.lg} {
          /* background-image: url(${ArrowPrevImagePC}); */
          right: calc(50% + ${rem(440)});
        }
      }

      .swiper-button-next {
        background-image: url(${ArrowNextImageSP});
        background-repeat: no-repeat;
        background-size: contain;
        right: auto;
        left: calc(50% + calc(142 / 375 * 100vw));

        @media ${breakpoints.lg} {
          /* background-image: url(${ArrowNextImagePC}); */
          left: calc(50% + ${rem(440)});
        }
      }

      .swiper-pagination {
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${rem(12)};

        @media ${breakpoints.lg} {
          gap: ${rem(20)};
        }
      }

      .swiper-pagination-bullet {
        width: ${rem(12)};
        height: ${rem(12)};
        background-color: #e2e2e2;
        opacity: 1;
        margin-right: 0 !important;
        margin-left: 0 !important;

        @media ${breakpoints.lg} {
        }

        &.swiper-pagination-bullet-active {
          background-color: ${winterColor.main};
        }
      }
    }
  }

  > .e-contact {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    @media ${breakpoints.lg} {
    }
  }

  > .e-overview {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    @media ${breakpoints.lg} {
    }
  }
`

export const CourseJuniorHighSchool: (props: CourseJuniorHighSchoolProps) => JSX.Element = StyledComponent

export default CourseJuniorHighSchool
