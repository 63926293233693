import React, { useState } from 'react'
import styled from 'styled-components'
import { rem, breakpoints, fontFamily, winterColor, innerSeasonWidth } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'
import CourseTab from './atom/courseTab'
import classNames from 'classnames'
import CourseJuniorHighSchool from './group/courseJuniorHighSchool'
import CoursePrimary from './group/coursePrimary'
import CourseHighSchool from './group/courseHighSchool'

export interface CourseSectionProps {
  readonly noProps?: string
}

type CourseSectionPropsClassName = CourseSectionProps & {
  readonly className: string
}

function Component({ className }: CourseSectionPropsClassName) {
  const [currentTab, setCurrentTab] = useState<'primary' | 'junior-high-school' | 'high-school'>('junior-high-school')

  const handleTabClick = (selectTab: 'primary' | 'junior-high-school' | 'high-school') => {
    setCurrentTab(selectTab)
  }

  return (
    <div className={className}>
      <h2 className={classNames('l-inner', 'c-head')}>コース紹介</h2>
      <div className="l-inner">
        <div className="c-course-tab">
          <div className="c-course-tab__item">
            <CourseTab
              gradeImage={<StaticImage src="../../../assets/images/winter/course/course-primary.png" alt="" placeholder="none" />}
              gradeText="小学生"
              onClick={() => handleTabClick('primary')}
              isCurrent={currentTab === 'primary'}
            />
          </div>
          <div className="c-course-tab__item">
            <CourseTab
              gradeImage={<StaticImage src="../../../assets/images/winter/course/course-junior-high-school.png" alt="" placeholder="none" />}
              gradeText="中学生"
              onClick={() => handleTabClick('junior-high-school')}
              isCurrent={currentTab === 'junior-high-school'}
            />
          </div>
          <div className="c-course-tab__item">
            <CourseTab
              gradeImage={<StaticImage src="../../../assets/images/winter/course/course-high-school.png" alt="" placeholder="none" />}
              gradeText="高校生"
              onClick={() => handleTabClick('high-school')}
              isCurrent={currentTab === 'high-school'}
            />
          </div>
        </div>
      </div>
      <div className={classNames('c-tab-content', 'is-primary', currentTab === 'primary' && 'is-current')}>
        <CoursePrimary />
      </div>
      <div className={classNames('c-tab-content', 'is-primary', currentTab === 'junior-high-school' && 'is-current')}>
        <CourseJuniorHighSchool />
      </div>
      <div className={classNames('c-tab-content', 'is-primary', currentTab === 'high-school' && 'is-current')}>
        <CourseHighSchool />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseSectionPropsClassName>`
  padding-top: ${rem(80)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(120)};
  }

  .l-inner {
    width: calc(${innerSeasonWidth.sm} + ${rem(10)});
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(1080)};
      padding-left: ${rem(40)};
      padding-right: ${rem(40)};
    }
  }

  .c-head {
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 0.15em;
    line-height: 1.6;
    text-align: center;
    color: ${winterColor.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(50)};
      letter-spacing: 0.1em;
    }
  }

  .c-course-tab {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: ${rem(28)};
    border-bottom: 1.5px solid #333;
    gap: ${rem(10)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(26)};
      border-bottom-width: 3px;
      padding-left: ${rem(6)};
      padding-right: ${rem(6)};
      gap: ${rem(55)};
    }
  }

  .c-course-tab__item {
    flex: 1;

    @media ${breakpoints.lg} {
    }
  }

  .c-tab-content {
    margin-top: ${rem(50)};
    display: none;

    @media ${breakpoints.lg} {
      margin-top: ${rem(80)};
    }

    &.is-current {
      display: block;
    }
  }
`

export const CourseSection: (props: CourseSectionProps) => JSX.Element = StyledComponent

export default CourseSection
