import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, fontFamily, winterColor } from 'variables/_index'
import WinterContactButton from './atom/winterContactButton'
import { siteStructure } from 'utils/site'
import { QuoteLeft, QuoteRight } from 'components/icon'

export interface ContactSectionHasCampaignProps {
  readonly isDocumentButton?: boolean
  readonly balloonImageElement: JSX.Element
  readonly headImageElement: JSX.Element
}

type ContactSectionHasCampaignPropsClassName = ContactSectionHasCampaignProps & {
  readonly className: string
}

function Component({ className, balloonImageElement, headImageElement }: ContactSectionHasCampaignPropsClassName) {
  return (
    <div className={className}>
      <div className="c-illust">{headImageElement}</div>
      <div className="c-contact-area">
        <div className="l-inner">
          <p className="c-lead-text">
            冬期講習
            <span className="is-color">
              <span className="is-num">4</span>日間
            </span>
            を
          </p>
          <div className="c-main-text">
            <QuoteLeft className="c-main-text__quote-left" color={'#333'} />
            <p className="c-main-text__content">
              <span className="is-em">無料</span>で<span className="is-em">体験!</span>
            </p>
            <QuoteRight className="c-main-text__quote-right" color={'#333'} />
          </div>
          <div className="c-buttons">
            <div className="c-buttons__item">
              <WinterContactButton href={siteStructure.summerContact.path} imageElement={balloonImageElement} />
            </div>
          </div>
          <p className="c-point">
            <span className="c-point__lead">講習受講後の入会で</span>
            <span className="c-point__main">
              入会金22,000円<span className="is-small">を</span>
              <span className="is-dot">全</span>
              <span className="is-dot">額</span>
              <span className="is-dot">免</span>
              <span className="is-dot">除</span>!
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<ContactSectionHasCampaignPropsClassName>`
  text-align: center;
  padding-top: ${rem(30)};
  position: relative;

  @media ${breakpoints.lg} {
    padding-top: ${rem(40)};
  }

  .c-illust {
    position: relative;
    z-index: 2;

    img {
    }

    @media ${breakpoints.lg} {
    }
  }

  .l-inner {
    width: ${rem(300)};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(576.13)};
    }
  }

  .c-buttons {
    margin-top: ${rem(12)};
    display: flex;
    flex-direction: column;
    gap: ${rem(10)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
      gap: ${rem(16)};
    }
  }

  .c-buttons__item {
  }

  .c-contact-area {
    margin-top: ${rem(-10)};
    background-color: ${winterColor.accent};
    padding-top: ${rem(20)};
    padding-bottom: ${rem(30)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(-40)};
      padding-top: ${rem(50)};
      padding-bottom: ${rem(40)};
    }
  }

  .c-lead-text {
    font-size: ${rem(20)};
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1;
    text-align: center;
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(32)};
    }

    .is-color {
      color: ${winterColor.main};
    }

    .is-num {
      font-size: ${rem(30)};

      @media ${breakpoints.lg} {
        font-size: ${rem(52)};
      }
    }
  }

  .c-main-text {
    letter-spacing: 0.1em;
    font-size: ${rem(23)};
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: ${rem(4)};

    @media ${breakpoints.lg} {
      font-size: ${rem(36)};
      margin-top: ${rem(8)};
    }
  }

  .c-main-text__content {
    margin: 0;

    .is-em {
      font-size: ${rem(30)};
      color: ${winterColor.main};

      @media ${breakpoints.lg} {
        font-size: ${rem(48)};
      }
    }
  }

  .c-main-text__quote-left,
  .c-main-text__quote-right {
    margin-top: ${rem(10)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(14)};
    }
  }

  .c-main-text__quote-left {
    margin-right: ${rem(20 / 2)};
  }

  .c-main-text__quote-right {
    margin-left: ${rem(20 / 2)};
  }

  .c-point {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    margin: ${rem(18)} 0 0;
    padding-top: ${rem(6)};
    padding-bottom: ${rem(4)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(18)};
      padding-top: ${rem(12)};
      padding-bottom: ${rem(10)};
      border-top-width: 2px;
      border-bottom-width: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .c-point__lead {
    font-size: ${rem(16)};
    letter-spacing: 0.05em;
    font-weight: 500;

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
      letter-spacing: 0.075em;
    }
  }

  .c-point__main {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${rem(20)};
    margin-top: ${rem(3)};
    letter-spacing: 0.05em;
    font-weight: 700;

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
      letter-spacing: 0.075em;
      margin-left: ${rem(12)};
      margin-top: ${rem(0)};
    }

    &::before,
    &::after {
      content: '';
      width: 1.5px;
      height: ${rem(22)};
      background-color: #333;
      flex-shrink: 0;
      margin-top: ${rem(6)};

      @media ${breakpoints.lg} {
        width: 2px;
        height: ${rem(28)};
        margin-top: ${rem(0)};
      }
    }

    &::before {
      transform: rotate(-24deg);
      margin-right: ${rem(8)};
    }

    &::after {
      transform: rotate(24deg);
      margin-left: ${rem(8)};
    }

    > .is-small {
      font-size: ${rem(17)};

      @media ${breakpoints.lg} {
        font-size: ${rem(21)};
      }
    }

    > .is-dot {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: ${rem(4)};
        height: ${rem(4)};
        background-color: currentColor;
        border-radius: 50%;
        top: ${rem(-1)};
        left: 50%;
        transform: translate(-50%);

        @media ${breakpoints.lg} {
          width: ${rem(5)};
          height: ${rem(5)};
        }
      }
    }
  }
`

export const ContactSectionHasCampaign: (props: ContactSectionHasCampaignProps) => JSX.Element = StyledComponent

export default ContactSectionHasCampaign
