import React from 'react'
import Layout from 'layout/base'
import Seo from 'components/seo'
import { WinterTemplate } from 'templates/winterTemplate'
import type { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'

interface WinterPageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const WinterPage = ({ pageContext, location }: WinterPageProps): JSX.Element => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // TODO : 全ページで作っているので共通化したい
  const breadcrumbItems = crumbs.map(crumb => {
    return crumb.crumbLabel in siteStructure
      ? {
          ...crumb,
          ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
        }
      : { ...crumb }
  })

  return (
    <Layout pageType="winterLP" breadcrumbItems={breadcrumbItems} isDisplayCTASection={false} ctaType="type2">
      <Seo
        title="冬期講習2023-オンライン個別指導塾Fit NET STUDY"
        description="冬期講習2023のご案内です。この冬で勉強の「つまずき」を解消し、3学期の定期テストで点数アップ！4日間の無料体験受付中。小学生・中学生・高校生対象。"
        path={location.pathname}
      />
      <WinterTemplate />
    </Layout>
  )
}

export default WinterPage
