import React from 'react'
import styled from 'styled-components'
import { breakpoints, rem, winterColor } from 'variables/_index'

export interface VoiceCardProps {
  readonly noProps?: string
  readonly title: JSX.Element
  readonly imageElement: JSX.Element
  readonly grade: string
  readonly name: string
  readonly text: JSX.Element
}

type VoiceCardPropsClassName = VoiceCardProps & {
  readonly className: string
}

function Component({ className, title, imageElement, grade, name, text }: VoiceCardPropsClassName) {
  return (
    <div className={className}>
      <div className="e-title">{title}</div>
      <div className="e-image">{imageElement}</div>
      <p className="e-text">{text}</p>
      <div className="e-meta">
        <div className="e-grade">{grade}</div>
        <div className="e-name">{name}</div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<VoiceCardPropsClassName>`
  /* height: ${rem(624 / 2)}; */
  background-color: #fff;
  border-radius: ${rem(30)};
  border: 2.5px solid ${winterColor.accent};
  position: relative;
  padding: ${rem(24)} ${rem(24)} ${rem(15)};
  text-align: center;

  @media ${breakpoints.lg} {
    border-radius: ${rem(50)};
    border-width: 3px;
    padding: ${rem(30)} ${rem(50)} ${rem(28)};
  }

  .e-title {
    font-size: ${rem(28)};
    font-weight: 700;
    text-align: center;
    line-height: calc(30 / 28);
    color: ${winterColor.main};
    white-space: nowrap;

    @media ${breakpoints.lg} {
      font-size: ${rem(34)};
    }

    .is-marker {
      display: inline-block;
      text-decoration-line: underline;
      text-decoration-color: ${winterColor.accent};
      text-decoration-thickness: ${rem(4)};
      text-decoration-skip-ink: none;

      @media ${breakpoints.lg} {
        text-underline-offset: ${rem(4)};
      }
    }
  }

  .e-image {
    margin-top: ${rem(23)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(28)};
    }

    img {
      display: block;
      width: 100%;
      /* width: ${rem(270 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(270)};
      } */
    }
  }

  .e-text {
    margin: ${rem(20)} 0 0;
    text-align: left;
    line-height: calc(24 / 16);
    font-size: ${rem(16)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
      font-size: ${rem(20)};
    }
  }

  .e-meta {
    margin-top: ${rem(8)};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${rem(12)};
    letter-spacing: 0.15em;

    @media ${breakpoints.lg} {
      margin-top: ${rem(12)};
      font-size: ${rem(12)};
    }
  }

  .e-grade {
  }

  .e-name {
    &::before {
      content: '｜';
    }
  }
`

export const VoiceCard: (props: VoiceCardProps) => JSX.Element = StyledComponent

export default VoiceCard
