import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { rem, breakpoints, fontFamily } from 'variables/_index'
import { ArrowRightKu } from 'components/icon'
import { Link as ScrollLink } from 'react-scroll'

export interface PriceButtonProps {
  readonly href: string
}

type PriceButtonPropsClassName = PriceButtonProps & {
  readonly className: string
}

function Component({ className, href }: PriceButtonPropsClassName) {
  return (
    <ScrollLink to={href} className={className} smooth offset={0}>
      <div className="e-content">
        <span className="e-image">
          <StaticImage src="../../../../assets/images/winter/course/price-button.png" alt="" placeholder="none" />
        </span>
        <span className="e-text">日程や料金はこちら</span>
        <div className="e-icon">
          <ArrowRightKu />
        </div>
      </div>
    </ScrollLink>
  )
}

const StyledComponent = styled(Component)<PriceButtonPropsClassName>`
  display: inline-block;
  border-radius: 200px;
  position: relative;
  text-decoration: none;
  font-weight: 500;
  font-family: ${fontFamily.zenKaku};
  cursor: pointer;
  transition: all 0.3s ease 0s;
  box-shadow: ${rem(3)} ${rem(3)} 0 0 #d1d1d1;

  &:hover {
    opacity: 0.8;
  }

  > .e-content {
    display: flex;
    padding-right: ${rem(20)};
    border-radius: inherit;
    border: 1.5px solid #333;
    background-color: #dcedff;
    color: #333;
    align-items: center;
    width: ${rem(295)};
    font-size: ${rem(14)};
    position: relative;
    z-index: 2;

    @media ${breakpoints.lg} {
      width: ${rem(500)};
      font-size: ${rem(24)};
      border-width: 3px;
      padding-right: ${rem(40)};
      line-height: 1.6;
    }

    > .e-image {
      width: ${rem(160 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(130)};
      }

      img {
      }
    }

    > .e-text {
      margin-left: ${rem(25)};
      font-size: ${rem(15)};
      font-weight: 500;
      line-height: 1.6;
      text-align: left;
      letter-spacing: 0.1em;

      @media ${breakpoints.lg} {
        margin-left: ${rem(50)};
        font-size: ${rem(24)};
      }
    }

    > .e-icon {
      flex-shrink: 0;
      margin-left: auto;

      svg {
        @media ${breakpoints.lg} {
          width: ${rem(12)};
        }
      }
    }
  }

  @media ${breakpoints.lg} {
  }
`

export const PriceButton: (props: PriceButtonProps) => JSX.Element = StyledComponent

export default PriceButton
