import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, rem, winterColor } from 'variables/_index'

export interface ContactBodyPrimaryProps {
  readonly noProps?: string
}

type ContactBodyPrimaryPropsClassName = ContactBodyPrimaryProps & {
  readonly className: string
}

function Component({ className }: ContactBodyPrimaryPropsClassName) {
  return (
    <div className={className}>
      <div className="c-main">
        <StaticImage
          className="c-main__image"
          src="../../../../assets/images/winter/contact/cta_body_primary.png"
          alt=""
          placeholder="none"
          loading="lazy"
        />
      </div>
      <div className="c-snow1">
        <div className="c-snow1__fadeIn">
          <StaticImage className="c-snow1__image" src="../../../../assets/images/winter/mv/snow/snow9.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
      <div className="c-snow2">
        <div className="c-snow2__fadeIn">
          <StaticImage className="c-snow2__image" src="../../../../assets/images/winter/mv/snow/snow8.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
      <div className="c-snow3">
        <div className="c-snow3__fadeIn">
          <StaticImage className="c-snow3__image" src="../../../../assets/images/winter/mv/snow/snow4.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
      <div className="c-snow4">
        <div className="c-snow4__fadeIn">
          <StaticImage className="c-snow4__image" src="../../../../assets/images/winter/mv/snow/snow1.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<ContactBodyPrimaryPropsClassName>`
  position: relative;
  display: inline-block;
  width: ${rem(203)};

  @media ${breakpoints.lg} {
    width: ${rem(256.48)};
  }

  @keyframes DropContactBodyPrimary {
    0% {
      opacity: 0;
      transform: translateY(${rem(-28)});
    }

    70% {
      opacity: 1;
      transform: translateY(${rem(12)});
    }

    100% {
      opacity: 0;
      transform: translateY(${rem(12)});
    }
  }

  @keyframes MoveContactBodyPrimary {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(${rem(10)});
    }
    100% {
      transform: translateY(0);
    }
  }

  .c-main {
  }

  .c-main__image {
    width: ${rem(160)};

    @media ${breakpoints.lg} {
      width: ${rem(231.85)};
    }
  }

  .c-snow1,
  .c-snow2,
  .c-snow3,
  .c-snow4,
  .c-snow5 {
    position: absolute;
    top: 0;
    line-height: 0;
  }

  .c-snow1 {
    width: ${rem(19)};
    top: ${rem(13)};
    right: calc(50% + ${rem(78)});
    animation: MoveContactBodyPrimary 5.1s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(26.57)};
      top: ${rem(18)};
      right: calc(50% + ${rem(112)});
    }
  }

  .c-snow2 {
    width: ${rem(17)};
    top: ${rem(22)};
    left: calc(50% + ${rem(10)});
    animation: MoveContactBodyPrimary 5.6s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(24.29)};
      top: ${rem(36)};
      left: calc(50% + ${rem(10)});
    }
  }

  .c-snow3 {
    width: ${rem(9)};
    top: ${rem(16)};
    left: calc(50% + ${rem(46)});
    animation: MoveContactBodyPrimary 4.6s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(13.43)};
      top: ${rem(22)};
      left: calc(50% + ${rem(58)});
    }
  }

  .c-snow4 {
    width: ${rem(20)};
    top: ${rem(6)};
    left: calc(50% + ${rem(76)});
    animation: MoveContactBodyPrimary 4.8s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(31.25)};
      top: ${rem(14)};
      left: calc(50% + ${rem(108)});
    }
  }
`

export const ContactBodyPrimary: (props: ContactBodyPrimaryProps) => JSX.Element = StyledComponent

export default ContactBodyPrimary
