import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerWidth, fontFamily, winterColor, innerSeasonWidth } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import ArrowIcon from '../../../assets/images/winter/style/style_jukou_arrow.svg'

export interface StyleSectionProps {
  readonly noProps?: boolean
}

type StyleSectionPropsClassName = StyleSectionProps & {
  readonly className: string
}

function Component({ className }: StyleSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="l-inner">
        <h2 className="c-style-head">受講方法</h2>
        <p className="c-style-lead">
          インターネット環境があれば
          <br className="u-hidden-pc" />
          ご受講が可能です。
        </p>
        <StaticImage className="e-style-image" src="../../../assets/images/winter/style/style_lead_img.jpg" alt="" placeholder="none" />

        <div className="c-style-boxes">
          <div className={classNames('c-style-box', 'c-style-boxes__item')}>
            <h3 className="c-style-box__head">授業の受け方</h3>
            <div className="c-style-box__content">
              <p className="c-style-box__text">
                たったの2ステップで簡単に
                <br className="u-hidden-pc" />
                授業を受けられます。
              </p>
              <div className="c-step-boxes">
                <div className={classNames('c-step-box', 'c-step-boxes__item')}>
                  <p className="c-step-box__number">1</p>
                  <p className="c-step-box__lead">
                    学習記録システムにログインし
                    <br />
                    授業の予定をカレンダーで確認
                  </p>
                  <StaticImage
                    className="c-step-box__image"
                    src="../../../assets/images/winter/style/style_box_img01.jpg"
                    alt=""
                    placeholder="none"
                  />
                </div>
                <div className={classNames('c-step-box', 'c-step-boxes__item')}>
                  <p className="c-step-box__number">2</p>
                  <p className="c-step-box__lead">授業の入室ボタンをクリック</p>
                  <StaticImage
                    className="c-step-box__image"
                    src="../../../assets/images/winter/style/style_box_img02.jpg"
                    alt=""
                    placeholder="none"
                  />
                </div>
              </div>
              <img className="c-step-arrow" src={ArrowIcon} alt="" width={28} height={28} />
              <p className="c-step-start">Zoomで授業スタート!</p>
            </div>
          </div>
          <div className={classNames('c-style-box', 'c-style-boxes__item')}>
            <h3 className="c-style-box__head">利用可能端末</h3>
            <div className={classNames('c-device-box', 'c-style-box__content')}>
              <StaticImage
                className={classNames('c-device-box__image', 'u-hidden-pc')}
                src="../../../assets/images/winter/style/style_box_img03_sp.jpg"
                alt=""
                placeholder="none"
              />
              <StaticImage
                className={classNames('c-device-box__image', 'u-hidden-sp')}
                src="../../../assets/images/winter/style/style_box_img03_pc.jpg"
                alt=""
                placeholder="none"
              />
              <div className="c-device-box__body">
                <p className="c-device-box__text">スマホ以外ほぼすべての端末が利用できます。</p>
                <ul className="c-device-box__lists">
                  <li className="c-device-box__list">
                    タブレット
                    <span className="is-small">(アンドロイドまたはiPad)</span>
                  </li>
                  <li className="c-device-box__list">
                    PC
                    <span className="is-small">（WindowsまたはMac）</span>
                  </li>
                </ul>
                <p className="c-device-box__note">※小学生コースの体験授業はスマホOK!</p>
              </div>
            </div>
          </div>
          <div className={classNames('c-style-box', 'c-style-boxes__item')}>
            <h3 className="c-style-box__head">端末レンタルについて</h3>
            <div className={classNames('c-rental-box', 'c-style-box__content')}>
              <div className="c-rental-box__head">
                <StaticImage
                  className={classNames('c-rental-box__image', 'u-hidden-pc')}
                  src="../../../assets/images/winter/style/style_box_img04_sp.jpg"
                  alt=""
                  placeholder="none"
                />
                <StaticImage
                  className={classNames('c-rental-box__image', 'u-hidden-sp')}
                  src="../../../assets/images/winter/style/style_box_img04_pc.png"
                  alt=""
                  placeholder="none"
                />
              </div>
              <div className="c-rental-box__body">
                <p className="c-rental-box__text">タブレットのレンタルもございます。体験ガイダンス時にご相談ください。</p>
                <div className={classNames('c-rental-box__illust', 'u-hidden-sp')}>
                  <StaticImage src="../../../assets/images/winter/style/style_box_img05.png" alt="" placeholder="none" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<StyleSectionPropsClassName>`
  padding-top: ${rem(80)};
  padding-bottom: ${rem(50)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(120)};
    padding-bottom: ${rem(80)};
  }

  .l-inner {
    width: ${innerSeasonWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(1080)};
      padding-left: ${rem(40)};
      padding-right: ${rem(40)};
    }
  }

  .c-style-head {
    text-align: center;
    color: ${winterColor.fitMain};
    font-size: ${rem(32)};
    font-weight: 700;
    font-family: ${fontFamily.zenKaku};
    letter-spacing: 0.15em;
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(50)};
    }
  }

  .c-style-lead {
    margin-top: ${rem(30)};
    font-size: ${rem(18)};
    font-weight: 500;
    text-align: center;
    line-height: calc(29 / 18);
    letter-spacing: 0.1em;

    @media ${breakpoints.lg} {
      margin-top: ${rem(40)};
      font-size: ${rem(24)};
      letter-spacing: 0.05em;
    }
  }

  .e-style-image {
    display: block;
    width: ${rem(280)};
    margin-top: ${rem(20)};
    margin-inline: auto;

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
      width: ${rem(525)};
    }
  }

  .c-style-boxes {
    display: flex;
    flex-direction: column;
    gap: ${rem(40)};
    margin-top: ${rem(30)};

    @media ${breakpoints.lg} {
      gap: ${rem(80)};
      margin-top: ${rem(86)};
    }
  }

  .c-style-boxes__item {
  }

  .c-style-box {
    border-radius: ${rem(10)};
    border: 1.5px solid ${winterColor.fitMain};

    @media ${breakpoints.lg} {
      border-radius: ${rem(20)};
      border-width: 3px;
    }
  }

  .c-style-box__head {
    text-align: center;
    padding: ${rem(24)} ${rem(10)};
    font-family: ${fontFamily.zenKaku};
    line-height: 1;
    letter-spacing: 0.1em;
    font-size: ${rem(23)};
    font-weight: 700;
    color: ${winterColor.text};
    border-bottom: 1px solid ${winterColor.fitMain};

    @media ${breakpoints.lg} {
      padding-block: ${rem(24)};
      font-size: ${rem(38)};
    }
  }

  .c-style-box__content {
    margin-top: ${rem(30)};
    padding-inline: ${rem(30)};
    padding-bottom: ${rem(30)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
      padding-inline: ${rem(80)};
      padding-bottom: ${rem(60)};
    }
  }

  .c-style-box__text {
    font-size: ${rem(32 / 2)};
    font-weight: 500;
    line-height: 1.6;
    text-align: center;
    margin-top: ${rem(64 / 2)};
    margin-bottom: ${rem(28)};
    letter-spacing: 0.1em;

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
      line-height: 1;
      margin-top: ${rem(66)};
      margin-bottom: ${rem(52)};
    }
  }

  .c-step-boxes {
    display: flex;
    flex-direction: column;
    gap: ${rem(30)};

    @media ${breakpoints.lg} {
      flex-direction: row;
      gap: ${rem(78)};
    }
  }

  .c-step-boxes__item {
  }

  .c-step-box {
    @media ${breakpoints.lg} {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .c-step-box__number {
    margin: 0;
    font-family: ${fontFamily.zenKaku};
    font-size: ${rem(40)};
    font-weight: 500;
    line-height: 1;
    border-bottom: 1px solid currentColor;
    text-align: center;
    color: ${winterColor.fitMain};
    padding-bottom: ${rem(8)};

    @media ${breakpoints.lg} {
      font-size: ${rem(64)};
      padding-bottom: ${rem(12)};
      border-bottom-width: 2px;
    }
  }

  .c-step-box__lead {
    margin-top: ${rem(20)};
    margin-bottom: ${rem(18)};
    font-size: ${rem(16)};
    font-weight: 500;
    line-height: calc(26 / 16);
    text-align: center;
    color: ${winterColor.text};
    letter-spacing: 0.1em;

    @media ${breakpoints.lg} {
      margin-top: ${rem(16)};
      margin-bottom: ${rem(16)};
      font-size: ${rem(22)};
      line-height: 1.8;
    }
  }

  .c-step-box__image {
    margin-inline: auto;

    @media ${breakpoints.lg} {
      margin-top: auto;
    }
  }

  .c-step-arrow {
    display: block;
    margin-top: ${rem(15)};
    margin-inline: auto;
    margin-bottom: 0;
    width: ${rem(28)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(30)};
      width: ${rem(47.25)};
    }
  }

  .c-step-start {
    margin-top: ${rem(20)};
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${rem(18)};
    font-weight: 700;
    line-height: 1;
    font-family: ${fontFamily.zenKaku};
    color: ${winterColor.fitMain};
    letter-spacing: 0.15em;

    @media ${breakpoints.lg} {
      margin-top: ${rem(24)};
      font-size: ${rem(24)};
    }

    &::before,
    &::after {
      content: '';
      width: 2px;
      height: ${rem(20)};
      background-color: currentColor;
      flex-shrink: 0;

      @media ${breakpoints.lg} {
        height: ${rem(30)};
        border-width: 2px;
      }
    }

    &::before {
      margin-right: ${rem(14)};
      transform: rotate(-20deg);

      @media ${breakpoints.lg} {
        margin-right: ${rem(20)};
      }
    }

    &::after {
      margin-left: ${rem(14)};
      transform: rotate(20deg);

      @media ${breakpoints.lg} {
        margin-left: ${rem(20)};
      }
    }
  }

  .c-device-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: ${rem(30)};

    @media ${breakpoints.lg} {
      flex-direction: row;
      gap: ${rem(40)};
    }
  }

  .c-device-box__image {
    @media ${breakpoints.lg} {
      flex: 1;
    }
  }

  .c-device-box__body {
    @media ${breakpoints.lg} {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .c-device-box__text {
    margin: 0;
    font-size: ${rem(16)};
    line-height: calc(26 / 16);

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      line-height: 1;
      order: 1;
    }
  }

  .c-device-box__lists {
    display: flex;
    flex-direction: column;
    gap: ${rem(10)};
    margin: ${rem(20)} 0 0;

    @media ${breakpoints.lg} {
      margin-top: ${rem(40)};
      order: 3;
    }
  }

  .c-device-box__list {
    margin: 0;
    padding: ${rem(8)} ${rem(3)} ${rem(9)};
    border-radius: ${rem(8)};
    background-color: ${winterColor.fitMain};
    color: #fff;
    text-align: center;
    font-size: ${rem(16)};
    font-weight: 500;
    white-space: nowrap;

    @media ${breakpoints.lg} {
      padding: ${rem(8)} ${rem(10)};
      border-radius: ${rem(10)};
      font-size: ${rem(24)};
    }

    .is-small {
      font-size: ${rem(14)};

      @media ${breakpoints.lg} {
        font-size: ${rem(18)};
      }
    }
  }

  .c-device-box__note {
    font-size: ${rem(12)};
    line-height: 1;
    margin-top: ${rem(14)};
    margin-bottom: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      line-height: 1.5;
      margin-top: ${rem(14)};
      order: 2;
    }
  }

  .c-rental-box {
    display: flex;
    flex-direction: column;
    gap: ${rem(30)};

    @media ${breakpoints.lg} {
      flex-direction: row;
      gap: ${rem(40)};
    }
  }

  .c-rental-box__head {
    @media ${breakpoints.lg} {
      flex: 1;
    }
  }

  .c-rental-box__image {
  }

  .c-rental-box__body {
    @media ${breakpoints.lg} {
      flex: 1;
    }
  }

  .c-rental-box__text {
    font-size: ${rem(16)};
    line-height: calc(26 / 16);
    margin-block: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      line-height: 1.8;
    }
  }

  .c-rental-box__illust {
    @media ${breakpoints.lg} {
      width: ${rem(200)};
      height: auto;
      display: block;
      margin-inline: auto;
      margin-top: ${rem(50)};
    }
  }
`

export const StyleSection: (props: StyleSectionProps) => JSX.Element = StyledComponent

export default StyleSection
