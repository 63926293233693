import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints, winterColor } from 'variables/_index'
import textOneImage from 'assets/images/winter/course/text-one.svg'
import textTwoImage from 'assets/images/winter/course/text-two.svg'
import textThreeImage from 'assets/images/winter/course/text-three.svg'
import arrowIcon from 'assets/images/winter/course/icon-arrow.svg'
import resultImage from 'assets/images/winter/course/result-text.svg'

export interface CourseJuniorHighSchoolCalcProps {
  readonly noProps?: string
}

type CourseJuniorHighSchoolCalcPropsClassName = CourseJuniorHighSchoolCalcProps & {
  readonly className: string
}

function Component({ className }: CourseJuniorHighSchoolCalcPropsClassName) {
  return (
    <div className={className}>
      <div className="c-points">
        <p className="c-point">
          <img className="c-point__number1" src={textOneImage} alt="" />
          点数があがるしくみ
        </p>
        <div className="c-plus-icon"></div>
        <p className="c-point">
          <img className="c-point__number3" src={textTwoImage} alt="" />
          学習記録システム
        </p>
        <div className="c-plus-icon"></div>
        <p className="c-point">
          <img className="c-point__number3" src={textThreeImage} alt="" />
          つまずかないサポート
        </p>
      </div>
      <img className="c-arrow" src={arrowIcon} alt="" />
      <img className="c-result" src={resultImage} alt="3学期でテスト得点アップ" />
    </div>
  )
}

const StyledComponent = styled(Component)<CourseJuniorHighSchoolCalcPropsClassName>`
  display: block;
  border-radius: ${rem(10)};
  background: #fff;
  border: 1.5px solid #333;
  padding: ${rem(20)} ${rem(18)} ${rem(14)};
  text-align: center;

  @media ${breakpoints.lg} {
    border-width: 3px;
    border-radius: ${rem(20)};
    padding-block: ${rem(30)};
  }

  .c-points {
    width: fit-content;
    margin: 0 auto;
  }

  .c-point {
    display: flex;
    align-items: center;
    gap: ${rem(10)};
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;
    font-size: ${rem(18)};
    letter-spacing: 0.1em;
    line-height: 1.6;
    text-align: left;
    color: ${winterColor.main};
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
    }
  }

  .c-point__number1,
  .c-point__number2,
  .c-point__number3 {
    width: ${rem(30)};

    @media ${breakpoints.lg} {
      width: ${rem(44)};
    }
  }

  .c-point__number1 {
  }

  .c-point__number2 {
  }

  .c-point__number3 {
  }

  .c-plus-icon {
    margin: ${rem(15)} auto ${rem(8)};
    width: ${rem(16)};
    height: ${rem(16)};
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border-radius: ${rem(8)};

    @media ${breakpoints.lg} {
      width: ${rem(28)};
      height: ${rem(28)};
      margin-block: ${rem(5)} ${rem(6)};
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      background: #333;
      width: ${rem(16)};
      height: ${rem(2)};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media ${breakpoints.lg} {
        width: ${rem(28)};
      }
    }

    &::before {
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  .c-arrow {
    width: ${rem(35)};
    display: block;
    margin: ${rem(10)} auto ${rem(5)};

    @media ${breakpoints.lg} {
      width: ${rem(47)};
      margin-bottom: ${rem(8)};
    }
  }

  .c-result {
    width: ${rem(300)};
    max-width: 100%;
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(441)};
    }
  }
`

export const CourseJuniorHighSchoolCalc: (props: CourseJuniorHighSchoolCalcProps) => JSX.Element = StyledComponent

export default CourseJuniorHighSchoolCalc
