import React from 'react'
import styled from 'styled-components'
import { rem, winterColor, fontFamily, breakpoints } from 'variables/_index'
import classNames from 'classnames'

export interface CourseTabProps {
  readonly gradeImage: JSX.Element
  readonly gradeText: string
  readonly isCurrent?: boolean
  readonly onClick: () => void
}

type CourseTabPropsClassName = CourseTabProps & {
  readonly className: string
}

function Component({ className, gradeImage, gradeText, isCurrent = false, onClick }: CourseTabPropsClassName) {
  return (
    <div className={classNames(className, isCurrent && 'is-current')} onClick={onClick}>
      <div className="e-course-content">
        <div className="e-course-image">{gradeImage}</div>
        <p className="e-course-title">
          {gradeText}
          <br className="is-sp" />
          コース
        </p>
        <svg className="e-course-arrow" height="7.592" viewBox="0 0 13.063 7.592" width="13.063" xmlns="http://www.w3.org/2000/svg">
          <path
            className="e-course-path"
            d="m12 0-6 6-6-6"
            fill="none"
            stroke="#333"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            transform="translate(.531 .531)"
          />
        </svg>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseTabPropsClassName>`
  border-radius: 10px 10px 0 0;
  position: relative;
  box-shadow: ${rem(3)} 0 0 #d1d1d1;

  @media ${breakpoints.lg} {
    border-radius: 20px 20px 0 0;
    box-shadow: ${rem(6)} 0 0 #d1d1d1;
  }

  &:hover,
  &.is-current {
    > .e-course-content {
      background-color: ${winterColor.main};
      color: #fff;
    }

    .e-course-path {
      stroke: #fff;
    }
  }

  > .e-course-content {
    padding: ${rem(20 / 2)} ${rem(10 / 2)};
    border: 1.5px solid #333;
    border-radius: inherit;
    background-color: #dcedff;
    border-bottom: none;
    text-align: center;
    position: relative;
    z-index: 1;
    transition: background-color 0.3s ease 0s;
    cursor: pointer;

    @media ${breakpoints.lg} {
      padding-top: ${rem(15)};
      padding-bottom: ${rem(15)};
      border-width: 3px;
    }

    > .e-course-image {
      img {
        @media ${breakpoints.lg} {
          width: ${rem(120)};
        }
      }
    }

    > .e-course-title {
      margin: ${rem(10)} 0 0;
      font-size: ${rem(18)};
      line-height: calc(20 / 18);
      letter-spacing: 0.1em;
      font-weight: 500;
      font-family: ${fontFamily.zenKaku};

      @media ${breakpoints.lg} {
        margin-top: ${rem(5)};
        font-size: ${rem(28)};

        > .is-sp {
          display: none;
        }
      }
    }
  }

  .e-course-arrow {
    width: ${rem(12)};
    display: block;
    margin: ${rem(4)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(18)};
      margin-top: ${rem(5)};
    }
  }

  .e-course-path {
    stroke: #333;
  }
`

export const CourseTab: (props: CourseTabProps) => JSX.Element = StyledComponent

export default CourseTab
