import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, winterColor, fontFamily, innerWidth, summerColor, innerSeasonWidth } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'
import headTextImage from 'assets/images/winter/about/worry_head.svg'
import checkIcon from 'assets/images/winter/about/check_icon.svg'
import arrowIcon from 'assets/images/winter/about/arrow.svg'
import logoImage from 'assets/images/winter/about/logo.svg'
import resultTextImageSP from 'assets/images/winter/about/result_text_sp.svg'
import headType3LineSP from 'assets/images/winter/about/line_sp.svg'
import bracketsLeft from 'assets/images/winter/about/brackets_left.svg'
import bracketsRight from 'assets/images/winter/about/brackets_right.svg'
import reasonImage1SP from 'assets/images/winter/about/reason1-sp.png'
import reasonImage1PC from 'assets/images/winter/about/reason1-pc.jpg'
import reasonImage2_2SP from 'assets/images/winter/about/reason2-2-sp.png'
import reasonImage2_2PC from 'assets/images/winter/about/reason2-2-pc.jpg'
import classNames from 'classnames'

export interface AboutSectionProps {
  readonly noProps?: string
}

type AboutSectionPropsClassName = AboutSectionProps & {
  readonly className: string
}

function Component({ className }: AboutSectionPropsClassName) {
  return (
    <div className={className}>
      <h2 className="c-about-head">
        <span className="c-about-head__content">
          <img className="c-about-head__text" src={headTextImage} alt="こんなお悩みありませんか？" />
          <StaticImage className="c-about-head__image" src="../../../assets/images/winter/about/worry_girl.png" alt="" placeholder="none" />
        </span>
      </h2>

      <ul className="c-lists">
        <li className="c-lists__item">2学期でテストの点数が下がった</li>
        <li className="c-lists__item">どこがわからないのかわからない</li>
        <li className="c-lists__item">勉強のやり方がわからない</li>
      </ul>

      <img className="c-arrow" src={arrowIcon} alt="" width={53.5} height={48.51} loading="lazy" decoding="async" />

      <p className="c-result">
        <img className="c-result__logo" src={logoImage} alt="Fit NET STUDY" loading="lazy" decoding="async" />
        <span className="c-result__text">なら</span>
        <img className="c-result__image" src={resultTextImageSP} alt="3学期のテストで点数アップできる！" loading="lazy" decoding="async" />
      </p>

      <div className="c-illust">
        <StaticImage className="c-illust__image" src="../../../assets/images/winter/about/reason_girl.jpg" alt="" placeholder="none" />
        <StaticImage className="c-illust__drop1" src="../../../assets/images/winter/mv/snow/snow6.png" alt="" placeholder="none" />
        <StaticImage className="c-illust__drop2" src="../../../assets/images/winter/mv/snow/snow7.png" alt="" placeholder="none" />
        <StaticImage className="c-illust__drop3" src="../../../assets/images/winter/mv/snow/snow3.png" alt="" placeholder="none" />
      </div>
      <div className="c-reason-box">
        <h3 className="c-reason-box__head">
          点数アップできる
          <span className="is-em">理由1</span>
        </h3>
        <div className="c-heading-brackets">
          <p className="c-heading-brackets__pop">苦手がなくなる</p>
          <h4 className="c-heading-brackets__head">総復習</h4>
        </div>
        <div className={classNames('c-reason-box__content', 'c-reason-card')}>
          <div className="c-reason-card__image">
            <picture>
              <source media="(min-width: 64rem)" srcSet={reasonImage1PC} width="400" height="250" />
              <img src={reasonImage1SP} alt="" loading="lazy" width="272" height="158" />
            </picture>
          </div>
          <div className="c-reason-card__body">
            <p className="c-reason-card__text">
              <strong className="is-em">中学入学時から今までの学習範囲を総復習</strong>
              。“つまずき”の原因である苦手をなくすことで基礎学力が身につきます。その結果、学校の授業が理解しやすくなり、テストの点数をアップさせることができます。
            </p>
          </div>
        </div>
      </div>
      <div className="c-illust">
        <StaticImage className="c-illust__image" src="../../../assets/images/winter/about/reason_girl.jpg" alt="" placeholder="none" />
        <StaticImage className="c-illust__drop1" src="../../../assets/images/winter/mv/snow/snow6.png" alt="" placeholder="none" />
        <StaticImage className="c-illust__drop2" src="../../../assets/images/winter/mv/snow/snow7.png" alt="" placeholder="none" />
        <StaticImage className="c-illust__drop3" src="../../../assets/images/winter/mv/snow/snow3.png" alt="" placeholder="none" />
      </div>
      <div className="c-reason-box">
        <h3 className="c-reason-box__head">
          点数アップできる
          <span className="is-em">理由2</span>
        </h3>
        <div className="c-heading-brackets">
          <p className="c-heading-brackets__pop">自宅でも安心</p>
          <h4 className="c-heading-brackets__head">オンライン授業</h4>
        </div>
        <h5 className="c-reason-box__head-type3">
          対面式だから
          <br className="u-hidden-pc" />
          お子さまの様子が見える
        </h5>
        <div className={classNames('c-reason-box__content', 'c-reason-card')}>
          <div className="c-reason-card__image">
            <StaticImage src="../../../assets/images/winter/about/reason2-1.png" alt="" placeholder="none" />
          </div>
          <div className="c-reason-card__body">
            <p className="c-reason-card__text">
              <strong className="is-em">お子さまの理解度に合わせた指導を行う</strong>のでわかりやすく、自宅でも集中が続きます。
            </p>
            <div className={classNames('c-reason-card__illust1', 'u-hidden-sp')}>
              <StaticImage src="../../../assets/images/winter/about/reason-illust1.jpg" alt="" placeholder="none" />
            </div>
          </div>
        </div>
        <h5 className={classNames('c-reason-box__head-type3', 'is-second-onward')}>
          その場で
          <br className="u-hidden-pc" />
          講師に質問できる
        </h5>
        <div className={classNames('c-reason-box__content', 'c-reason-card')}>
          <div className="c-reason-card__image">
            <picture>
              <source media="(min-width: 64rem)" srcSet={reasonImage2_2PC} width="400" height="250" />
              <img src={reasonImage2_2SP} alt="" loading="lazy" width="272" height="158" />
            </picture>
          </div>
          <div className="c-reason-card__body">
            <p className="c-reason-card__text">
              その場で質問ができるので、
              <strong className="is-em">授業中の「わからない」をそのままにしません</strong>。解説も個別に行います。
            </p>
            <div className={classNames('c-reason-card__illust2', 'u-hidden-sp')}>
              <StaticImage src="../../../assets/images/winter/about/reason-illust2.jpg" alt="" placeholder="none" />
            </div>
          </div>
        </div>
        <h5 className={classNames('c-reason-box__head-type3', 'is-second-onward')}>
          見やすく
          <br className="u-hidden-pc" />
          わかりやすい解説
        </h5>
        <div className={classNames('c-reason-box__content', 'c-reason-card')}>
          <div className="c-reason-card__image">
            <StaticImage src="../../../assets/images/winter/about/reason2-3.png" alt="" placeholder="none" />
          </div>
          <div className="c-reason-card__body">
            <p className="c-reason-card__text">
              解説はZoom画面にテキストを映し、
              <strong className="is-em">直接書き込みながら</strong>行います。
            </p>
            <div className={classNames('c-reason-card__illust3', 'u-hidden-sp')}>
              <StaticImage src="../../../assets/images/winter/about/reason-illust3.jpg" alt="" placeholder="none" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<AboutSectionPropsClassName>`
  padding-top: ${rem(80)};
  width: ${innerSeasonWidth.sm};
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media ${breakpoints.lg} {
    padding-top: ${rem(120)};
    width: ${rem(1080)};
    padding-left: ${rem(40)};
    padding-right: ${rem(40)};
  }

  @keyframes DropAboutSection {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(${rem(10)});
    }
    100% {
      transform: translateY(0);
    }
  }

  .c-about-head {
    text-align: center;
    position: relative;
    border-bottom: 1.5px solid ${winterColor.main};
  }

  .c-about-head__content {
    position: relative;
    display: block;
    margin-inline: auto;
    width: fit-content;
    padding-bottom: ${rem(22)};
    padding-inline: ${rem(77)};

    @media ${breakpoints.lg} {
      padding-bottom: ${rem(25)};
      padding-inline: ${rem(130)};
    }
  }

  .c-about-head__text {
    display: block;
    width: ${rem(210)};
    margin: 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(330)};
    }
  }

  .c-about-head__image {
    position: absolute;
    right: ${rem(0)};
    bottom: ${rem(0)};
    width: ${rem(72)};
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(110)};
    }
  }

  .c-lists {
    margin: ${rem(29)} auto 0;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: ${rem(15)};
    list-style: none;

    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
      gap: ${rem(20)};
    }
  }

  .c-lists__item {
    position: relative;
    font-family: ${fontFamily.zenKaku};
    font-size: ${rem(18)};
    font-weight: 500;
    padding-bottom: ${rem(1)};
    border-bottom: 1px solid ${winterColor.main};
    margin: 0 0 0 ${rem(27)};
    line-height: 1.6;
    width: fit-content;

    @media ${breakpoints.lg} {
      font-size: ${rem(22)};
      border-bottom-width: 1.5px;
    }

    &::before {
      content: '';
      position: absolute;
      left: ${rem(-27)};
      top: ${rem(6)};
      width: ${rem(21.01)};
      height: ${rem(16.77)};
      background: url(${checkIcon}) no-repeat center center / contain;

      @media ${breakpoints.lg} {
        width: ${rem(28.18)};
        height: ${rem(22.48)};
        left: ${rem(-37)};
      }
    }
  }

  .c-arrow {
    width: ${rem(53.5)};
    margin: ${rem(22)} auto 0;
    display: block;

    @media ${breakpoints.lg} {
      width: ${rem(61.7)};
      margin-block: ${rem(23)};
    }
  }

  .c-result {
    margin: ${rem(20)} 0 0;
    vertical-align: baseline;
    text-align: center;
    font-family: ${fontFamily.zenKaku};
    font-weight: 500;
    padding-bottom: ${rem(10)};

    @media ${breakpoints.lg} {
    }
  }

  .c-result__logo {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    width: ${rem(200)};

    @media ${breakpoints.lg} {
      width: ${rem(320)};
    }
  }

  .c-result__text {
    margin-left: ${rem(10)};
    vertical-align: middle;
    font-size: ${rem(18)};

    @media ${breakpoints.lg} {
      font-size: ${rem(22)};
    }
  }

  .c-result__image {
    display: block;
    margin: ${rem(18)} auto 0;
    width: ${rem(303)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
      width: ${rem(481.26)};
    }
  }

  .c-illust {
    width: ${rem(106)};
    margin-inline: auto;
    margin-top: ${rem(40)};
    position: relative;
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(50)};
      width: ${rem(146)};
    }
  }

  .c-illust__image {
    width: ${rem(90)};

    @media ${breakpoints.lg} {
      width: ${rem(144)};
    }
  }

  .c-illust__drop1,
  .c-illust__drop2,
  .c-illust__drop3 {
    position: absolute;
    line-height: 0;
  }

  .c-illust__drop1 {
    width: ${rem(14)};
    top: ${rem(10)};
    right: calc(50% + ${rem(38)});
    animation: DropAboutSection 5.1s ease-in-out 0s infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(20.55)};
      top: ${rem(15)};
      right: calc(50% + ${rem(50)});
    }
  }

  .c-illust__drop2 {
    width: ${rem(10)};
    top: ${rem(5)};
    left: calc(50% + ${rem(25)});
    animation: DropAboutSection 5.1s ease-in-out 1s infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(14.47)};
      top: ${rem(16)};
      left: calc(50% + ${rem(36)});
    }
  }

  .c-illust__drop3 {
    width: ${rem(13.43)};
    top: ${rem(22)};
    left: calc(50% + ${rem(40)});
    animation: DropAboutSection 5.1s ease-in-out 2s infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(11)};
      top: ${rem(36)};
      left: calc(50% + ${rem(60)});
    }
  }

  .c-reason-box {
    border-radius: ${rem(10)};
    background: #fff;
    border: 1.5px solid ${winterColor.main};
    padding: ${rem(12)} ${rem(30)} ${rem(36)};

    @media ${breakpoints.lg} {
      border-radius: ${rem(20)};
      padding-inline: ${rem(80)};
      padding-bottom: ${rem(60)};
      border-width: 1.5px;
    }
  }

  .c-reason-box__head {
    padding-bottom: ${rem(8)};
    border-bottom: 1px solid ${winterColor.main};
    text-align: center;
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(20)};
    letter-spacing: 0.15em;
    line-height: 1.6;
    text-align: center;
    margin-bottom: ${rem(42)};
    margin-inline: ${rem(-30)};

    @media ${breakpoints.lg} {
      font-size: ${rem(29)};
      margin-inline: ${rem(-80)};
      margin-bottom: ${rem(46)};
      border-bottom-width: 1.5px;
    }

    .is-em {
      color: ${winterColor.main};
      font-size: ${rem(28)};

      @media ${breakpoints.lg} {
        font-size: ${rem(40)};
      }
    }
  }

  .c-heading-brackets {
    width: ${rem(258)};
    max-width: 100%;
    margin-inline: auto;
    margin-bottom: ${rem(34)};
    background: url(${bracketsLeft}) no-repeat left center / auto 100%, url(${bracketsRight}) no-repeat right center / auto 100%;

    @media ${breakpoints.lg} {
      width: ${rem(424)};
      margin-bottom: ${rem(35)};
    }
  }

  .c-heading-brackets__pop {
    text-align: center;
    font-family: ${fontFamily.zenKaku};
    font-weight: 500;
    font-size: ${rem(18)};
    letter-spacing: 0.2em;
    line-height: 1;
    text-align: center;
    margin: 0 0 ${rem(10)};

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
      margin-bottom: ${rem(14)};
    }
  }

  .c-heading-brackets__head {
    text-align: center;
    line-height: 1;
    color: ${winterColor.main};
    font-weight: 700;
    font-size: ${rem(24)};
    letter-spacing: 0.15em;
    font-family: ${fontFamily.zenKaku};

    @media ${breakpoints.lg} {
      font-size: ${rem(36)};
    }
  }

  .c-reason-box__head-type3 {
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(20)};
    letter-spacing: 0.1em;
    line-height: calc(28 / 20);
    text-align: center;
    color: ${winterColor.main};
    text-align: center;
    margin-bottom: ${rem(20)};
    margin-top: ${rem(30)};
    position: relative;
    padding-top: ${rem(34)};

    @media ${breakpoints.lg} {
      font-size: ${rem(32)};
      padding-top: ${rem(50)};
    }

    &::before {
      content: '';
      height: 1.5px;
      width: ${rem(220)};
      background: ${winterColor.main};
      position: absolute;
      top: 0;
      inset-inline: 0;
      margin-inline: auto;

      @media ${breakpoints.lg} {
        width: ${rem(640)};
      }
    }

    &.is-second-onward {
      margin-top: ${rem(60)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(50)};
      }
    }
  }

  .c-reason-box__content {
    margin-top: ${rem(20)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(30)};
    }
  }

  .c-reason-card {
    display: flex;
    flex-direction: column;
    gap: ${rem(20)};

    @media ${breakpoints.lg} {
      flex-direction: row;
      gap: ${rem(40)};
    }
  }

  .c-reason-card__image {
    @media ${breakpoints.lg} {
      flex: 1;
    }

    picture {
      display: block;

      img {
        margin-bottom: 0;
      }
    }
  }

  .c-reason-card__body {
    @media ${breakpoints.lg} {
      flex: 1;
    }
  }

  .c-reason-card__text {
    font-size: ${rem(16)};
    line-height: calc(28 / 16);
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      line-height: calc(33 / 18);
      letter-spacing: 0.01em;
    }

    .is-em {
      color: ${winterColor.main};
      font-weight: inherit;
    }
  }

  .c-reason-card__illust1,
  .c-reason-card__illust2,
  .c-reason-card__illust3 {
    @media ${breakpoints.lg} {
      text-align: center;
      margin-top: ${rem(50)};
    }
  }

  .c-reason-card__illust1 {
    img {
      width: ${rem(200)};
    }
  }

  .c-reason-card__illust2 {
    img {
      width: ${rem(200)};
    }
  }

  .c-reason-card__illust3 {
    img {
      width: ${rem(200)};
    }
  }
`

export const AboutSection: (props: AboutSectionProps) => JSX.Element = StyledComponent

export default AboutSection
