import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { rem, winterColor, breakpoints } from 'variables/_index'
import ArrowCircleRight from 'components/icon/arrowCircleRight'

export interface WinterDocumentButtonProps {
  readonly href: string
}

type WinterDocumentButtonPropsClassName = WinterDocumentButtonProps & {
  readonly className: string
}

function Component({ className, href }: WinterDocumentButtonPropsClassName) {
  return (
    <a className={className} href={href}>
      <div className="e-content">
        <span className="e-illust">
          <StaticImage src="../../../../assets/images/illustration/illustration15-3.png" alt="" placeholder="none" />
        </span>
        <span className="e-text">資料請求はこちら</span>
        <div className="e-icon">
          <ArrowCircleRight color="#ff8c00" />
        </div>
      </div>
    </a>
  )
}

const StyledComponent = styled(Component)<WinterDocumentButtonPropsClassName>`
  display: inline-block;
  border-radius: 200px;
  position: relative;
  text-decoration: none;
  font-weight: 700;

  > .e-content {
    display: flex;
    border-radius: 300px;
    border: 4px solid #fff;
    background-color: ${winterColor.accent3};
    color: #fff;
    align-items: center;
    width: ${rem(600 / 2)};
    font-size: ${rem(32 / 2)};
    padding: ${rem(20)} ${rem(30)} ${rem(20)} ${rem(72)};
    transition: all 0.3s ease 0s, color 0.3s ease 0s;
    position: relative;
    z-index: 2;
    letter-spacing: 0.3em;

    @media ${breakpoints.lg} {
      width: ${rem(500)};
      padding: ${rem(30)} ${rem(40)} ${rem(30)} ${rem(146)};
      font-size: ${rem(24)};
      border-width: 6px;
    }

    > .e-illust {
      width: ${rem(34.15)};
      height: ${rem(21)};
      position: absolute;
      left: ${rem(25)};
      top: 50%;
      transform: translateY(-50%);

      img {
      }

      @media ${breakpoints.lg} {
        width: ${rem(50.4)};
        left: ${rem(40)};
      }
    }

    > .e-text {
    }

    > .e-icon {
      position: absolute;
      top: 50%;
      right: ${rem(15)};
      transform: translateY(-50%);
    }

    &:hover,
    &:focus {
      background-color: #effbff;
      color: #0093ff;
      border-color: #b9f0ff;

      > .e-icon {
        path {
          stroke: #0093ff;
        }

        g {
          fill: #effbff;
          stroke: #effbff;
        }
      }
    }
  }

  @media ${breakpoints.lg} {
  }
`

export const WinterDocumentButton: (props: WinterDocumentButtonProps) => JSX.Element = StyledComponent

export default WinterDocumentButton
