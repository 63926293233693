import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, winterColor } from 'variables/_index'
import ArrowCircleRight from 'components/icon/arrowCircleRight'
import { Link } from 'gatsby'

export interface WinterContactButtonProps {
  readonly href: string
  readonly imageElement: JSX.Element
}

type WinterContactButtonPropsClassName = WinterContactButtonProps & {
  readonly className: string
}

function Component({ className, href, imageElement }: WinterContactButtonPropsClassName) {
  return (
    <Link className={className} to={href}>
      <div className="e-content">
        <span className="e-illust">{imageElement}</span>
        <span className="e-text">無料体験してみる</span>
        <div className="e-icon">
          <ArrowCircleRight color="#0000FF" />
        </div>
      </div>
    </Link>
  )
}

const StyledComponent = styled(Component)<WinterContactButtonPropsClassName>`
  display: inline-block;
  border-radius: 200px;
  position: relative;
  text-decoration: none;
  font-weight: 700;

  @keyframes WinterContactButtonSway {
    0% {
      transform: translate(${rem(-30)}, ${rem(4)}) rotate(18deg);
    }

    100% {
      transform: translate(${rem(8)}, ${rem(-8)}) rotate(-16deg);
    }
  }

  @keyframes WinterContactButtonSwayPC {
    0% {
      transform: translate(${rem(-30)}, ${rem(4)}) rotate(18deg);
    }

    100% {
      transform: translate(${rem(28)}, ${rem(-8)}) rotate(-16deg);
    }
  }

  > .e-content {
    display: flex;
    align-items: center;
    border-radius: 300px;
    border: 4px solid #fff;
    background-color: ${winterColor.accent2};
    color: #fff;
    align-items: center;
    width: ${rem(300)};
    font-size: ${rem(16)};
    padding: ${rem(20)} ${rem(30)} ${rem(20)} ${rem(72)};
    position: relative;
    z-index: 2;
    transition: all 0.3s ease 0s, color 0.3s ease 0s;
    position: relative;
    letter-spacing: 0.3em;

    @media ${breakpoints.lg} {
      width: ${rem(500)};
      padding: ${rem(30)} ${rem(40)} ${rem(30)} ${rem(130)};
      font-size: ${rem(24)};
      border-width: 6px;
      letter-spacing: 0.2em;
    }

    > .e-illust {
      width: ${rem(71.03 / 2)};
      height: ${rem(40 / 2)};
      position: absolute;
      left: ${rem(30 / 2)};
      top: 50%;
      margin-top: ${rem(-29 / 2 / 2)};
      animation: WinterContactButtonSway 2.4s ease-in-out infinite alternate;

      img {
      }

      @media ${breakpoints.lg} {
        width: ${rem(49.72)};
        height: ${rem(28)};
        animation: WinterContactButtonSwayPC 3.4s ease-in-out infinite alternate;
      }
    }

    > .e-text {
    }

    > .e-icon {
      position: absolute;
      top: 50%;
      right: ${rem(15)};
      transform: translateY(-50%);

      @media ${breakpoints.lg} {
        right: ${rem(24)};
      }
    }

    &:hover,
    &:focus {
      background-color: #effbff;
      color: #0093ff;
      border-color: #b9f0ff;

      > .e-icon {
        path {
          stroke: #0093ff;
        }

        g {
          fill: #effbff;
          stroke: #effbff;
        }
      }
    }
  }

  @media ${breakpoints.lg} {
  }
`

export const WinterContactButton: (props: WinterContactButtonProps) => JSX.Element = StyledComponent

export default WinterContactButton
