import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerWidth, fontFamily, winterColor, innerSeasonWidth } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import iconSpeech from 'assets/images/winter/overview/icon-speech1.svg'

export interface OverviewSectionProps {
  readonly noProps?: string
}

type OverviewSectionPropsClassName = OverviewSectionProps & {
  readonly className: string
}

function Component({ className }: OverviewSectionPropsClassName) {
  return (
    <div id="a-overview-high" className={className}>
      <div className="l-inner">
        <div className="c-course-head">
          <div className="c-drop1">
            <div className="c-drop1__fadeIn">
              <StaticImage
                className="c-drop1__image"
                src="../../../assets/images/winter/mv/snow/snow1.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
          </div>
          <div className="c-drop2">
            <div className="c-drop2__fadeIn">
              <StaticImage
                className="c-drop2__image"
                src="../../../assets/images/winter/mv/snow/snow8.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
          </div>
          <div className="c-drop3">
            <div className="c-drop3__fadeIn">
              <StaticImage
                className="c-drop3__image"
                src="../../../assets/images/winter/mv/snow/snow11.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
          </div>
          <div className="c-drop4">
            <div className="c-drop4__fadeIn">
              <StaticImage
                className="c-drop4__image"
                src="../../../assets/images/winter/mv/snow/snow9.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
          </div>
          <div className="c-drop5">
            <div className="c-drop5__fadeIn">
              <StaticImage
                className="c-drop5__image"
                src="../../../assets/images/winter/mv/snow/snow4.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
          </div>
          <h4 className="c-course-head__title">冬期講習の概要</h4>
        </div>
        <div className="c-box">
          <h5 className="c-box__head">日程と時間割</h5>
          <p className="c-overview-text">
            <span className={classNames('is-underline', 'is-bold', 'is-color')}>❶日程・❷時間割・❸教科</span>の中から、
            <span className={classNames('is-underline', 'is-color')}>自由にスケジュールを選んで受講</span>
            できます。
          </p>
          <div className="c-box-table1">
            <h6 className="c-box-table1__head">❶日程</h6>
            <ul className="c-box-table1__lists">
              <li className="c-box-table1__list">12/11(月)〜1/13(土)</li>
            </ul>
            <p className="c-box-table1__attention">※日曜・冬期休業(12/28〜1/3)は除く</p>
          </div>
          <svg className="c-cross-icon" height="16.061" viewBox="0 0 16.061 16.061" width="16.061" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" stroke="#333" strokeWidth="1.5" transform="translate(-2502.469 8039.576)">
              <path d="m2503-8039.046 15 15" />
              <path d="m2518-8039.046-15 15" />
            </g>
          </svg>
          <div className="c-box-table2">
            <h6 className="c-box-table2__head">❷時間割</h6>
            <ul className="c-box-table2__lists">
              <li className={classNames('c-box-table2__a', 'c-box-table2__list')}>
                <span className="c-box-table2__list-head">1時間目｜</span>14:30～15:50
              </li>
              <li className={classNames('c-box-table2__b', 'c-box-table2__list')}>
                <span className="c-box-table2__list-head">2時間目｜</span>16:00～17:20
              </li>
              <li className={classNames('c-box-table2__c', 'c-box-table2__list')}>
                <span className="c-box-table2__list-head">3時間目｜</span>17:30～18:50
              </li>
              <li className={classNames('c-box-table2__d', 'c-box-table2__list')}>
                <span className="c-box-table2__list-head">4時間目｜</span>19:00～20:20
              </li>
              <li className={classNames('c-box-table2__e', 'c-box-table2__list')}>
                <span className="c-box-table2__list-head">5時間目｜</span>20:30～21:50
              </li>
            </ul>
          </div>
          <svg className="c-cross-icon" height="16.061" viewBox="0 0 16.061 16.061" width="16.061" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" stroke="#333" strokeWidth="1.5" transform="translate(-2502.469 8039.576)">
              <path d="m2503-8039.046 15 15" />
              <path d="m2518-8039.046-15 15" />
            </g>
          </svg>
          <div className="c-box-table3">
            <h6 className="c-box-table3__head">❸教科</h6>
            <ul className="c-box-table3__lists">
              <li className={classNames('c-box-table3__a', 'c-box-table3__list')}>
                <span className="c-box-table3__list-head">高1</span>
                <br />
                <span className="c-box-table3__list-text">英語文法・数I・数A・数II・数B 物理基礎・化学基礎・生物基礎</span>
              </li>
              <li className={classNames('c-box-table3__b', 'c-box-table3__list')}>
                <span className="c-box-table3__list-head">高2</span>
                <br />
                <span className="c-box-table3__list-text">
                  英語文法・英語構文
                  <br className="u-hidden-pc" />
                  数I・数A・数II・数B
                  <br />
                  物理基礎・化学基礎・生物基礎
                </span>
              </li>
            </ul>
          </div>
          <div className="c-example">
            <p className="c-example__head">受講例</p>
            <div className="c-example__box">
              <div className="c-example__sub-head-image">
                <img src={iconSpeech} alt="" />
              </div>
              <div className="c-example__body">
                <p className="c-example__sub-head-text">高校1年生のBさんの場合・・・</p>
                <p className="c-example__content">
                  <span className="is-em">12/11～14</span>の<span className="is-em">2時間目</span>に<span className="is-em">数学Ⅰ</span>を
                  4コマ受講しています。
                </p>
              </div>
            </div>
          </div>
          <h5 className={classNames('c-box__head', 'is-mt')}>授業形式</h5>
          <div className="c-box-table4">
            <p className="c-box-table4__time">1回｜80分授業</p>
            <ul className="c-box-table4__lists">
              <li className={classNames('c-box-table4__a', 'c-box-table4__list')}>
                <span className="c-box-table4__list-head">数英｜</span>
                講師1人 対 生徒2人
              </li>
              <li className={classNames('c-box-table4__b', 'c-box-table4__list')}>
                <span className="c-box-table4__list-head">理　｜</span>
                講師1人 対 生徒5人
              </li>
            </ul>
          </div>
          <h5 className={classNames('c-box__head', 'is-mt2')}>料金</h5>
          <div className="c-box-table5">
            <ul className="c-box-table5__lists">
              <li className={classNames('c-box-table5__a', 'c-box-table5__list')}>
                <span className="c-box-table5__list-head">4コマ</span>
                <span className="c-box-table5__list-free">無　料</span>
              </li>
              <li className={classNames('c-box-table5__b', 'c-box-table5__list')}>
                <span className="c-box-table5__list-head">8コマ</span>
                <span className="c-box-table5__list-text">5,900円</span>
              </li>
              <li className={classNames('c-box-table5__c', 'c-box-table5__list')}>
                <span className="c-box-table5__list-head">12コマ</span>
                <span className="c-box-table5__list-text">11,800円</span>
              </li>
            </ul>
            <ul className="c-box-table5__attentions">
              <li className="e-box-table5-attention">表示価格は税込みです。</li>
              <li className="e-box-table5-attention">
                テキスト料金は別途かかります。
                <br />
                3,320円/冊　送料／2冊まで:800円&nbsp;&nbsp;&nbsp;3冊以上:1,000円
              </li>
            </ul>
          </div>
        </div>
        <div className="c-deadline">
          <p className="c-deadline__head">最終申し込み締め切り</p>
          <p className="c-deadline__date">12/16(土)</p>
          <ul className="c-deadline__attentions">
            <li className="c-deadline__attention">※申し込み締め切り日は日程によって異なります。</li>
            <li className="c-deadline__attention">※満席になり次第、申し込みを締め切る場合があります。</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<OverviewSectionPropsClassName>`
  padding-top: ${rem(42)};
  padding-bottom: ${rem(50)};
  background: ${winterColor.mainBackground};

  @media ${breakpoints.lg} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
  }

  @keyframes DropOverviewSection {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(${rem(10)});
    }
    100% {
      transform: translateY(0);
    }
  }

  @media ${breakpoints.lg} {
    margin-left: auto;
    margin-right: auto;
  }

  .l-inner {
    width: calc(${innerSeasonWidth.sm} + ${rem(10)});
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @media ${breakpoints.lg} {
      width: ${rem(1080)};
      padding-left: ${rem(40)};
      padding-right: ${rem(40)};
    }
  }

  .c-course-head {
    position: relative;
    padding-top: ${rem(56)};
  }

  .c-course-head__title {
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(24)};
    letter-spacing: 0.15em;
    line-height: 1.6;
    text-align: center;
    color: ${winterColor.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(36)};
    }
  }

  .c-drop1 {
    position: absolute;
    top: ${rem(16)};
    right: calc(50% + ${rem(56)});

    @media ${breakpoints.lg} {
      top: ${rem(3)};
      right: calc(50% + ${rem(84)});
    }

    &__fadeIn {
    }

    &__image {
      width: ${rem(20)};
      animation: DropOverviewSection 5.1s ease-in-out infinite backwards;

      @media ${breakpoints.lg} {
        width: ${rem(31.25)};
      }
    }
  }

  .c-drop2 {
    position: absolute;
    top: ${rem(8)};
    right: calc(50% + ${rem(22)});

    @media ${breakpoints.lg} {
      top: ${rem(-12)};
      right: calc(50% + ${rem(28)});
    }

    &__fadeIn {
    }

    &__image {
      width: ${rem(17)};
      animation: DropOverviewSection 5.1s ease-in-out infinite backwards;

      @media ${breakpoints.lg} {
        width: ${rem(24.16)};
      }
    }
  }

  .c-drop3 {
    position: absolute;
    top: ${rem(36)};
    left: calc(50% + ${rem(4)});

    @media ${breakpoints.lg} {
      top: ${rem(19)};
      left: calc(50% + ${rem(3)});
    }

    &__fadeIn {
    }

    &__image {
      width: ${rem(9)};
      animation: DropOverviewSection 5.1s ease-in-out 3s infinite backwards;

      @media ${breakpoints.lg} {
        width: ${rem(12.61)};
      }
    }
  }

  .c-drop4 {
    position: absolute;
    top: ${rem(18)};
    left: calc(50% + ${rem(34)});

    @media ${breakpoints.lg} {
      top: ${rem(8)};
      left: calc(50% + ${rem(46)});
    }

    &__fadeIn {
    }

    &__image {
      width: ${rem(19)};
      animation: DropOverviewSection 5.1s ease-in-out infinite backwards;

      @media ${breakpoints.lg} {
        width: ${rem(28.29)};
      }
    }
  }

  .c-drop5 {
    position: absolute;
    top: ${rem(18)};
    left: calc(50% + ${rem(74)});

    @media ${breakpoints.lg} {
      top: ${rem(4)};
      left: calc(50% + ${rem(102)});
    }

    &__fadeIn {
    }

    &__image {
      width: ${rem(9)};
      animation: DropOverviewSection 5.1s ease-in-out infinite backwards;

      @media ${breakpoints.lg} {
        width: ${rem(13.43)};
      }
    }
  }

  .c-box {
    margin-top: ${rem(16)};
    border-radius: ${rem(10)};
    background: #fff;
    border: 1.5px solid #333;
    padding: ${rem(40)} ${rem(30)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(56)};
      border-radius: ${rem(20)};
      border-width: 3px;
      padding: ${rem(60)} ${rem(236)} ${rem(50)};
    }
  }

  .c-box__head {
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(20)};
    letter-spacing: 0.3em;
    line-height: 1;
    text-align: center;
    color: ${winterColor.main};
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: ${rem(8)} ${rem(3)};
    margin-left: ${rem(-30)};
    margin-right: ${rem(-30)};

    @media ${breakpoints.lg} {
      margin-left: ${rem(-60)};
      margin-right: ${rem(-60)};
      font-size: ${rem(26)};
      border-top-width: 1.5px;
      border-bottom-width: 1.5px;
      padding-block: ${rem(14)};
    }

    &.is-mt {
      margin-top: ${rem(40)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(58)};
      }
    }

    &.is-mt2 {
      margin-top: ${rem(34)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(50)};
      }
    }

    &.is-mt3 {
      margin-top: ${rem(38)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(54)};
      }
    }
  }

  .c-overview-text {
    margin: ${rem(26)} 0 0;
    font-size: ${rem(16)};
    line-height: 2;

    @media ${breakpoints.lg} {
      margin-top: ${rem(38)};
      font-size: ${rem(20)};
      line-height: calc(36 / 20);
    }

    .is-bold {
      font-weight: 700;
    }

    .is-underline {
      text-decoration: underline;
      text-underline-offset: ${rem(6)};
    }

    .is-color {
      color: ${winterColor.main};
    }
  }

  .c-box-table1 {
    margin-top: ${rem(10)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
    }
  }

  .c-box-table1__head,
  .c-box-table2__head,
  .c-box-table3__head {
    font-size: ${rem(16)};
    font-family: ${fontFamily.zenKaku};
    color: ${winterColor.main};
    letter-spacing: 0.3em;
    font-weight: 700;
    line-height: 1;
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(22)};
    }
  }

  .c-box-table1__lists,
  .c-box-table2__lists {
    margin: ${rem(8)} auto 0;
    border-top: 1px solid ${winterColor.main};
    border-bottom: 1px solid ${winterColor.main};
    background: #fff;
    padding: ${rem(0)};
    list-style: none;
    width: ${rem(240)};

    @media ${breakpoints.lg} {
      width: ${rem(520)};
      margin-top: ${rem(16)};
    }
  }

  .c-box-table2__lists {
    margin-top: ${rem(14)};
  }

  .c-box-table1__list {
    font-size: ${rem(14)};
    white-space: nowrap;
    line-height: 1.94;
    margin-bottom: 0;
    letter-spacing: 0.1em;
    padding: ${rem(5)};
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      line-height: calc(40 / 16);
      letter-spacing: 0.1em;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d0d0d0;
    }
  }

  .c-box-table1__attention {
    font-size: ${rem(10)};
    line-height: 1.6;
    margin: ${rem(10)} auto 0;
    width: ${rem(240)};

    @media ${breakpoints.lg} {
      width: ${rem(343)};
      font-size: ${rem(12)};
    }
  }

  .c-box-table1__a {
  }

  .c-box-table1__b {
  }

  .c-box-table1__c {
  }

  .c-box-table1__list-head {
    color: ${winterColor.main};
  }

  .c-cross-icon {
    width: ${rem(15)};
    display: block;
    margin: ${rem(20)} auto ${rem(16)};
  }

  .c-box-table2__head {
  }

  .c-box-table2__lists {
    width: ${rem(240)};
    max-width: 100%;
    margin-inline: auto;

    @media ${breakpoints.lg} {
      width: 100%;
    }
  }

  .c-box-table2__list {
    font-size: ${rem(14)};
    white-space: nowrap;
    line-height: 1.92;
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      line-height: calc(40 / 16);
      letter-spacing: 0.1em;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d0d0d0;
    }
  }

  .c-box-table2__list-head {
    color: ${winterColor.main};
  }

  .c-box-table2__a {
  }

  .c-box-table2__b {
  }

  .c-box-table2__c {
  }

  .c-box-table2__d {
  }

  .c-box-table2__e {
  }

  .c-box-table3 {
  }

  .c-box-table3__head {
  }

  .c-box-table3__lists {
    margin: ${rem(14)} auto 0;
    border-top: 1px solid ${winterColor.main};
    border-bottom: 1px solid ${winterColor.main};
    background: #fff;
    padding: ${rem(10)} ${rem(0)};
    list-style: none;
    width: ${rem(240)};
    max-width: 100%;

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
      width: 100%;
    }
  }

  .c-box-table3__list {
    font-size: ${rem(14)};
    letter-spacing: 0.1em;
    line-height: calc(22 / 14);
    margin-bottom: 0;
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
    }

    &:not(:last-child) {
      margin-bottom: ${rem(8)};
      padding-bottom: ${rem(8)};
      border-bottom: 1px solid #d0d0d0;
    }
  }

  .c-box-table3__list-head {
    color: ${winterColor.main};
  }

  .c-box-table3__list-text {
  }

  .c-box-table3__a {
  }

  .c-box-table3__b {
  }

  .c-example {
    margin-top: ${rem(20)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(38)};
    }
  }

  .c-example__box {
    margin-top: ${rem(2)};
    border-radius: ${rem(10)};
    padding: ${rem(10)} ${rem(20)};
    border: 1px solid #d0d0d0;
    display: flex;
    align-items: flex-start;
    gap: ${rem(8)};

    @media ${breakpoints.lg} {
      gap: ${rem(10)};
      margin-top: ${rem(4)};
      border-width: 1px;
      border-radius: ${rem(10)};
      padding: ${rem(19)} ${rem(10)} ${rem(19)} ${rem(20)};
    }
  }

  .c-example__head {
    font-size: ${rem(16)};
    letter-spacing: 0.3em;
    font-weight: 700;
    line-height: 1.6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${winterColor.main};
    font-family: ${fontFamily.zenKaku};
    margin-bottom: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(22)};
    }
  }

  .c-example__sub-head-image {
    flex-shrink: 0;
    width: ${rem(25)};

    @media ${breakpoints.lg} {
      width: ${rem(30)};
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .c-example__body {
    padding-top: ${rem(4)};
  }

  .c-example__sub-head-text {
    font-size: ${rem(13)};
    letter-spacing: 0.1em;
    margin: 0;
    color: ${winterColor.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      text-align: left;
    }
  }

  .c-example__content {
    margin: ${rem(5)} 0 0 ${rem(-28)};
    font-size: ${rem(14)};
    text-align: left;
    letter-spacing: 0.1em;
    line-height: calc(22 / 14);

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      padding-left: ${rem(26)};
    }

    .is-em {
      color: ${winterColor.main};
      font-weight: 700;
    }
  }

  .c-box-table4 {
    margin-top: ${rem(12)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(24)};
    }
  }

  .c-box-table4__time {
    font-size: ${rem(12)};
    letter-spacing: 0.1em;
    line-height: 1;
    text-align: center;
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
    }
  }

  .c-box-table4__lists {
    margin: ${rem(8)} auto 0;
    background: #fff;
    padding: ${rem(0)};
    list-style: none;
    width: fit-content;

    @media ${breakpoints.lg} {
      margin-top: ${rem(8)};
    }
  }

  .c-box-table4__list {
    font-size: ${rem(14)};
    white-space: nowrap;
    line-height: 2.14;
    text-align: center;
    margin-bottom: 0;
    letter-spacing: 0.1em;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      line-height: 2.5;
      width: ${rem(340)};
      margin-left: auto;
      margin-right: auto;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d0d0d0;
    }
  }

  .c-box-table4__a {
  }

  .c-box-table4__b {
  }

  .c-box-table4__list-head {
    color: ${winterColor.main};
  }

  .c-box-table5 {
  }

  .c-box-table5__lists {
    margin: ${rem(6)} auto 0;
    background: #fff;
    padding: ${rem(0)};
    list-style: none;
    width: ${rem(200)};
    max-width: 100%;

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
      width: ${rem(340)};
    }
  }

  .c-box-table5__list {
    font-size: ${rem(14)};
    white-space: nowrap;
    line-height: 2.1;
    padding-left: ${rem(32)};
    margin-bottom: 0;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      padding-left: ${rem(90)};
      line-height: 2.5;
      letter-spacing: 0.1em;
      margin-left: auto;
      margin-right: auto;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d0d0d0;
    }
  }

  .c-box-table5__list-head {
    color: ${winterColor.main};
    width: ${rem(50)};
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${breakpoints.lg} {
      width: ${rem(60)};
    }
  }

  .c-box-table5__list-free {
    font-weight: 700;
    color: ${winterColor.main};

    &::before {
      content: '｜';
      color: ${winterColor.main};
    }
  }

  .c-box-table5__list-text {
    font-weight: 700;

    &::before {
      content: '｜';
      color: ${winterColor.main};
    }
  }

  .c-box-table5__a {
  }

  .c-box-table5__b {
  }

  .c-box-table5__c {
  }

  .c-box-table5__attentions {
    margin: ${rem(3)} auto 0;
    text-align: left;
    font-size: ${rem(20 / 2)};
    list-style: none;
    width: fit-content;

    @media ${breakpoints.lg} {
      font-size: ${rem(12)};
      width: ${rem(340)};
      margin-left: auto;
      margin-right: auto;
    }
  }

  .e-box-table5-attention {
    display: flex;
    margin: 0;
    line-height: calc(16 / 10);

    &::before {
      content: '※';
    }
  }

  .c-box-table6 {
  }

  .c-box-table6__target {
    margin: ${rem(10)} 0 0;
    font-size: ${rem(12)};
    letter-spacing: 0.1em;
    line-height: 1;
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(25)};
      font-size: ${rem(16)};
    }
  }

  .c-box-table6__lead {
    margin: ${rem(10)} 0 0;
    font-size: ${rem(14)};
    line-height: calc(23 / 14);

    @media ${breakpoints.lg} {
      margin-top: ${rem(15)};
      font-size: ${rem(16)};
    }
  }

  .c-box-table6__lists {
    margin: ${rem(16)} auto 0;
    background: #fff;
    padding: ${rem(0)};
    list-style: none;
    width: ${rem(240)};
    max-width: 100%;

    @media ${breakpoints.lg} {
      margin-top: ${rem(16)};
      width: ${rem(340)};
    }
  }

  .c-box-table6__list {
    font-size: ${rem(14)};
    white-space: nowrap;
    line-height: 2.14;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      line-height: 2.5;
      letter-spacing: 0.1em;
      padding-left: ${rem(32)};
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d0d0d0;
    }
  }

  .c-box-table6__list-head {
    color: ${winterColor.main};
  }

  .c-box-table6__list-text {
  }

  .c-box-table6__list-price {
    font-weight: 700;
  }

  .c-box-table6__a {
  }

  .c-box-table6__b {
  }

  .c-box-table6__attentions {
    margin: ${rem(3)} 0 0;
    text-align: left;
    font-size: ${rem(20 / 2)};
    list-style: none;

    @media ${breakpoints.lg} {
      font-size: ${rem(12)};
      margin-top: ${rem(3)};
      margin-left: auto;
      margin-right: auto;
      width: ${rem(340)};
    }
  }

  .e-box-table6-attention {
    display: flex;
    margin: 0;
    line-height: calc(16 / 10);

    &::before {
      content: '※';
    }
  }

  .c-deadline {
    margin-top: ${rem(40)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(58)};
    }
  }

  .c-deadline__head {
    font-size: ${rem(16)};
    letter-spacing: 0.1em;
    font-weight: 500;
    line-height: 1.6;
    width: ${rem(240)};
    max-width: 100%;
    padding-bottom: ${rem(5)};
    text-align: center;
    border-bottom: 1px solid #d0d0d0;
    margin: 0 auto;

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
      width: ${rem(340)};
    }
  }

  .c-deadline__date {
    margin: ${rem(5)} 0 0;
    font-weight: 500;
    font-size: ${rem(16)};
    letter-spacing: 0.1em;
    text-align: center;
    color: ${winterColor.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
      margin-top: ${rem(3)};
    }
  }

  .c-deadline__attentions {
    margin: ${rem(10)} 0 0;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-size: ${rem(10)};
    line-height: 1.6;
    list-style: none;
    list-style: none;

    @media ${breakpoints.lg} {
      margin-top: ${rem(8)};
      font-size: ${rem(12)};
      width: ${rem(340)};
    }
  }

  .c-deadline__attention {
    margin: 0;
    line-height: calc(16 / 10);
  }
`

export const OverviewSection: (props: OverviewSectionProps) => JSX.Element = StyledComponent

export default OverviewSection
