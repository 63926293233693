import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, winterColor, fixedHeaderHeight } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'
import Scroll from './atom/scroll'
import pointImage1 from 'assets/images/winter/mv/mv-point1.svg'
import pointImage2 from 'assets/images/winter/mv/mv-point2.svg'
import mainImageSP from 'assets/images/winter/mv/mv-sp.png'
import mainImagePC from 'assets/images/winter/mv/mv-pc.png'
import catchImageSP from 'assets/images/winter/mv/mv-catch-sp.png'
import catchImagePC from 'assets/images/winter/mv/mv-catch-pc.svg'

export interface MainVisualSectionProps {
  readonly noProps?: string
}

type MainVisualSectionPropsClassName = MainVisualSectionProps & {
  readonly className: string
}

// 冬期講習文字:0.5
// キャッチ文字：1.5
// 雪（画像）:1.7
// 実績:3
const logoDurationTime = 3 //冬期講習の全部の文字が表示されるまで時間
const logoDelayTime = 0.5 // 冬期講習の最初の文字が表示されるまでの時間

const leadDurationTime = 1.5 // キャッチ文字が表示されるまで時間
const leadDelayTime = logoDurationTime * (3 / 4) + logoDelayTime // キャッチ文字が表示されるまでの時間

const catchDurationTime = 1.5 // 無料ボックス文字が表示されるまで時間
const catchDurationTimePC = 3 // 無料ボックス文字が表示されるまで時間
const catchDelayTime = (logoDurationTime + logoDelayTime) / 2 // 無料ボックス文字が表示されるまでの時間

const catchBottomDurationTime = 0.2 // 無料ボックス下の文字が表示されるまで時間
const catchBottomDelayTime = catchDelayTime // 無料ボックス下の文字が表示されるまでの時間

const outerDropDurationTime = 4 // 雪がfadeInする時の時間
const outerRandomDelayRange = 3 // 全部の外側の雪のランダムで表示されるまでのレンジ
const outerDelayTime = 1 // 雪がfadeInするまでの時間

const pointDurationTIme = 1.5 // 実績がfadeInする時の時間
const pointDelayTIme = leadDelayTime + leadDurationTime / 3 // 実績がfadeInする時の時間

// 使っていない
const timeDurationTIme = 1.5 // 実施期間がfadeInする時の時間
const timeDelayTIme = 3 // 実施期間がfadeInする時の時間

// PC
// 1 | 20 | 21 | 9  1
// 2 | 10 | 22 1.5
// 3 | 18 | 12 2
// 4 | 13 2.5
// 5 | 6 | 7| 14 | 15 3
// 11 | 17 | 16 | 19 3.5
const snowStepPC1 = 1
const snowStepPC2 = 1.5
const snowStepPC3 = 2
const snowStepPC4 = 2.5
const snowStepPC5 = 3
const snowStepPC6 = 3.5

// スマホ
// 1 | 9  1
// 2 | 10 1.375
// 3 | 11 1.75
//   | 12 2.125
// 4 | 13 2.5
// 5 | 14 2.875
// 6 |
// 7 | 15 3.25
// 8 | 16 3.625
const snowStep1 = 1
const snowStep2 = 1.375
const snowStep3 = 1.75
const snowStep4 = 2.125
const snowStep5 = 2.5
const snowStep6 = 2.875
const snowStep7 = 3.25
const snowStep8 = 3.625

const snow1DelayTime = snowStep1
const snow1DelayTimePC = snowStepPC1
const snow2DelayTime = snowStep2
const snow2DelayTimePC = snowStepPC2
const snow3DelayTime = snowStep3
const snow3DelayTimePC = snowStepPC3
const snow4DelayTime = snowStep5
const snow4DelayTimePC = snowStepPC4
const snow5DelayTime = snowStep6
const snow5DelayTimePC = snowStepPC5
const snow6DelayTime = snowStep7
const snow6DelayTimePC = snowStepPC5
const snow7DelayTime = snowStep7
const snow7DelayTimePC = snowStepPC5
const snow8DelayTime = snowStep1
const snow8DelayTimePC = snowStepPC1
const snow9DelayTime = snowStep1
const snow9DelayTimePC = snowStepPC1
const snow10DelayTime = snowStep2
const snow10DelayTimePC = snowStepPC2
const snow11DelayTime = snowStep3
const snow11DelayTimePC = snowStepPC6
const snow12DelayTime = snowStep4
const snow12DelayTimePC = snowStepPC3
const snow13DelayTime = snowStep5
const snow13DelayTimePC = snowStepPC4
const snow14DelayTime = snowStep6
const snow14DelayTimePC = snowStepPC5
const snow15DelayTime = snowStep7
const snow15DelayTimePC = snowStep7

function Component({ className }: MainVisualSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="c-main">
        <div className="c-logo">
          <div className="c-logo__year">
            <svg height="34.1" viewBox="0 0 244.8 34.1" width="244.8" xmlns="http://www.w3.org/2000/svg">
              <g fill="#fff" transform="translate(-557.1 -328.1)">
                <path d="m635 362 .3-9h2.1l-.4 6 3.7-6h1.9l-.4 6 3.7-6h2.1l-5.8 9h-1.9l.4-6.1-3.8 6.1z" />
                <path d="m653.8 362 3.1-9h2.2l-3.1 9zm4.7-10.5a.95.95 0 0 1 -1-1.3 1.6 1.6 0 0 1 .7-.9 1.666 1.666 0 0 1 1.1-.4.95.95 0 0 1 1 1.3 1.6 1.6 0 0 1 -.7.9 1.666 1.666 0 0 1 -1.1.4z" />
                <path d="m665.6 362 3.1-9h2l-.2 1.1a4.826 4.826 0 0 1 1.4-.9 3.287 3.287 0 0 1 1.7-.4 2.336 2.336 0 0 1 2.1.9 2.914 2.914 0 0 1 0 2.6l-1.9 5.7h-2.2l1.8-5.2a2.274 2.274 0 0 0 .1-1.6c-.2-.4-.5-.5-1-.5a2.452 2.452 0 0 0 -1.6.6 3.807 3.807 0 0 0 -1.1 1.6l-1.7 5.1z" />
                <path d="m686.9 362.2a3.439 3.439 0 0 1 -2-.5c-.4-.3-.4-1-.1-1.9l1.7-5h-1.8l.6-1.8h1.8l1.1-3.2h2.2l-1.1 3.2h2.6l-.6 1.8h-2.6l-1.5 4.4a1.352 1.352 0 0 0 0 .9c.2.2.5.2.9.2a2.485 2.485 0 0 0 .8-.1 6.375 6.375 0 0 0 .7-.2l-.7 1.9a2.958 2.958 0 0 1 -1 .2 3.4 3.4 0 0 1 -1 .1z" />
                <path d="m701.6 362.2a3.51 3.51 0 0 1 -3-1.2c-.6-.8-.6-2-.1-3.5a6.772 6.772 0 0 1 1.4-2.5 5.962 5.962 0 0 1 2.2-1.6 5.734 5.734 0 0 1 2.5-.6 2.814 2.814 0 0 1 2 .6 2.588 2.588 0 0 1 .9 1.7 5.28 5.28 0 0 1 -.3 2.4l-.2.5h-6.5a1.989 1.989 0 0 0 .1 1.9 1.755 1.755 0 0 0 1.5.6 3.287 3.287 0 0 0 1.7-.4 5.8 5.8 0 0 0 1.5-1.3l1.3 1.1a8 8 0 0 1 -2.4 1.9 10.134 10.134 0 0 1 -2.6.4zm-.3-6h4.2a2.1 2.1 0 0 0 -.2-1.4 1.364 1.364 0 0 0 -1.2-.5 2.934 2.934 0 0 0 -1.6.4c-.4.5-.8.9-1.2 1.5z" />
                <path d="m714.8 362 3.1-9h2l-.3 1.3a5.827 5.827 0 0 1 1.5-1.1 3.922 3.922 0 0 1 1.8-.4h.6c.2 0 .3.1.4.1l-.7 2c-.2 0-.3-.1-.6-.1h-.8a3.534 3.534 0 0 0 -1.9.6 3.708 3.708 0 0 0 -1.3 1.7l-1.6 4.9z" />
                <path d="m594.9 359.5a1.5 1.5 0 0 1 -1.4-1 1.528 1.528 0 0 1 .9-1.9l85.4-28.5.5.2 85.3 28.3a1.487 1.487 0 1 1 -1 2.8l-84.8-28.2-84.5 28.2c-.1 0-.3.1-.4.1z" />
                <path d="m584.4 361.6h-25.8a1.5 1.5 0 0 1 0-3h25.8a1.5 1.5 0 0 1 0 3z" />
                <path d="m568.5 347.4h-3.4a1.3 1.3 0 0 1 0-2.6h3.4a1.3 1.3 0 0 1 0 2.6z" />
                <path d="m577.9 347.4h-3.4a1.3 1.3 0 0 1 0-2.6h3.4a1.324 1.324 0 0 1 1.3 1.3 1.206 1.206 0 0 1 -1.3 1.3z" />
                <path d="m571.5 344.4a1.324 1.324 0 0 1 -1.3-1.3v-3.4a1.3 1.3 0 0 1 2.6 0v3.4a1.324 1.324 0 0 1 -1.3 1.3z" />
                <path d="m571.5 353.8a1.324 1.324 0 0 1 -1.3-1.3v-3.4a1.3 1.3 0 0 1 2.6 0v3.4a1.324 1.324 0 0 1 -1.3 1.3z" />
                <path d="m800.4 361.6h-25.8a1.5 1.5 0 0 1 0-3h25.8a1.5 1.5 0 0 1 0 3z" />
                <path d="m784.3 347.4h-3.4a1.3 1.3 0 0 1 0-2.6h3.4a1.324 1.324 0 0 1 1.3 1.3 1.206 1.206 0 0 1 -1.3 1.3z" />
                <path d="m793.8 347.4h-3.4a1.3 1.3 0 0 1 0-2.6h3.4a1.3 1.3 0 0 1 0 2.6z" />
                <path d="m787.3 344.4a1.324 1.324 0 0 1 -1.3-1.3v-3.4a1.3 1.3 0 0 1 2.6 0v3.4a1.206 1.206 0 0 1 -1.3 1.3z" />
                <path d="m787.3 353.8a1.324 1.324 0 0 1 -1.3-1.3v-3.4a1.3 1.3 0 0 1 2.6 0v3.4a1.206 1.206 0 0 1 -1.3 1.3z" />
              </g>
            </svg>
          </div>
          <h1 className="c-logo__text">
            <span className="c-logo-text1">
              <StaticImage
                className="c-logo-text1__image"
                src="../../../assets/images/winter/mv/mv-text1.png"
                alt="冬"
                placeholder="none"
                loading="eager"
              />
            </span>
            <span className="c-logo-text2">
              <StaticImage
                className="c-logo-text2__image"
                src="../../../assets/images/winter/mv/mv-text2.png"
                alt="期"
                placeholder="none"
                loading="eager"
              />
            </span>
            <span className="c-logo-text3">
              <StaticImage
                className="c-logo-text3__image"
                src="../../../assets/images/winter/mv/mv-text3.png"
                alt="講"
                placeholder="none"
                loading="eager"
              />
            </span>
            <span className="c-logo-text4">
              <StaticImage
                className="c-logo-text4__image"
                src="../../../assets/images/winter/mv/mv-text4.png"
                alt="習"
                placeholder="none"
                loading="eager"
              />
            </span>
          </h1>
          <p className="c-logo__lead">オンライン</p>
        </div>
        <div className="c-main-bg">
          <picture>
            <source srcSet={mainImagePC} media="(min-width: 768px)" width="1440" height="1072" />
            <img className="c-main-bg__image" src={mainImageSP} alt="" width="1065" height="1065" />
          </picture>
          <div className="c-catch-box">
            <div className="c-catch-box__wrap">
              <div className="c-catch-box__in">
                <picture className="c-catch-box__image">
                  <source srcSet={catchImagePC} media="(min-width: 768px)" width="260" height="182" />
                  <img src={catchImageSP} alt="4日間の無料体験を受付中" width="510" height="375" />
                </picture>
              </div>
              <p className="c-catch-box__lead">※中・高コース対象</p>
            </div>
          </div>
        </div>
        <div className="c-drop1">
          <div className="c-drop1__fadeIn">
            <StaticImage className="c-drop1__image" src="../../../assets/images/winter/mv/snow/snow2.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
        <div className="c-drop2">
          <div className="c-drop2__fadeIn">
            <StaticImage className="c-drop2__image" src="../../../assets/images/winter/mv/snow/snow7.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
        <div className="c-drop3">
          <div className="c-drop3__fadeIn">
            <StaticImage className="c-drop3__image" src="../../../assets/images/winter/mv/snow/snow6.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
        <div className="c-drop4">
          <div className="c-drop4__fadeIn">
            <StaticImage className="c-drop4__image" src="../../../assets/images/winter/mv/snow/snow9.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
        <div className="c-drop5">
          <div className="c-drop5__fadeIn">
            <StaticImage
              className="c-drop5__image"
              src="../../../assets/images/winter/mv/snow/snow11.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="c-drop6">
          <div className="c-drop6__fadeIn">
            <StaticImage className="c-drop6__image" src="../../../assets/images/winter/mv/snow/snow2.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
        <div className="c-drop7">
          <div className="c-drop7__fadeIn">
            <StaticImage className="c-drop7__image" src="../../../assets/images/winter/mv/snow/snow4.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
        <div className="c-drop8">
          <div className="c-drop8__fadeIn">
            <StaticImage className="c-drop8__image" src="../../../assets/images/winter/mv/snow/snow6.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
        <div className="c-drop9">
          <div className="c-drop9__fadeIn">
            <StaticImage
              className="c-drop9__image"
              src="../../../assets/images/winter/mv/snow/snow10.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="c-drop10">
          <div className="c-drop10__fadeIn">
            <StaticImage
              className="c-drop10__image"
              src="../../../assets/images/winter/mv/snow/snow3.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="c-drop11">
          <div className="c-drop11__fadeIn">
            <StaticImage
              className="c-drop11__image"
              src="../../../assets/images/winter/mv/snow/snow9.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="c-drop12">
          <div className="c-drop12__fadeIn">
            <StaticImage
              className="c-drop12__image"
              src="../../../assets/images/winter/mv/snow/snow10.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="c-drop13">
          <div className="c-drop13__fadeIn">
            <StaticImage
              className="c-drop13__image"
              src="../../../assets/images/winter/mv/snow/snow1.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="c-drop14">
          <div className="c-drop14__fadeIn">
            <StaticImage
              className="c-drop14__image"
              src="../../../assets/images/winter/mv/snow/snow11.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="c-drop15">
          <div className="c-drop15__fadeIn">
            <StaticImage
              className="c-drop15__image"
              src="../../../assets/images/winter/mv/snow/snow5.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>

        <div className="c-points">
          <div className="c-points__point1">
            {/* TODO 画像の場所を共通化する */}
            <img src={pointImage1} alt="" width="99.6" height="39" />
          </div>
          <div className="c-points__point2">
            <img src={pointImage2} alt="" width="99.6" height="39" />
          </div>
          <div className="c-points__point3">
            <StaticImage src="../../../assets/images/winter/mv/mv-point3.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
      </div>

      <div className="c-time-area">
        <p className="c-time-area__between">実施期間｜12/11-1/13</p>
        <div className="c-time-area__content">
          <ul className="c-time-area__lists">
            <li className="c-time-area__list">小学生</li>
            <li className="c-time-area__list">中学生</li>
            <li className="c-time-area__list">高校生</li>
          </ul>
        </div>
      </div>

      <div className="c-scroll">
        <Scroll targetID="about" />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<MainVisualSectionPropsClassName>`
  @keyframes summerScrollMove {
    0% {
      opacity: 0;
      transform: translateY(${rem(0)});
    }
    50% {
      opacity: 1;
    }
    80% {
      opacity: 0.9;
    }
    100% {
      opacity: 0;
      transform: translateY(${rem(100 / 2)});
    }
  }

  @keyframes summerScrollMovePC {
    0% {
      opacity: 0;
      transform: translateY(${rem(0)});
    }
    50% {
      opacity: 1;
    }
    80% {
      opacity: 0.9;
    }
    100% {
      opacity: 0;
      transform: translateY(${rem(120)});
    }
  }

  @keyframes fadeInMainVisualSection {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInDownMainVisualSection {
    0% {
      opacity: 0;
      transform: translateY(${rem(-48)});
    }

    100% {
      opacity: 1;
      transform: translateY(24);
    }
  }

  @keyframes fadeInDownMainVisualSectionPC {
    0% {
      opacity: 0;
      transform: translateY(${rem(-48)});
    }

    100% {
      opacity: 1;
      transform: translateY(48);
    }
  }

  @keyframes fadeInLeftMainVisualSection {
    0% {
      opacity: 0;
      transform: translateX(${rem(-48)});
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes DropMainVisualSection {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(${rem(10)});
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideTextX100 {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes Logo1MainVisualSection {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes Logo2MainVisualSection {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    55% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes Logo3MainVisualSection {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes Logo4MainVisualSection {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  position: relative;
  // ヘッダーメニューの高さ + 余白
  padding-top: calc(${fixedHeaderHeight.sm});
  background: ${winterColor.main};

  @media ${breakpoints.lg} {
    width: 100%;
    // header上部と下部の余白を足す
    padding-top: ${rem(94)};
    padding-bottom: ${rem(20)};
  }

  .c-logo {
    position: relative;
    z-index: 2;

    @media ${breakpoints.lg} {
      position: absolute;
      top: ${rem(34)};
      left: ${rem(10)};
    }
  }

  .c-logo__year {
    opacity: 0;
    animation: fadeInMainVisualSection ${catchDurationTime}s ease ${catchDelayTime}s forwards;

    svg {
      width: ${rem(244.8)};
      height: auto;
      /* height: ${rem(34.1)}; */

      @media ${breakpoints.lg} {
        width: ${rem(454)};
      }
    }
  }

  .c-logo__text {
    display: flex;
    justify-content: center;
    margin-top: ${rem(4)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
    }
  }

  .c-logo__lead {
    margin: ${rem(10)} 0 0 0;
    letter-spacing: 0.25em;
    line-height: 1;
    font-weight: 700;
    color: #fff;
    font-size: ${rem(29)};
    text-align: center;
    opacity: 0;
    animation: fadeInMainVisualSection ${leadDurationTime}s ease ${leadDelayTime}s forwards;

    @media ${breakpoints.lg} {
      margin-top: ${rem(8)};
      font-size: ${rem(40)};
    }
  }

  .c-logo-text1,
  .c-logo-text2,
  .c-logo-text3,
  .c-logo-text4 {
    opacity: 0;
  }

  .c-logo-text1 {
    width: ${rem(57.23)};
    animation: Logo1MainVisualSection ${logoDurationTime}s ease ${logoDelayTime}s forwards;

    @media ${breakpoints.lg} {
      width: ${rem(108)};
    }
  }

  .c-logo-text2 {
    width: ${rem(69.93)};
    margin-left: ${rem(8.3)};
    animation: Logo2MainVisualSection ${logoDurationTime}s ease ${logoDelayTime}s forwards;

    @media ${breakpoints.lg} {
      width: ${rem(136)};
    }
  }

  .c-logo-text3 {
    width: ${rem(68.34)};
    margin-left: ${rem(6.4)};
    animation: Logo3MainVisualSection ${logoDurationTime}s ease ${logoDelayTime}s forwards;

    @media ${breakpoints.lg} {
      width: ${rem(132)};
    }
  }

  .c-logo-text4 {
    width: ${rem(63.03)};
    margin-left: ${rem(8.6)};
    animation: Logo4MainVisualSection ${logoDurationTime}s ease ${logoDelayTime}s forwards;

    @media ${breakpoints.lg} {
      width: ${rem(122)};
    }
  }

  .c-logo-text1__image {
  }

  .c-main-bg {
    z-index: 1;
    margin-top: ${rem(-32)};
    margin-inline: auto;
    padding-inline: ${rem(10)};
    /* width: ${rem(355)}; */
    position: relative;

    @media ${breakpoints.lg} {
      text-align: right;
      padding-inline: ${rem(0)};
      width: ${rem(1000)};
      margin-top: ${rem(0)};
    }
  }

  .c-main-bg__image {
    margin: 0;
    display: block;

    @media ${breakpoints.lg} {
      width: ${rem(720)};
      margin-left: auto;
    }
  }

  .c-catch-box {
    position: absolute;
    top: ${rem(74)};
    left: ${rem(0)};

    @media ${breakpoints.lg} {
      width: ${rem(370)};
      top: ${rem(304)};
      left: ${rem(0)};
    }

    img {
      image-rendering: -webkit-optimize-contrast;
    }
  }

  .c-catch-box__wrap {
    overflow: hidden;
    width: ${rem(170)};

    @media ${breakpoints.lg} {
      width: ${rem(370)};
    }
  }

  .c-catch-box__in {
    width: 0;
    overflow: hidden;
    animation: slideTextX100 ${catchDurationTime}s ease ${catchDelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-duration: ${catchDurationTimePC}s;
    }

    img {
    }
  }

  .c-catch-box__image {
    display: block;
    width: ${rem(170)};
    height: ${rem(125)};

    @media ${breakpoints.lg} {
      width: ${rem(260)};
      height: ${rem(182)};
    }

    img {
      width: 100%;
      margin: 0;
      display: block;
    }
  }

  .c-catch-box__lead {
    margin: 0;
    text-align: left;
    font-size: ${rem(10)};
    padding-left: ${rem(25)};
    opacity: 0;
    /* opacity: 1; */
    animation: fadeInMainVisualSection ${catchBottomDurationTime}s ease ${catchBottomDelayTime}s forwards;

    @media ${breakpoints.lg} {
      font-size: ${rem(10)};
      padding-left: ${rem(0)};
      color: #fff;
      margin-top: ${rem(10)};
    }
  }

  .c-points {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    bottom: ${rem(0)};
    inset-inline: 0;
    margin: auto;
    gap: ${rem(5)};
    opacity: 0;
    z-index: 2;
    animation: fadeInMainVisualSection ${pointDurationTIme}s ease ${pointDelayTIme}s forwards;

    @media ${breakpoints.lg} {
      inset-inline: auto ${rem(10)};
      margin: 0;
      gap: ${rem(10)};
      bottom: ${rem(10)};
    }

    img {
      image-rendering: -webkit-optimize-contrast;
      width: 100%;
      vertical-align: bottom;
    }
  }

  .c-points__point1 {
    width: ${rem(100)};

    @media ${breakpoints.lg} {
      width: ${rem(128)};
    }

    img {
      margin: 0;
      display: block;
    }
  }

  .c-points__point2 {
    width: ${rem(100)};

    @media ${breakpoints.lg} {
      width: ${rem(128)};
    }

    img {
      margin: 0;
      display: block;
    }
  }

  .c-points__point3 {
    width: ${rem(79)};

    @media ${breakpoints.lg} {
      width: ${rem(99)};
    }

    img {
      display: block;
    }
  }

  .c-time-area {
    position: relative;
    background-color: #fff;
    font-size: ${rem(24 / 2)};
    font-weight: 500;

    @media ${breakpoints.lg} {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% + ${rem(25)});
      z-index: 3;
    }
  }

  .c-time-area__between {
    padding-block: ${rem(9)};
    margin: 0;
    text-align: center;
    font-size: ${rem(15)};
    letter-spacing: 0.25em;
    font-weight: 700;
    background: ${winterColor.main};
    color: #fff;

    @media ${breakpoints.lg} {
      font-size: ${rem(21)};
      padding-block: ${rem(0)};
      color: ${winterColor.main};
      background: transparent;

      font-size: ${rem(20)};
      letter-spacing: 0.3em;
      line-height: 1;
      color: #0028be;
    }
  }

  .c-time-area__content {
    margin-top: ${rem(5)};
    /* opacity: 0; */
    /* animation: fadeInDownMainVisualSection ${timeDurationTIme}s ease ${timeDelayTIme}s forwards; */

    @media ${breakpoints.lg} {
      margin-top: ${rem(0)};
      margin-left: ${rem(26)};
    }
  }

  .c-time-area__lists {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: ${rem(30 / 2)};
    font-weight: 500;

    @media ${breakpoints.lg} {
      color: ${winterColor.main};
      font-size: ${rem(20)};
      line-height: 1;
    }
  }

  .c-time-area__list {
    display: flex;
    color: #0028be;
    align-items: center;
    font-weight: 700;
    margin: 0;
    letter-spacing: 0.5em;

    @media ${breakpoints.lg} {
      letter-spacing: 0.3em;
    }

    &:nth-child(n + 2) {
      &::before {
        content: '/';
        display: block;
      }
    }
  }

  .c-scroll {
    display: none;
    position: absolute;
    bottom: ${rem(154)};
    left: ${rem(60)};
    z-index: 2;

    @media (min-width: 1080px) {
      display: block;
      left: ${rem(20)};
      bottom: ${rem(80)};
    }

    @media (min-width: 1200px) {
      display: block;
      left: ${rem(80)};
      bottom: ${rem(80)};
    }
  }

  .c-drop1,
  .c-drop2,
  .c-drop3,
  .c-drop4,
  .c-drop5,
  .c-drop6,
  .c-drop7,
  .c-drop8,
  .c-drop9,
  .c-drop10,
  .c-drop11,
  .c-drop12,
  .c-drop13,
  .c-drop14,
  .c-drop15 {
    position: absolute;
    z-index: 3;
    line-height: 0;
  }

  .c-drop1__fadeIn,
  .c-drop2__fadeIn,
  .c-drop3__fadeIn,
  .c-drop4__fadeIn,
  .c-drop5__fadeIn,
  .c-drop6__fadeIn,
  .c-drop7__fadeIn,
  .c-drop8__fadeIn,
  .c-drop9__fadeIn,
  .c-drop10__fadeIn,
  .c-drop11__fadeIn,
  .c-drop12__fadeIn,
  .c-drop13__fadeIn,
  .c-drop14__fadeIn,
  .c-drop15__fadeIn {
    opacity: 0;
    /* opacity: 1; */
  }

  .c-drop1 {
    top: ${rem(18)};
    right: calc(50% + ${rem(55)});
    width: ${rem(19)};

    @media ${breakpoints.lg} {
      width: ${rem(33.74)};
      top: ${rem(18)};
      right: calc(50% + ${rem(342)});
    }
  }

  .c-drop1__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow1DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow1DelayTimePC}s;
    }
  }

  .c-drop1__image {
    animation: DropMainVisualSection 5.1s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 5.1s;
    }
  }

  .c-drop2 {
    top: ${rem(57)};
    right: calc(50% + ${rem(144)});
    width: ${rem(10)};

    @media ${breakpoints.lg} {
      width: ${rem(14.47)};
      top: ${rem(69)};
      right: calc(50% + ${rem(472)});
      left: auto;
    }
  }

  .c-drop2__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow2DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow2DelayTimePC}s;
    }
  }

  .c-drop2__image {
    animation: DropMainVisualSection 6.2s ease-in-out infinite;
  }

  .c-drop3 {
    top: ${rem(154)};
    right: calc(50% + ${rem(154)});
    width: ${rem(14)};

    @media ${breakpoints.lg} {
      width: ${rem(20.55)};
      top: ${rem(290)};
      right: calc(50% + ${rem(378)});
    }
  }

  .c-drop3__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow3DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow3DelayTimePC}s;
    }
  }

  .c-drop3__image {
    animation: DropMainVisualSection 4.6s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 4.6s;
    }
  }

  .c-drop4 {
    top: ${rem(200)};
    right: calc(50% + ${rem(126)});
    width: ${rem(19)};

    @media ${breakpoints.lg} {
      width: ${rem(28.29)};
      top: ${rem(364)};
      right: calc(50% + ${rem(116)});
    }
  }

  .c-drop4__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow4DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow4DelayTimePC}s;
    }
  }

  .c-drop4__image {
    animation: DropMainVisualSection 5s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 5s;
    }
  }

  .c-drop5 {
    bottom: ${rem(126)};
    right: calc(50% + ${rem(136)});
    width: ${rem(9)};

    @media ${breakpoints.lg} {
      width: ${rem(16)};
      bottom: ${rem(92)};
      right: calc(50% + ${rem(172)});
    }
  }

  .c-drop5__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow5DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow5DelayTimePC}s;
    }
  }

  .c-drop5__image {
    animation: DropMainVisualSection 5s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 5s;
    }
  }

  .c-drop6 {
    bottom: ${rem(82)};
    right: calc(50% + ${rem(145)});
    width: ${rem(19)};

    @media ${breakpoints.lg} {
      width: ${rem(28.47)};
      bottom: ${rem(24)};
      right: calc(50% + ${rem(288)});
    }
  }

  .c-drop6__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow6DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow6DelayTimePC}s;
    }
  }

  .c-drop6__image {
    animation: DropMainVisualSection 5.2s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 5.2s;
    }
  }

  .c-drop7 {
    bottom: ${rem(-14)};
    right: calc(50% + ${rem(145)});
    width: ${rem(9)};

    @media ${breakpoints.lg} {
      width: ${rem(13.43)};
      bottom: ${rem(0)};
      right: calc(50% + ${rem(414)});
    }
  }

  .c-drop7__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow7DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow7DelayTimePC}s;
    }
  }

  .c-drop7__image {
    animation: DropMainVisualSection 4.8s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 4.8s;
    }
  }

  .c-drop8 {
    top: ${rem(16)};
    left: calc(50% + ${rem(82)});
    width: ${rem(14)};

    @media ${breakpoints.lg} {
      width: ${rem(20.55)};
      top: ${rem(38)};
      left: calc(50% + ${rem(12)});
    }
  }

  .c-drop8__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow8DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow8DelayTimePC}s;
    }
  }

  .c-drop8__image {
    animation: DropMainVisualSection 4.8s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 4.8s;
    }
  }

  .c-drop9 {
    top: ${rem(48)};
    left: calc(50% + ${rem(154)});
    width: ${rem(11)};

    @media ${breakpoints.lg} {
      display: block;
      width: ${rem(15.95)};
      top: ${rem(28)};
      left: calc(50% + ${rem(312)});
    }
  }

  .c-drop9__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow9DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow9DelayTimePC}s;
    }
  }

  .c-drop9__image {
    animation: DropMainVisualSection 4.6s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 4.6s;
    }
  }

  .c-drop10 {
    top: ${rem(178)};
    left: calc(50% + ${rem(134)});
    width: ${rem(11)};

    @media ${breakpoints.lg} {
      display: block;
      width: ${rem(16.75)};
      top: ${rem(80)};
      left: calc(50% + ${rem(452)});
    }
  }

  .c-drop10__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow10DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow10DelayTimePC}s;
    }
  }

  .c-drop10__image {
    animation: DropMainVisualSection 5s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 5s;
    }
  }

  .c-drop11 {
    top: ${rem(228)};
    left: calc(50% + ${rem(155)});
    width: ${rem(19)};

    @media ${breakpoints.lg} {
      display: block;
      width: ${rem(28.29)};
      top: ${rem(125)};
      left: calc(50% + ${rem(374)});
    }
  }

  .c-drop11__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow11DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow11DelayTimePC}s;
    }
  }

  .c-drop11__image {
    animation: DropMainVisualSection 5.2s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 5.2s;
    }
  }

  .c-drop12 {
    top: ${rem(288)};
    left: calc(50% + ${rem(144)});
    width: ${rem(11)};

    @media ${breakpoints.lg} {
      display: block;
      width: ${rem(15.95)};
      top: ${rem(248)};
      left: calc(50% + ${rem(432)});
    }
  }

  .c-drop12__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow12DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow12DelayTimePC}s;
    }
  }

  .c-drop12__image {
    animation: DropMainVisualSection 5.6s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 5.6s;
    }
  }

  .c-drop13 {
    bottom: ${rem(90)};
    left: calc(50% + ${rem(126)});
    width: ${rem(20)};

    @media ${breakpoints.lg} {
      display: block;
      width: ${rem(31.25)};
      bottom: ${rem(182)};
      left: calc(50% + ${rem(354)});
    }
  }

  .c-drop13__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow13DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow13DelayTimePC}s;
    }
  }

  .c-drop13__image {
    animation: DropMainVisualSection 5s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 5s;
    }
  }

  .c-drop14 {
    bottom: ${rem(2)};
    left: calc(50% + ${rem(136)});
    width: ${rem(9)};

    @media ${breakpoints.lg} {
      display: block;
      width: ${rem(12.61)};
      bottom: ${rem(122)};
      left: calc(50% + ${rem(464)});
    }
  }

  .c-drop14__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow14DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow14DelayTimePC}s;
    }
  }

  .c-drop14__image {
    animation: DropMainVisualSection 5.6s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 5.6s;
    }
  }

  .c-drop15 {
    bottom: ${rem(-38)};
    left: calc(50% + ${rem(146)});
    width: ${rem(19)};

    @media ${breakpoints.lg} {
      display: block;
      width: ${rem(29.58)};
      bottom: ${rem(-76)};
      left: calc(50% + ${rem(366)});
    }
  }

  .c-drop15__fadeIn {
    animation: fadeInDownMainVisualSection ${outerDropDurationTime}s ease ${snow15DelayTime}s forwards;

    @media ${breakpoints.lg} {
      animation-delay: ${snow15DelayTimePC}s;
    }
  }

  .c-drop15__image {
    animation: DropMainVisualSection 5.2s ease-in-out infinite;

    @media ${breakpoints.lg} {
      animation-duration: 5.2s;
    }
  }

  .c-main {
    padding-top: ${rem(35)};
    padding-bottom: ${rem(15)};
    text-align: center;
    position: relative;
    /* width: ${rem(355)}; */
    max-width: 100%;
    margin-inline: auto;

    @media ${breakpoints.lg} {
      padding-top: ${rem(10)};
      padding-bottom: ${rem(0)};
      width: ${rem(1000)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const MainVisualSection: (props: MainVisualSectionProps) => JSX.Element = StyledComponent

export default MainVisualSection
