import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, rem, winterColor } from 'variables/_index'

export interface SecretBoyPrimaryProps {
  readonly noProps?: string
}

type SecretBoyPrimaryPropsClassName = SecretBoyPrimaryProps & {
  readonly className: string
}

function Component({ className }: SecretBoyPrimaryPropsClassName) {
  return (
    <div className={className}>
      <div className="e-snow1">
        <div className="e-snow1-fadeIn">
          <StaticImage src="../../../../assets/images/winter/mv/snow/snow2.png" className="e-snow1-image" alt="" placeholder="none" />
        </div>
      </div>
      <div className="e-snow2">
        <div className="e-snow2-fadeIn">
          <StaticImage src="../../../../assets/images/winter/mv/snow/snow10.png" alt="" className="e-snow2-image" placeholder="none" />
        </div>
      </div>
      <div className="e-snow3">
        <div className="e-snow3-fadeIn">
          <StaticImage src="../../../../assets/images/winter/mv/snow/snow5.png" alt="" className="e-snow3-image" placeholder="none" />
        </div>
      </div>
      <div className="e-snow4">
        <div className="e-snow4-fadeIn">
          <StaticImage src="../../../../assets/images/winter/mv/snow/snow7.png" alt="" className="e-snow4-image" placeholder="none" />
        </div>
      </div>
      <div className="e-snow5">
        <div className="e-snow5-fadeIn">
          <StaticImage src="../../../../assets/images/winter/mv/snow/snow6.png" alt="" className="e-snow5-image" placeholder="none" />
        </div>
      </div>
      <div className="e-main">
        <StaticImage src="../../../../assets/images/winter/course/primary/point-body.png" alt="" placeholder="none" />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<SecretBoyPrimaryPropsClassName>`
  position: relative;
  display: inline-block;

  @keyframes MoveSecretBoyPrimary {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(${rem(10)});
    }
    100% {
      transform: translateY(0);
    }
  }

  > .e-snow1,
  > .e-snow2,
  > .e-snow3,
  > .e-snow4,
  > .e-snow5 {
    position: absolute;
  }

  .e-snow1 {
    top: ${rem(58)};
    right: calc(50% + ${rem(79)});

    @media ${breakpoints.lg} {
      top: ${rem(76)};
      right: calc(50% + ${rem(138)});
    }
  }

  .e-snow1-fadeIn {
  }

  .e-snow1-image {
    width: ${rem(19)};
    display: block;
    animation: MoveSecretBoyPrimary 5.1s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(28.47)};
    }
  }

  .e-snow2 {
    top: ${rem(64)};
    right: calc(50% + ${rem(46)});

    @media ${breakpoints.lg} {
      top: ${rem(104)};
      right: calc(50% + ${rem(84)});
    }
  }

  .e-snow2-fadeIn {
  }

  .e-snow2-image {
    width: ${rem(11)};
    display: block;
    animation: MoveSecretBoyPrimary 5.1s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(15.95)};
    }
  }

  .e-snow3 {
    top: ${rem(34)};
    right: calc(50% + ${rem(14)});

    @media ${breakpoints.lg} {
      top: ${rem(54)};
      right: calc(50% + ${rem(30)});
    }
  }

  .e-snow3-fadeIn {
  }

  .e-snow3-image {
    width: ${rem(19)};
    display: block;
    animation: MoveSecretBoyPrimary 5.1s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(29.58)};
    }
  }

  .e-snow4 {
    top: ${rem(26)};
    left: calc(50% + ${rem(56)});

    @media ${breakpoints.lg} {
      top: ${rem(34)};
      left: calc(50% + ${rem(70)});
    }
  }

  .e-snow4-fadeIn {
  }

  .e-snow4-image {
    width: ${rem(10)};
    display: block;
    animation: MoveSecretBoyPrimary 5.1s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(14.47)};
    }
  }

  .e-snow5 {
    top: ${rem(44)};
    left: calc(50% + ${rem(84)});

    @media ${breakpoints.lg} {
      top: ${rem(64)};
      left: calc(50% + ${rem(138)});
    }
  }

  .e-snow5-fadeIn {
  }

  .e-snow5-image {
    width: ${rem(14)};
    display: block;
    animation: MoveSecretBoyPrimary 5.1s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(20.55)};
    }
  }

  > .e-main {
    @media ${breakpoints.lg} {
    }

    img {
      width: ${rem(165)};

      @media ${breakpoints.lg} {
        width: ${rem(254.55)};
      }
    }
  }
`

export const SecretBoyPrimary: (props: SecretBoyPrimaryProps) => JSX.Element = StyledComponent

export default SecretBoyPrimary
