import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, winterColor, fontFamily, breakpoints, innerSeasonWidth } from 'variables/_index'
import HeadingTab from '../atom/headingTab'
import OverviewSection from 'components/section/winter/overviewHighSection'
import CoursePrimaryCalc from './coursePrimaryCalc'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import SecretBoyPrimary from '../atom/secretBoyPrimary'
import classNames from 'classnames'
import textTwoPointImage from 'assets/images/winter/course/text-two-point.svg'
import textOneImage from 'assets/images/winter/course/text-one.svg'
import textTwoImage from 'assets/images/winter/course/text-two.svg'
import ContactGirl from '../atom/contactBodyPrimary'
import ContactSectionSimple from '../contactSectionSimplePrimary'

export interface CoursePrimaryProps {
  readonly noProps?: string
}

type CoursePrimaryPropsClassName = CoursePrimaryProps & {
  readonly className: string
}

function Component({ className }: CoursePrimaryPropsClassName) {
  return (
    <div className={className}>
      <div className="l-inner">
        <div className="e-head">
          <HeadingTab text="小学生コース" headingLevel="h3" />
        </div>
      </div>
      <div className="e-content-area0">
        <div className="l-inner">
          <h4 className="e-content-head">
            冬期講習の
            <span className="is-number">
              <img src={textTwoPointImage} alt="2" />
            </span>
            <span className="is-point">つのポイント</span>
          </h4>
          <div className="e-calc">
            <CoursePrimaryCalc />
          </div>
        </div>
        <div className="e-content-area1">
          <div className="l-inner">
            <div className="c-secret-illust">
              <SecretBoyPrimary />
            </div>
            <div className="c-secret-head">
              <p className="c-secret-head__lead">中学で必要な学力が身につく</p>
              <h5 className="c-secret-head__title">
                <img className="c-secret-head__number" src={textOneImage} alt="1" />
                集団WEB授業
              </h5>
            </div>
            <div className="c-secret1-box">
              <div className="c-secret1-box__content">
                <p className="c-secret1-box__text1">
                  <strong className="is-marker">
                    中学で学ぶ単元の土台となる基礎を復習し、定着させます<span className="is-palt">。</span>
                  </strong>
                  &nbsp;発言や質問をしながら進める対面式授業だから、自宅でも集中して楽しく勉強ができます。
                </p>
                <div className="c-secret1-box__image1">
                  <StaticImage src="../../../../assets/images/winter/course/primary/image1-1.jpg" alt="" placeholder="none" />
                </div>
              </div>
              <div className="c-secret1-box__tables">
                <h5 className={classNames('c-secret1-box__head', 'is-mt')}>対象</h5>
                <div className="c-box-table1">
                  <ul className="c-box-table1__lists">
                    <li className="c-box-table1__list">小学3年生～小学6年生</li>
                  </ul>
                </div>
                <h5 className={classNames('c-secret1-box__head', 'is-mt')}>日程</h5>
                <div className="c-box-table2">
                  <ul className="c-box-table2__lists">
                    <li className={classNames('c-box-table2__a', 'c-box-table2__list')}>
                      <span className="c-box-table2__list-head">小3｜</span>12/25(月)〜12/27(水)の3日間
                    </li>
                    <li className={classNames('c-box-table2__b', 'c-box-table2__list')}>
                      <span className="c-box-table2__list-head">小4｜</span>12/22(金)〜12/26(火)の5日間
                    </li>
                    <li className={classNames('c-box-table2__c', 'c-box-table2__list')}>
                      <span className="c-box-table2__list-head">小5・小6｜</span>12/22(金)〜12/27(水)の6日間
                    </li>
                  </ul>
                </div>
                <h5 className={classNames('c-secret1-box__head', 'is-mt')}>時間</h5>
                <div className="c-box-table3">
                  <ul className="c-box-table3__lists">
                    <li className="c-box-table3__list">19:00〜20:30｜1コマ40分｜1日2コマ</li>
                  </ul>
                </div>
                <h5 className={classNames('c-secret1-box__head', 'is-mt')}>教科</h5>
                <div className="c-box-table4">
                  <ul className="c-box-table4__lists">
                    <li className={classNames('c-box-table4__a', 'c-box-table4__list')}>
                      <span className="c-box-table4__list-head">小3・小4</span>
                      <br className="u-hidden-pc" />
                      算数・国語
                    </li>
                    <li className={classNames('c-box-table4__b', 'c-box-table4__list')}>
                      <span className="c-box-table4__list-head">小5・小6</span>
                      <br className="u-hidden-pc" />
                      算数・国語・英語・理科・社会
                    </li>
                  </ul>
                </div>
                <h5 className={classNames('c-secret1-box__head', 'is-mt2')}>料金</h5>
                <div className="c-box-table5">
                  <ul className="c-box-table5__lists">
                    <li className={classNames('c-box-table5__b', 'c-box-table5__list')}>
                      <span className="c-box-table5__list-head">小3</span>
                      <span className="c-box-table5__list-text">6,600円</span>
                    </li>
                    <li className={classNames('c-box-table5__c', 'c-box-table5__list')}>
                      <span className="c-box-table5__list-head">小4</span>
                      <span className="c-box-table5__list-text">11,000円</span>
                    </li>
                    <li className={classNames('c-box-table5__c', 'c-box-table5__list')}>
                      <span className="c-box-table5__list-head">小5</span>
                      <span className="c-box-table5__list-text">15,000円</span>
                    </li>
                    <li className={classNames('c-box-table5__c', 'c-box-table5__list')}>
                      <span className="c-box-table5__list-head">小6</span>
                      <span className="c-box-table5__list-text">15,000円</span>
                    </li>
                  </ul>
                  <ul className="c-box-table5__attentions">
                    <li className="e-box-table5-attention">表示価格は税込みです。</li>
                    <li className="e-box-table5-attention">
                      テキスト代&nbsp;&nbsp;
                      <br className="u-hidden-pc" />
                      小学3・4年生/2,220円　小学5・6年生/2,805円
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactSectionSimple
        balloonImageElement={<StaticImage src="../../../../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
        headImageElement={<ContactGirl />}
      />

      <div className="e-content-area3">
        <div className="l-inner">
          <div className="c-secret-illust">
            <SecretBoyPrimary />
          </div>
          <div className="c-secret-head">
            <p className="c-secret-head__lead">自宅で実験ができる</p>
            <h5 className="c-secret-head__title">
              <img className="c-secret-head__number" src={textTwoImage} alt="2" />
              わくわく理科実験講座
            </h5>
          </div>
          <div className="c-secret3-box">
            <p className="c-secret3-box__text">
              <strong className="is-marker">実験キットが自宅に届くから、実際に見て、触って、学べる!</strong>
              　先生と一緒に、実験から考察まで行います。理科や実験が好きなお子さまにおススメです。スマホでの受講が可能です。
            </p>
            <div className="c-secret3-box__tables">
              <h5 className={classNames('c-secret3-box__head', 'is-mt')}>実験内容</h5>
              <div className="c-box-table4">
                <ul className="c-box-table4__lists">
                  <li className={classNames('c-box-table4__a', 'c-box-table4__list')}>
                    <span className="c-box-table4__list-head">小1～小3</span>
                    <br className="u-hidden-pc" />
                    星座模型を作ろう
                  </li>
                  <li className={classNames('c-box-table4__b', 'c-box-table4__list')}>
                    <span className="c-box-table4__list-head">小4～小6</span>
                    <br className="u-hidden-pc" />
                    カイロのなぞを探れ!
                  </li>
                </ul>
              </div>
              <h5 className={classNames('c-secret3-box__head', 'is-mt')}>日程・時間</h5>
              <div className="c-box-table1">
                <ul className="c-box-table1__lists">
                  <li className="c-box-table1__list">12/23(土)　11:00〜12:10</li>
                </ul>
              </div>
              <h5 className={classNames('c-secret3-box__head', 'is-mt')}>料金</h5>
              <div className="c-box-table1">
                <ul className="c-box-table1__lists">
                  <li className="c-box-table1__list">
                    <strong className="is-em">4,400円</strong>
                  </li>
                </ul>
                <ul className="c-box-table1__price-attentions">
                  <li className="c-box-table1__price-attention">※教材の料金を含みます。</li>
                  <li className="c-box-table1__price-attention">※表示価格は税込みです。</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="c-deadline">
            <p className="c-deadline__head">最終申し込み締め切り</p>
            <p className="c-deadline__date">11/30(木)</p>
            <ul className="c-deadline__attentions">
              <li className="c-deadline__attention">※満席になり次第、申し込みを締め切る場合があります。</li>
            </ul>
          </div>
        </div>
      </div>

      <ContactSectionSimple
        balloonImageElement={<StaticImage src="../../../../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
        headImageElement={<ContactGirl />}
      />
    </div>
  )
}

const StyledComponent = styled(Component)<CoursePrimaryPropsClassName>`
  .l-inner {
    width: calc(${innerSeasonWidth.sm} + ${rem(10)});
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(1080)};
      padding-left: ${rem(40)};
      padding-right: ${rem(40)};
    }
  }

  .e-head {
    text-align: center;
  }

  .e-price-button {
    margin-top: ${rem(28)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(56)};
    }
  }

  .e-content-head {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${rem(18)};
    font-weight: 500;
    font-family: ${fontFamily.zenKaku};
    line-height: 1.6;
    text-align: center;
    letter-spacing: 0.1em;
    color: ${winterColor.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(30)};
    }

    img {
      display: block;
      margin: 0;
      width: ${rem(35)};

      @media ${breakpoints.lg} {
        width: ${rem(52)};
      }
    }

    > .is-point {
      font-weight: 700;
      font-size: ${rem(24)};
      letter-spacing: 0;
      margin-left: ${rem(2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(36)};
        margin-left: ${rem(4)};
      }
    }

    > .is-number {
      color: ${winterColor.main};
      position: relative;
      margin-left: ${rem(-4)};

      @media ${breakpoints.lg} {
        margin-left: ${rem(-4)};
      }

      &::after {
        content: '';
        position: absolute;
        width: ${rem(5)};
        height: ${rem(5)};
        background-color: currentColor;
        border-radius: 50%;
        top: ${rem(0)};
        left: 50%;
        transform: translate(-50%);
        top: ${rem(-10)};
        margin-left: ${rem(6)};

        @media ${breakpoints.lg} {
          width: ${rem(6)};
          height: ${rem(6)};
        }
      }
    }
  }

  .e-calc {
    margin-top: ${rem(20)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(30)};
      width: ${rem(640)};
      margin-inline: auto;
    }
  }

  .c-secret3-box__tables {
    @media ${breakpoints.lg} {
      width: ${rem(520)};
      margin-inline: auto;
    }
  }

  .c-secret-illust {
    text-align: center;

    @media ${breakpoints.lg} {
    }
  }

  .c-secret-head {
    width: ${rem(295)};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: ${rem(10)};
    background: #fff;
    border: 1.5px solid ${winterColor.text};
    padding: ${rem(20)} ${rem(10)};
    display: grid;
    justify-items: center;
    gap: ${rem(12)};
    position: relative;
    z-index: 2;

    @media ${breakpoints.lg} {
      width: ${rem(640)};
      padding-bottom: ${rem(20)};
      border-radius: ${rem(20)};
      border-width: 3px;
    }
  }

  .c-secret-head__lead {
    margin: 0;
    font-family: ${fontFamily.zenKaku};
    font-weight: 500;
    font-size: ${rem(16)};
    line-height: 1;
    text-align: center;
    color: ${winterColor.text};
    display: flex;
    align-items: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
    }

    &::before,
    &::after {
      content: '';
      width: 1.5px;
      height: ${rem(20)};
      background-color: #333;
      flex-shrink: 0;
      margin-top: ${rem(0)};

      @media ${breakpoints.lg} {
        width: 2px;
        height: ${rem(28)};
        margin-top: ${rem(0)};
      }
    }

    &::before {
      transform: rotate(-24deg);
      margin-right: ${rem(12)};
    }

    &::after {
      transform: rotate(24deg);
      margin-left: ${rem(8)};
    }
  }

  .c-secret1-box__tables {
    @media ${breakpoints.lg} {
      width: ${rem(520)};
      max-width: 100%;
      margin-inline: auto;
    }
  }

  .c-secret-head__title {
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(20)};
    letter-spacing: 0.1em;
    line-height: 1;
    color: ${winterColor.main};
    display: flex;
    align-items: center;
    gap: ${rem(10)};

    @media ${breakpoints.lg} {
      font-size: ${rem(32)};
    }
  }

  .c-secret-head__number {
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(60)};
    }
  }

  .c-secret1-box,
  .c-secret2-box,
  .c-secret3-box {
    margin-top: ${rem(-30)};
    border-radius: ${rem(10)};
    background: #fff;
    border: 1.5px solid #333;
    padding: ${rem(30)} ${rem(30)} ${rem(38)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(-40)};
      border-width: 3px;
      border-radius: ${rem(20)};
      padding-block: ${rem(100)} ${rem(60)};
    }
  }

  .c-secret1-box {
    padding-top: ${rem(65)};

    @media ${breakpoints.lg} {
      padding-top: ${rem(94)};
      padding-inline: ${rem(80)};
    }
  }

  .c-secret1-box__content {
    @media ${breakpoints.lg} {
      display: flex;
      flex-direction: row-reverse;
      gap: ${rem(40)};
    }
  }

  .c-box-table1__price-attentions {
    margin: ${rem(10)} 0 0;
    list-style: none;
    display: flex;
    gap: ${rem(16)};
    justify-content: flex-start;
  }

  .c-box-table1__price-attention {
    font-size: ${rem(10)};
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(12)};
    }
  }

  .c-secret2-box,
  .c-secret3-box {
    padding-top: ${rem(64)};

    @media ${breakpoints.lg} {
      padding-inline: ${rem(80)};
    }
  }

  .c-secret3-box {
    @media ${breakpoints.lg} {
      padding-top: ${rem(94)};
      padding-inline: ${rem(180)};
    }
  }

  .c-secret1-box__text1,
  .c-secret1-box__text2,
  .c-secret1-box__text3,
  .c-secret3-box__text {
    font-size: ${rem(16)};
    line-height: calc(28 / 16);
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      line-height: calc(36 / 20);
    }

    .is-marker {
      text-decoration: underline;
      text-decoration-color: ${winterColor.accent};
      text-decoration-thickness: ${rem(4)};
      font-weight: inherit;
      text-underline-offset: ${rem(-1)};
      text-decoration-skip-ink: none;
      font-feature-settings: 'palt';
    }
  }

  .c-secret1-box__text1 {
    @media ${breakpoints.lg} {
      flex: 1;
    }
  }

  .c-secret3-box__text {
    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
    }
  }

  .c-secret1-box__cycle-head {
    text-align: center;
    font-family: ${fontFamily.zenKaku};
    font-weight: 500;
    font-size: ${rem(16)};
    letter-spacing: 0.2em;
    line-height: calc(20 / 16);
    text-align: center;
    color: ${winterColor.main};
    margin: ${rem(20)} 0 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
    }
  }

  .c-secret1-box__cycle-content {
    display: block;
    width: ${rem(282.04)};
    max-width: 100%;
    margin: ${rem(10)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(375)};
    }
  }

  .c-secret1-box__arrow {
    display: block;
    width: ${rem(35)};
    margin: ${rem(20)} auto;

    @media ${breakpoints.lg} {
      width: ${rem(47)};
    }
  }

  .c-secret1-box__text2 {
  }

  .c-secret1-box__text3 {
  }

  .c-secret1-box__result-image {
    display: block;
    width: ${rem(275)};
    max-width: 100%;
    margin: ${rem(8)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(640)};
      margin-top: 0;
    }
  }

  .c-secret1-box__voice-head {
    margin-top: ${rem(40)};
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(16)};
    letter-spacing: 0.15em;
    line-height: 1.6;
    text-align: center;
    color: ${winterColor.main};
    border-bottom: 1px solid currentColor;
    padding-bottom: ${rem(10)};
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
      content: '';
      width: 1.5px;
      height: ${rem(18)};
      background-color: currentColor;
      flex-shrink: 0;
      margin-top: ${rem(0)};

      @media ${breakpoints.lg} {
        width: 2px;
        height: ${rem(28)};
        margin-top: ${rem(0)};
      }
    }

    &::before {
      transform: rotate(-24deg);
      margin-right: ${rem(12)};
    }

    &::after {
      transform: rotate(24deg);
      margin-left: ${rem(8)};
    }
  }

  .c-secret1-box__voice-items {
    margin-top: ${rem(30)};
    display: grid;
    gap: ${rem(20)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(38)};
    }
  }

  .c-secret1-box__voice-item {
  }

  .c-secret1-box__image1 {
    margin-top: ${rem(20)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(0)};
      flex: 1;
    }
  }

  .e-content-area0 {
    padding-top: ${rem(60)};
    background-color: ${winterColor.mainBackground};

    @media ${breakpoints.lg} {
      margin-top: ${rem(0)};
      padding-top: ${rem(90)};
    }
  }

  .e-content-area1,
  .e-content-area3 {
    padding-top: ${rem(50)};
    padding-bottom: ${rem(50)};
    background-color: ${winterColor.mainBackground};

    @media ${breakpoints.lg} {
      padding-top: ${rem(80)};
      padding-bottom: ${rem(80)};
    }
  }

  .e-content-area1 {
  }

  .e-content-area3 {
  }

  .c-secret2-box {
  }

  .c-secret2-box__head1,
  .c-secret2-box__head2,
  .c-secret3-box__head1,
  .c-secret3-box__head2 {
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(18)};
    letter-spacing: 0.15em;
    line-height: 1.6;
    text-align: center;
    color: ${winterColor.main};
    padding-bottom: ${rem(16)};
    border-bottom: 1.5px solid #333;
    margin-inline: ${rem(-30)};
    margin-bottom: ${rem(40)};

    @media ${breakpoints.lg} {
      margin-inline: ${rem(-80)};
      font-size: ${rem(26)};
      padding-top: ${rem(14)};
      padding-bottom: ${rem(26)};
      margin-bottom: ${rem(60)};
    }
  }

  .c-secret2-card,
  .c-secret3-card {
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};

    @media ${breakpoints.lg} {
      flex-direction: row;
      gap: ${rem(40)};
    }

    &__image {
      @media ${breakpoints.lg} {
        flex: 1;
      }

      img {
        margin-bottom: 0;
      }
    }

    &__body {
      @media ${breakpoints.lg} {
        flex: 1;
      }
    }

    &__text {
      font-size: ${rem(16)};
      line-height: calc(28 / 16);
      margin: 0;

      @media ${breakpoints.lg} {
        font-size: ${rem(18)};
      }

      .is-marker {
        text-decoration: underline;
        text-decoration-color: ${winterColor.accent};
        text-decoration-thickness: ${rem(4)};
        font-weight: inherit;
        text-underline-offset: ${rem(-1)};
        text-decoration-skip-ink: none;
      }
    }

    &__illust {
      @media ${breakpoints.lg} {
        margin-top: ${rem(60)};
        text-align: center;

        img {
          width: ${rem(87.62)};
        }
      }
    }
  }

  .c-secret2-box__head2,
  .c-secret3-box__head2 {
    padding-top: ${rem(20)};
    border-top: 1.5px solid #333;
    margin-top: ${rem(36)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
      padding-top: ${rem(26)};
    }
  }

  > .e-contact {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    @media ${breakpoints.lg} {
    }
  }

  > .e-overview {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    @media ${breakpoints.lg} {
    }
  }

  .c-secret1-box__head,
  .c-secret3-box__head {
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(16)};
    letter-spacing: 0.3em;
    text-align: center;
    color: ${winterColor.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(22)};
    }

    &.is-mt {
      margin-top: ${rem(30)};
    }

    &.is-mt2 {
      margin-top: ${rem(34)};
    }

    &.is-mt3 {
      margin-top: ${rem(38)};
    }
  }

  .c-box-table1,
  .c-box-table2,
  .c-box-table3,
  .c-box-table4,
  .c-box-table5 {
    margin-top: ${rem(8)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
    }
  }

  .c-box-table1__head,
  .c-box-table2__head,
  .c-box-table3__head {
    font-size: ${rem(16)};
    font-family: ${fontFamily.zenKaku};
    color: ${winterColor.main};
    letter-spacing: 0.3em;
    font-weight: 700;
    line-height: 1;
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(22)};
    }
  }

  .c-box-table1__lists,
  .c-box-table2__lists {
    margin: 0;
    border-top: 1px solid ${winterColor.main};
    border-bottom: 1px solid ${winterColor.main};
    background: #fff;
    padding: ${rem(0)};
    list-style: none;

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
    }
  }

  .c-box-table2__lists {
  }

  .c-box-table1__list {
    font-size: ${rem(14)};
    white-space: nowrap;
    line-height: 1.94;
    margin-bottom: 0;
    letter-spacing: 0.1em;
    padding: ${rem(5)};
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      line-height: calc(40 / 16);
      letter-spacing: 0.1em;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d0d0d0;
    }
  }

  .c-box-table2__lists {
  }

  .c-box-table2__list {
    font-size: ${rem(14)};
    white-space: nowrap;
    line-height: 1.92;
    letter-spacing: 0.05em;
    margin-bottom: 0;

    @media ${breakpoints.lg} {
      width: ${rem(400)};
      margin-inline: auto;
      padding-left: ${rem(32)};
      font-size: ${rem(16)};
      line-height: calc(40 / 16);
      letter-spacing: 0.1em;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d0d0d0;
    }
  }

  .c-box-table2__list-head {
    color: ${winterColor.main};
  }

  .c-box-table3__lists {
    margin: 0;
    border-top: 1px solid ${winterColor.main};
    border-bottom: 1px solid ${winterColor.main};
    background: #fff;
    padding: ${rem(10)} ${rem(0)};
    list-style: none;
    max-width: 100%;

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
      width: 100%;
    }
  }

  .c-box-table3__list {
    font-size: ${rem(14)};
    letter-spacing: 0.1em;
    line-height: calc(22 / 14);
    margin-bottom: 0;
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
    }

    &:not(:last-child) {
      margin-bottom: ${rem(8)};
      padding-bottom: ${rem(8)};
      border-bottom: 1px solid #d0d0d0;
    }
  }

  .c-box-table3__list-head {
    color: ${winterColor.main};
  }

  .c-box-table4 {
  }

  .c-box-table4__lists {
    margin: 0;
    border-top: 1px solid ${winterColor.main};
    border-bottom: 1px solid ${winterColor.main};
    background: #fff;
    padding: ${rem(10)} ${rem(0)};
    list-style: none;
    max-width: 100%;
  }

  .c-box-table4__list {
    font-size: ${rem(14)};
    letter-spacing: 0.1em;
    line-height: calc(22 / 14);
    margin-bottom: 0;
    text-align: center;

    @media ${breakpoints.lg} {
      width: ${rem(400)};
      margin-inline: auto;
      text-align: left;
      font-size: ${rem(16)};
      padding-left: ${rem(62)};
    }

    &:not(:last-child) {
      margin-bottom: ${rem(8)};
      padding-bottom: ${rem(8)};
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: ${rem(222)};
        height: 1px;
        background: #d0d0d0;
        bottom: 0;
        inset-inline: 0;
        margin-inline: auto;

        @media ${breakpoints.lg} {
          width: ${rem(400)};
        }
      }
    }
  }

  .c-box-table4__a {
  }

  .c-box-table4__b {
  }

  .c-box-table4__list-head {
    color: ${winterColor.main};

    @media ${breakpoints.lg} {
      margin-right: ${rem(16)};
    }
  }

  .c-box-table5 {
  }

  .c-box-table5__lists {
    margin: 0;
    border-top: 1px solid ${winterColor.main};
    border-bottom: 1px solid ${winterColor.main};
    margin: ${rem(6)} auto 0;
    background: #fff;
    padding: ${rem(0)};
    list-style: none;
    width: ${rem(240)};
    max-width: 100%;

    @media ${breakpoints.lg} {
      width: 100%;
    }
  }

  .c-box-table5__list {
    font-size: ${rem(14)};
    white-space: nowrap;
    line-height: 1.94;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    padding-left: ${rem(64)};

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      line-height: 2.5;
      letter-spacing: 0.1em;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      width: ${rem(400)};
      padding-left: ${rem(130)};
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d0d0d0;
    }
  }

  .c-box-table5__list-head {
    color: ${winterColor.main};
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .c-box-table5__list-text {
    font-weight: 700;

    &::before {
      content: '｜';
      color: ${winterColor.main};
    }
  }

  .c-box-table5__a {
  }

  .c-box-table5__b {
  }

  .c-box-table5__c {
  }

  .c-box-table5__attentions {
    margin: ${rem(10)} 0 0;
    text-align: left;
    font-size: ${rem(20 / 2)};
    list-style: none;
    width: ${rem(240)};
    margin-inline: auto;

    @media ${breakpoints.lg} {
      width: ${rem(500)};
      font-size: ${rem(12)};
      margin-left: 0;
      margin-right: 0;
      padding-left: ${rem(0)};
    }
  }

  .e-box-table5-attention {
    display: flex;
    margin: 0;
    line-height: calc(16 / 10);

    @media ${breakpoints.lg} {
    }

    &::before {
      content: '※';
    }
  }

  .c-deadline {
    margin-top: ${rem(40)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(58)};
    }
  }

  .c-deadline__head {
    font-size: ${rem(16)};
    letter-spacing: 0.1em;
    font-weight: 500;
    line-height: 1.6;
    width: ${rem(240)};
    max-width: 100%;
    padding-bottom: ${rem(5)};
    text-align: center;
    border-bottom: 1px solid #d0d0d0;
    margin: 0 auto;

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
      width: ${rem(340)};
    }
  }

  .c-deadline__date {
    margin: ${rem(5)} 0 0;
    font-weight: 500;
    font-size: ${rem(16)};
    letter-spacing: 0.1em;
    text-align: center;
    color: ${winterColor.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
      margin-top: ${rem(3)};
    }
  }

  .c-deadline__attentions {
    margin: ${rem(10)} 0 0;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-size: ${rem(10)};
    line-height: 1.6;
    list-style: none;
    list-style: none;

    @media ${breakpoints.lg} {
      margin-top: ${rem(8)};
      font-size: ${rem(12)};
      width: ${rem(340)};
    }
  }

  .c-deadline__attention {
    margin: 0;
    line-height: calc(16 / 10);
  }
`

export const CoursePrimary: (props: CoursePrimaryProps) => JSX.Element = StyledComponent

export default CoursePrimary
