import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerWidth, winterColor } from 'variables/_index'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import VoiceCard from '../../section/winter/atom/voiceCard'
import ArrowNextImageSP from 'assets/images/winter/voice/slide-right-sp.svg'
import ArrowPrevImageSP from 'assets/images/winter/voice/slide-left-sp.svg'
import ArrowNextImagePC from 'assets/images/winter/voice/slide-right-pc.svg'
import ArrowPrevImagePC from 'assets/images/winter/voice/slide-left-pc.svg'
import LeadImage1SP from 'assets/images/winter/voice/lead1-sp.svg'
import LeadImage1PC from 'assets/images/winter/voice/lead1-pc.svg'
import LeadImage2SP from 'assets/images/winter/voice/lead2-sp.svg'
import LeadImage2PC from 'assets/images/winter/voice/lead2-pc.svg'
import LeadImage3SP from 'assets/images/winter/voice/lead3-sp.svg'
import LeadImage3PC from 'assets/images/winter/voice/lead3-pc.svg'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'

export interface WorrySectionProps {
  readonly noProps?: string
}

type WorrySectionPropsClassName = WorrySectionProps & {
  readonly className: string
}

function Component({ className }: WorrySectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <div className="l-inner">
        <h2 ref={ref} className={classNames('c-lead', inView && 'is-view')}>
          <picture className="c-lead__line1">
            <source media={breakpoints.lg} srcSet={LeadImage1PC} width={163.5} height={49.1} />
            <img src={LeadImage1SP} alt="この冬、" width={102.2} height={31} />
          </picture>
          <picture className="c-lead__line2">
            <source media={breakpoints.lg} srcSet={LeadImage2PC} width={553.014} height={49.6} />
            <img src={LeadImage2SP} alt="勉強のつまずきを解消。" width={257.3} height={73.5} />
          </picture>
          <picture className="c-lead__line3">
            <source media={breakpoints.lg} srcSet={LeadImage3PC} width={485.361} height={72.761} />
            <img src={LeadImage3SP} alt="3学期で点数アップ" width={313.125} height={50.026} />
          </picture>
        </h2>
        <div className="c-drop1">
          <div className="c-drop1__fadeIn">
            <StaticImage className="c-drop1__image" src="../../../assets/images/winter/mv/snow/snow8.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>

        <div className="c-drop2">
          <div className="c-drop2__fadeIn">
            <StaticImage className="c-drop2__image" src="../../../assets/images/winter/mv/snow/snow4.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
        <div className="c-drop3">
          <div className="c-drop3__fadeIn">
            <StaticImage
              className="c-drop3__image"
              src="../../../assets/images/winter/mv/snow/snow11.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="c-drop4">
          <div className="c-drop4__fadeIn">
            <StaticImage
              className="c-drop4__image"
              src="../../../assets/images/winter/mv/snow/snow10.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="c-drop5">
          <div className="c-drop5__fadeIn">
            <StaticImage
              className="c-drop5__image"
              src="../../../assets/images/winter/mv/snow/snow11.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="c-drop6">
          <div className="c-drop6__fadeIn">
            <StaticImage className="c-drop6__image" src="../../../assets/images/winter/mv/snow/snow9.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
        <div className="c-drop7">
          <div className="c-drop7__fadeIn">
            <StaticImage className="c-drop7__image" src="../../../assets/images/winter/mv/snow/snow6.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
        <div className="c-drop8">
          <div className="c-drop8__fadeIn">
            <StaticImage
              className="c-drop8__image"
              src="../../../assets/images/winter/mv/snow/snow10.png"
              alt=""
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <p className="c-voice-text">
          Fitでこんなにテストの点数
          <br className="u-hidden-pc" />
          上がってます!
        </p>
        <div className="c-voice-slider">
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            spaceBetween={10}
            slidesPerView={1.42}
            centeredSlides={true}
            pagination={{ clickable: true }}
            navigation={true}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    数学<strong className="is-marker">32点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice1.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="M.Dさん"
                text={<>学校の授業を受けるのが最近楽しくなってきている!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    数学<strong className="is-marker">29点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice2.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="S.Iさん"
                text={<>授業中に自分の間違いに気づくことができた!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    英語<strong className="is-marker">20点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice3.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="A.Mさん"
                text={<>先生が楽しいし、わかりやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    数学<strong className="is-marker">24点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice4.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="K.Mさん"
                text={<>わからないところが質問しやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    数学<strong className="is-marker">18点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice5.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="T.Sさん"
                text={<>Fitが学校の授業の予習になり授業に取り組みやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    数学<strong className="is-marker">24点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice6.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="R.Hさん"
                text={<>いつでも直接教えてもらえるところがいい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    英語<strong className="is-marker">35点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice7.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="N.Oさん"
                text={<>どんなに簡単な問題でも、しっかり教えてくれる!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    数学<strong className="is-marker">53点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice8.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="K.Iさん"
                text={<>自分のペースに合わせてやってくれる!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    数学<strong className="is-marker">26点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice9.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学1年生"
                name="H.Hさん"
                text={<>Fitの先生の教え方がわかりやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    数学<strong className="is-marker">20点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice10.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="S.Hさん"
                text={<>先生がわからない問題を優しく教えてくれる!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    数学<strong className="is-marker">29点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice11.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="K.Nさん"
                text={<>先生の教え方がわかりやすく、学びやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title={
                  <>
                    数学<strong className="is-marker">23点アップ!</strong>
                  </>
                }
                imageElement={<StaticImage src="../../../assets/images/winter/voice/voice12.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="Y.Tさん"
                text={<>効率よく予習復習の勉強ができるようになった!</>}
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="c-attention">※点数アップ時の学年です。</div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<WorrySectionPropsClassName>`
  background-color: ${winterColor.main};
  padding-top: ${rem(50)};
  padding-bottom: ${rem(50)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(120)};
    padding-bottom: ${rem(120)};
  }

  @keyframes DropVoiceSection {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(${rem(10)});
    }
    100% {
      transform: translateY(0);
    }
  }

  .l-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
    }
  }

  .c-lead {
    text-align: center;

    img {
      /* width: ${rem(311)}; */
      height: auto;
      display: block;
      margin-inline: auto;
      margin-bottom: 0;
      width: 100%;

      @media ${breakpoints.lg} {
        /* width: ${rem(554)}; */
      }
    }

    &.is-view {
      .c-lead__line1 {
        transition: opacity 2s ease 0s;
        opacity: 1;
      }

      .c-lead__line2 {
        transition: opacity 2s ease 0.8s;
        opacity: 1;
      }

      .c-lead__line3 {
        transition: opacity 2s ease 1.6s;
        opacity: 1;
      }
    }
  }

  .c-lead__line1,
  .c-lead__line2,
  .c-lead__line3 {
    opacity: 0;
    height: auto;
    display: block;
    margin-inline: auto;
    text-align: center;
  }

  .c-lead__line1 {
    @media ${breakpoints.lg} {
      padding-left: ${rem(50)};
    }

    img {
      width: ${rem(102.2)};

      @media ${breakpoints.lg} {
        width: ${rem(163.5)};
      }
    }
  }

  .c-lead__line2 {
    margin-top: ${rem(10)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(16)};
    }

    img {
      width: ${rem(257.3)};

      @media ${breakpoints.lg} {
        width: ${rem(553.014)};
      }
    }
  }

  .c-lead__line3 {
    margin-top: ${rem(6)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(14)};
      padding-left: ${rem(68)};
    }

    img {
      width: ${rem(313.125)};

      @media ${breakpoints.lg} {
        width: ${rem(485.361)};
      }
    }
  }

  .c-drop1,
  .c-drop2,
  .c-drop3,
  .c-drop4,
  .c-drop5,
  .c-drop6,
  .c-drop7,
  .c-drop8 {
    position: absolute;
    line-height: 0;
  }

  .c-drop1 {
    top: ${rem(-20)};
    right: calc(50% + ${rem(106)});
    width: ${rem(17)};

    @media ${breakpoints.lg} {
      top: ${rem(-52)};
      right: calc(50% + ${rem(288)});
      width: ${rem(24.29)};
    }

    &__fadeIn {
      @media ${breakpoints.lg} {
      }
    }

    &__image {
      animation: DropLogoSection 4.7s ease-in-out infinite;
    }
  }

  .c-drop2 {
    top: ${rem(8)};
    right: calc(50% + ${rem(155)});
    width: ${rem(9)};

    @media ${breakpoints.lg} {
      top: ${rem(24)};
      right: calc(50% + ${rem(414)});
      width: ${rem(13.43)};
    }

    &__fadeIn {
      @media ${breakpoints.lg} {
      }
    }

    &__image {
      animation: DropLogoSection 5.3s ease-in-out infinite;
    }
  }

  .c-drop3 {
    top: ${rem(88)};
    right: calc(50% + ${rem(156)});
    width: ${rem(9)};

    @media ${breakpoints.lg} {
      top: ${rem(114)};
      right: calc(50% + ${rem(374)});
      width: ${rem(12.61)};
    }

    &__fadeIn {
      @media ${breakpoints.lg} {
      }
    }

    &__image {
      animation: DropLogoSection 5.8s ease-in-out infinite;
    }
  }

  .c-drop4 {
    top: ${rem(196)};
    right: calc(50% + ${rem(155)});
    width: ${rem(11)};

    @media ${breakpoints.lg} {
      top: ${rem(234)};
      right: calc(50% + ${rem(434)});
      width: ${rem(15.95)};
    }

    &__fadeIn {
      @media ${breakpoints.lg} {
      }
    }

    &__image {
      animation: DropLogoSection 5.6s ease-in-out infinite;
    }
  }

  .c-drop5 {
    top: ${rem(-22)};
    left: calc(50% + ${rem(115)});
    width: ${rem(9)};

    @media ${breakpoints.lg} {
      top: ${rem(-66)};
      left: calc(50% + ${rem(334)});
      width: ${rem(12.61)};
    }

    &__fadeIn {
      @media ${breakpoints.lg} {
      }
    }

    &__image {
      animation: DropLogoSection 6.2s ease-in-out infinite;
    }
  }

  .c-drop6 {
    top: ${rem(0)};
    left: calc(50% + ${rem(146)});
    width: ${rem(19)};

    @media ${breakpoints.lg} {
      top: ${rem(18)};
      left: calc(50% + ${rem(404)});
      width: ${rem(28.29)};
    }

    &__fadeIn {
      @media ${breakpoints.lg} {
      }
    }

    &__image {
      animation: DropLogoSection 5.3s ease-in-out infinite;
    }
  }

  .c-drop7 {
    top: ${rem(168)};
    left: calc(50% + ${rem(152)});
    width: ${rem(14)};

    @media ${breakpoints.lg} {
      top: ${rem(162)};
      left: calc(50% + ${rem(362)});
      width: ${rem(20.55)};
    }

    &__fadeIn {
      @media ${breakpoints.lg} {
      }
    }

    &__image {
      animation: DropLogoSection 4.6s ease-in-out infinite;
    }
  }

  .c-drop8 {
    top: ${rem(192)};
    left: calc(50% + ${rem(125)});
    width: ${rem(9)};

    @media ${breakpoints.lg} {
      top: ${rem(232)};
      left: calc(50% + ${rem(462)});
      width: ${rem(15.95)};
    }

    &__fadeIn {
      @media ${breakpoints.lg} {
      }
    }

    &__image {
      animation: DropLogoSection 5.1s ease-in-out infinite;
    }
  }

  .c-voice-text {
    margin: ${rem(50)} 0 0;
    font-size: ${rem(18)};
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: calc(27 / 18);
    letter-spacing: 0.25em;

    @media ${breakpoints.lg} {
      margin-top: ${rem(80)};
      font-size: ${rem(24)};
    }
  }

  .c-voice-slider {
    margin-top: ${rem(20)};
    width: 100vw;
    margin-inline: calc(50% - 50vw);

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
      margin-top: ${rem(30)};
      margin-left: auto;
      margin-right: auto;
      padding-left: ${rem(30)};
      padding-right: ${rem(30)};
      overflow: hidden;
      position: relative;
    }

    .swiper {
      padding-bottom: ${rem(28)};

      @media ${breakpoints.lg} {
        padding-bottom: ${rem(42)};
        overflow: visible;
        width: ${rem(840)};

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          height: 100%;
          width: ${rem(80)};
          background: ${winterColor.main};
          z-index: 2;
        }

        &::before {
          left: 101%;
        }

        &::after {
          right: 101%;
        }
      }
    }

    .swiper-slide {
      height: auto;

      > [class^='voiceCard'] {
        height: 100%;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: ${rem(32)};
      height: ${rem(32)};
      margin-top: ${rem(-35)};
      z-index: 3;

      @media ${breakpoints.lg} {
        width: ${rem(40)};
        height: ${rem(40)};
        margin-top: ${rem(-54)};
      }
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      display: none;
    }

    .swiper-button-prev {
      background-image: url(${ArrowPrevImageSP});
      background-repeat: no-repeat;
      background-size: contain;
      left: auto;
      right: calc(50% + calc(118 / 375 * 100vw));

      @media ${breakpoints.lg} {
        background-image: url(${ArrowPrevImagePC});
        right: calc(50% + ${rem(456)});
      }
    }

    .swiper-button-next {
      background-image: url(${ArrowNextImageSP});
      background-repeat: no-repeat;
      background-size: contain;
      right: auto;
      left: calc(50% + calc(118 / 375 * 100vw));

      @media ${breakpoints.lg} {
        background-image: url(${ArrowNextImagePC});
        left: calc(50% + ${rem(456)});
      }
    }

    .swiper-pagination {
      bottom: ${rem(0)};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${rem(18)};

      @media ${breakpoints.lg} {
        gap: ${rem(20)};
      }
    }

    .swiper-pagination-bullet {
      width: ${rem(8)};
      height: ${rem(8)};
      background-color: #fff;
      opacity: 1;
      margin-right: 0 !important;
      margin-left: 0 !important;

      @media ${breakpoints.lg} {
        width: ${rem(12)};
        height: ${rem(12)};
      }

      &.swiper-pagination-bullet-active {
        background-color: ${winterColor.accent};
      }
    }
  }

  .c-attention {
    margin-top: ${rem(10)};
    font-size: ${rem(10)};
    color: #fff;
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(11)};
      font-size: ${rem(12)};
    }
  }
`

export const WorrySection: (props: WorrySectionProps) => JSX.Element = StyledComponent

export default WorrySection
