import React from 'react'
import styled from 'styled-components'
import { breakpoints, rem, innerSeasonWidth } from 'variables/_index'
import LogoImage from 'assets/images/winter/logo/logo.svg'
import { StaticImage } from 'gatsby-plugin-image'

export interface LogoSectionProps {
  readonly npProps?: string
}

type LogoSectionPropsClassName = LogoSectionProps & {
  readonly className: string
}

function Component({ className }: LogoSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="c-logo">
        <img src={LogoImage} alt="オンライン個別指導塾 Fit NET STUDY（フィットネットスタディ）" width="245" height="84" />
      </div>
      <div className="c-drop1">
        <div className="c-drop1__fadeIn">
          <StaticImage className="c-drop1__image" src="../../../assets/images/winter/mv/snow/snow9.png" alt="" placeholder="none" loading="eager" />
        </div>
      </div>
      <div className="c-drop2">
        <div className="c-drop2__fadeIn">
          <StaticImage className="c-drop2__image" src="../../../assets/images/winter/mv/snow/snow2.png" alt="" placeholder="none" loading="eager" />
        </div>
      </div>
      <div className="c-drop3">
        <div className="c-drop3__fadeIn">
          <StaticImage className="c-drop3__image" src="../../../assets/images/winter/mv/snow/snow6.png" alt="" placeholder="none" loading="eager" />
        </div>
      </div>
      <div className="c-drop4">
        <div className="c-drop4__fadeIn">
          <StaticImage className="c-drop4__image" src="../../../assets/images/winter/mv/snow/snow5.png" alt="" placeholder="none" loading="eager" />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<LogoSectionPropsClassName>`
  padding-top: ${rem(38)};
  padding-bottom: ${rem(50)};
  position: relative;
  width: ${innerSeasonWidth.sm};
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media ${breakpoints.lg} {
    padding-top: ${rem(52)};
    padding-bottom: ${rem(80)};
    width: ${rem(1080)};
    padding-left: ${rem(40)};
    padding-right: ${rem(40)};
  }

  @keyframes DropLogoSection {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(${rem(10)});
    }
    100% {
      transform: translateY(0);
    }
  }

  .c-logo {
    text-align: center;

    img {
      display: block;
      margin: 0 auto;

      @media ${breakpoints.lg} {
        width: ${rem(380)};
      }
    }
  }

  .c-drop1,
  .c-drop2,
  .c-drop3,
  .c-drop4 {
    position: absolute;
    line-height: 0;
  }

  .c-drop1 {
    top: ${rem(23)};
    right: calc(50% + ${rem(126)});
    width: ${rem(19)};

    @media ${breakpoints.lg} {
      width: ${rem(28.29)};
      top: ${rem(147)};
      right: calc(50% + ${rem(306)});
    }
  }

  .c-drop1__fadeIn {
    @media ${breakpoints.lg} {
    }
  }

  .c-drop1__image {
    animation: DropLogoSection 4.1s ease-in-out infinite;
  }

  .c-drop2 {
    bottom: ${rem(42)};
    right: calc(50% + ${rem(146)});
    width: ${rem(19)};

    @media ${breakpoints.lg} {
      width: ${rem(28.47)};
      bottom: ${rem(78)};
      right: calc(50% + ${rem(404)});
    }
  }

  .c-drop2__fadeIn {
    @media ${breakpoints.lg} {
    }
  }

  .c-drop2__image {
    animation: DropLogoSection 3.6s ease-in-out infinite;
  }

  .c-drop3 {
    top: ${rem(9)};
    left: calc(50% + ${rem(112)});
    width: ${rem(14)};

    @media ${breakpoints.lg} {
      width: ${rem(20.55)};
      top: ${rem(112)};
      left: calc(50% + ${rem(262)});
    }
  }

  .c-drop3__fadeIn {
    @media ${breakpoints.lg} {
    }
  }

  .c-drop3__image {
    animation: DropLogoSection 6.1s ease-in-out infinite;
  }

  .c-drop4 {
    bottom: ${rem(62)};
    left: calc(50% + ${rem(146)});
    width: ${rem(19)};

    @media ${breakpoints.lg} {
      width: ${rem(29.58)};
      bottom: ${rem(128)};
      left: calc(50% + ${rem(356)});
    }
  }

  .c-drop4__fadeIn {
    @media ${breakpoints.lg} {
    }
  }

  .c-drop4__image {
    animation: DropLogoSection 4.6s ease-in-out infinite;
  }
`

export const LogoSection: (props: LogoSectionProps) => JSX.Element = StyledComponent

export default LogoSection
