import React from 'react'
import styled from 'styled-components'
import { breakpoints, rem, winterColor } from 'variables/_index'

export interface TeacherCardProps {
  readonly title: string
  readonly imageElement: JSX.Element
  readonly position: string
  readonly text: JSX.Element
}

type TeacherCardPropsClassName = TeacherCardProps & {
  readonly className: string
}

function Component({ className, title, imageElement, position, text }: TeacherCardPropsClassName) {
  return (
    <div className={className}>
      <div className="e-title">{title}</div>
      <div className="e-pos">{position}</div>
      <div className="e-image">{imageElement}</div>
      <div className="e-text-area">
        <div className="e-text">{text}</div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<TeacherCardPropsClassName>`
  background-color: ${winterColor.main};
  border-radius: ${rem(30 / 2)};
  border: 1.5px solid #333;
  position: relative;
  padding: ${rem(20 / 2)} ${rem(20 / 2)} ${rem(16)};
  text-align: center;
  border: 1.5px solid #333;
  color: #fff;

  @media ${breakpoints.lg} {
    border-width: 3px;
    border-radius: ${rem(30)};
    padding: ${rem(20)} ${rem(20)};
  }

  > .e-title {
    font-size: ${rem(36 / 2)};
    font-weight: 700;
    text-align: center;
    padding-left: ${rem(20 / 2)};
    padding-right: ${rem(20 / 2)};

    @media ${breakpoints.lg} {
      font-size: ${rem(28)};
    }
  }

  > .e-pos {
    margin-top: ${rem(10 / 2)};
    font-size: ${rem(26 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(0)};
      font-size: ${rem(18)};
    }
  }

  > .e-image {
    margin-top: ${rem(20 / 2)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(18)};
    }

    img {
      width: ${rem(240 / 2)};
      max-width: 100%;

      @media ${breakpoints.lg} {
        width: ${rem(270)};
      }
    }
  }

  > .e-text-area {
    margin-top: ${rem(4)};
    text-align: left;

    @media ${breakpoints.lg} {
      margin-top: ${rem(18)};
    }

    > .e-text {
      display: inline;
      line-height: 1.88;
      font-size: ${rem(34 / 2)};
      font-weight: 700;
      color: #fff;
      border-bottom: 1px solid currentColor;

      @media ${breakpoints.lg} {
        font-size: ${rem(22)};
        line-height: calc(44 / 22);
      }
    }
  }
`

export const TeacherCard: (props: TeacherCardProps) => JSX.Element = StyledComponent

export default TeacherCard
