import React from 'react'
import styled from 'styled-components'
import MainVisualSection from 'components/section/winter/mainVisualSection'
import LogoSection from 'components/section/winter/logoSection'
import CampaignSection from 'components/section/winter/campaignSection'
import VoiceSection from 'components/section/winter/voiceSection'
import ContactSection from 'components/section/winter/contactSection'
import ContactSectionHasCampaign from 'components/section/winter/contactSectionHasCampaign'
import ContactSectionSimple from 'components/section/winter/contactSectionSimple'
import ContactSectionLast from 'components/section/winter/contactSectionLast'
import CourseSection from 'components/section/winter/courseSection'
import FlowSection from 'components/section/winter/flowSection'
import StyleSection from 'components/section/winter/styleSection'
import LinkSection from 'components/section/winter/linkSection'
import { StaticImage } from 'gatsby-plugin-image'
import AboutSection from 'components/section/winter/aboutSection'
import ContactGirl from 'components/section/winter/atom/contactGirl'
import CloseSection from 'components/section/winter/closeSection'

export interface WinterTemplateProps {
  readonly noProps?: string
}

type WinterTemplatePropsClassName = WinterTemplateProps & {
  readonly className: string
}

function Component({ className }: WinterTemplatePropsClassName) {
  return (
    <div className={className}>
      <MainVisualSection />
      <CloseSection />
      <LogoSection />
      <VoiceSection />
      <ContactSectionHasCampaign
        balloonImageElement={<StaticImage src="../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
        headImageElement={<ContactGirl />}
      />
      <AboutSection />
      <CampaignSection />
      <ContactSectionSimple
        balloonImageElement={<StaticImage src="../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
        headImageElement={<ContactGirl />}
      />
      <CourseSection />
      <FlowSection />
      <ContactSectionSimple
        balloonImageElement={<StaticImage src="../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
        headImageElement={<ContactGirl />}
      />
      <StyleSection />
      <ContactSectionLast
        balloonImageElement={<StaticImage src="../assets/images/illustration/illustration16-4.png" alt="" placeholder="none" />}
        headImageElement={<ContactGirl />}
      />
    </div>
  )
}

const StyledComponent = styled(Component)<WinterTemplatePropsClassName>``

export const WinterTemplate: (props: WinterTemplateProps) => JSX.Element = StyledComponent

export default WinterTemplate
