import React from 'react'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints, winterColor } from 'variables/_index'

export interface HeadingTabProps {
  readonly text: string
  readonly headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div'
}

type HeadingTabPropsClassName = HeadingTabProps & {
  readonly className: string
}

function Component({ className, text, headingLevel = 'div' }: HeadingTabPropsClassName) {
  const HeadingElement = headingLevel
  return (
    <HeadingElement className={className}>
      <div className="e-text">{text}</div>
    </HeadingElement>
  )
}

const StyledComponent = styled(Component)<HeadingTabPropsClassName>`
  font-size: ${rem(28)};
  font-family: ${fontFamily.zenKaku};
  letter-spacing: 0.15em;
  line-height: 1.6;
  color: ${winterColor.main};
  font-weight: 700;
  border-bottom: 1.5px solid #333;
  text-align: center;

  @media ${breakpoints.lg} {
    font-size: ${rem(30)};
    max-width: 100%;
    border-width: 3px;
    letter-spacing: 0.1em;
  }

  > .e-text {
    width: ${rem(250)};
    max-width: 100%;
    border-radius: ${rem(10)} ${rem(10)} 0 0;
    border: 1.5px solid #333;
    border-bottom: none;
    display: inline-block;
    padding-block: ${rem(4)};

    @media ${breakpoints.lg} {
      min-width: ${rem(490)};
      font-size: ${rem(40)};
      padding-top: ${rem(6)};
      padding-bottom: ${rem(6)};
      border-radius: ${rem(20)} ${rem(20)} 0 0;
      border-width: 3px;
    }
  }
`

export const HeadingTab: (props: HeadingTabProps) => JSX.Element = StyledComponent

export default HeadingTab
