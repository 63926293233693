import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, fontFamily, winterColor, innerSeasonWidth } from 'variables/_index'
import ArrowIcon from 'assets/images/winter/flow/flow_arrow.svg'
import flowStep3SPImage from 'assets/images/winter/flow/flow_step3-sp.jpg'
import flowStep3PCImage from 'assets/images/winter/flow/flow_step3-pc.jpg'

export interface FlowSectionProps {
  readonly noProps?: string
}

type FlowSectionPropsClassName = FlowSectionProps & {
  readonly className: string
}

function Component({ className }: FlowSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="l-inner">
        <h2 className="c-flow-head">
          講習<span className="is-small">までの</span>流れ
        </h2>
        <StaticImage className="c-flow-image" src="../../../assets/images/winter/flow/flow_top_img.jpg" alt="" placeholder="none" />
        <ol className="c-flow-steps">
          <li className={classNames('c-flow-steps__item', 'c-flow-step')}>
            <h3 className="c-flow-step__title">
              <span className="c-flow-step__number">1｜</span>
              <span className="c-flow-step__text">無料体験のお申し込み</span>
            </h3>
          </li>
          <li className={classNames('c-flow-steps__item', 'c-flow-step')}>
            <h3 className="c-flow-step__title">
              <span className="c-flow-step__number">2｜</span>
              <span className="c-flow-step__text">体験ガイダンス日時の設定</span>
            </h3>
          </li>
          <li className={classNames('c-flow-steps__item', 'c-flow-step', 'has-content')}>
            <h3 className="c-flow-step__title">
              <span className="c-flow-step__number">3｜</span>
              <span className="c-flow-step__text">体験ガイダンス</span>
            </h3>
            <picture className="c-flow-step__image">
              <source media="(min-width: 64rem)" srcSet={flowStep3PCImage} width="400" height="214" />
              <img src={flowStep3SPImage} alt="" loading="lazy" width="272" height="158" />
            </picture>
            <p className="c-flow-step__target">（スマホで参加OK!）</p>
            <ul className="c-flow-step__subSteps">
              <li className="c-flow-step__subStep">
                <p className="c-flow-step__subStepText">
                  お子さまの学習状況や
                  <br className="u-hidden-pc" />
                  お悩みをヒアリング
                </p>
              </li>
              <li className="c-flow-step__subStep">
                <p className="c-flow-step__subStepText">
                  Fitのサービスや講習についての
                  <br className="u-hidden-pc" />
                  詳しいご説明と質疑応答
                </p>
              </li>
              <li className={classNames('c-flow-step__subStep', 'is-large')}>
                <p className="c-flow-step__subStepHead">講習お申し込み</p>
                <p className="c-flow-step__subStepText">
                  担当者と一緒に、お子さまに
                  <br className="u-hidden-pc" />
                  適切な受講プランを決めます
                </p>
              </li>
            </ul>
          </li>
          <li className={classNames('c-flow-steps__item', 'c-flow-step', 'has-content')}>
            <h3 className="c-flow-step__title">
              <span className="c-flow-step__number">4｜</span>
              <span className="c-flow-step__text">冬期講習スタート！</span>
            </h3>
            <p className="c-flow-step__label">中学生・高校生コース</p>
            <p className="c-flow-step__campaign">
              <span className="is-underline">4日間</span>の講習授業
              <span className="is-small">を</span>
              <br className="u-hidden-pc" />
              <span className="is-underline">無料で体験</span>できる!
            </p>
            <ul className="c-flow-step__attentions">
              <li className="c-flow-step__attention">※テキスト代は別途かかります。</li>
              <li className="c-flow-step__attention">※小学生コースは、体験授業を2回無料で受講できます。</li>
              <li className="c-flow-step__attention">※初めて受講される場合のみ無料で受講いただけます。</li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<FlowSectionPropsClassName>`
  padding-top: ${rem(80)};
  padding-bottom: ${rem(50)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(120)};
    padding-bottom: ${rem(80)};
  }

  .l-inner {
    width: ${innerSeasonWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(1080)};
      padding-left: ${rem(40)};
      padding-right: ${rem(40)};
    }
  }

  .c-flow-head {
    text-align: center;
    margin-bottom: ${rem(32)};
    color: ${winterColor.fitMain};
    font-size: ${rem(32)};
    font-weight: 700;
    font-family: ${fontFamily.zenKaku};
    letter-spacing: 0.15em;
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(50)};
      margin-bottom: ${rem(34)};
    }

    .is-small {
      font-size: ${rem(22)};

      @media ${breakpoints.lg} {
        font-size: ${rem(32)};
      }
    }
  }

  .c-flow-image {
    display: block;
    width: ${rem(174)};
    margin-top: ${rem(30)};
    margin-inline: auto;

    @media ${breakpoints.lg} {
      width: ${rem(240)};
      margin-bottom: ${rem(40)};
    }
  }

  .c-flow-steps {
    display: flex;
    gap: ${rem(58)};
    flex-direction: column;
    margin: ${rem(30)} 0 0;

    @media ${breakpoints.lg} {
      margin-top: ${rem(64)};
      width: ${rem(640)};
      margin-inline: auto;
      gap: ${rem(88)};
    }
  }

  .c-flow-steps__item {
    position: relative;

    &:not(:last-child) {
      &::after {
        position: absolute;
        content: '';
        width: ${rem(27.56)};
        height: ${rem(25)};
        background: url(${ArrowIcon}) no-repeat center center / contain;
        top: calc(100% + ${rem(15)});
        inset-inline: 0;
        margin-inline: auto;

        @media ${breakpoints.lg} {
          width: ${rem(47.25)};
          height: ${rem(42.84)};
          top: calc(100% + ${rem(20)});
        }
      }
    }
  }

  .c-flow-step {
    border: 1.5px solid ${winterColor.fitMain};
    border-radius: ${rem(10)};
    position: relative;
    list-style: none;
    margin: 0;
    padding: ${rem(10)} ${rem(30)};

    @media ${breakpoints.lg} {
      border-radius: ${rem(15)};
      padding: ${rem(12)} ${rem(30)};
    }

    &.has-content {
      padding-top: ${rem(24)};
      padding-bottom: ${rem(30)};

      @media ${breakpoints.lg} {
        padding-bottom: ${rem(30)};
      }
    }
  }

  .c-flow-step__title {
    font-weight: 500;
    font-family: ${fontFamily.zenKaku};
    display: flex;
    align-items: center;

    @media ${breakpoints.lg} {
      text-align: left;
    }
  }

  .c-flow-step__number {
    font-size: ${rem(24)};
    color: ${winterColor.fitMain};
    position: relative;
    white-space: nowrap;
    letter-spacing: -0.02em;

    @media ${breakpoints.lg} {
      font-size: ${rem(36)};
    }
  }

  .c-flow-step__text {
    font-size: ${rem(18)};
    color: ${winterColor.text};
    letter-spacing: 0.1em;

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
    }
  }

  .c-flow-step__image {
    margin-top: ${rem(24)};
    display: block;

    @media ${breakpoints.lg} {
      text-align: center;
      display: block;
      margin-inline: auto;
      width: ${rem(400)};
    }
  }

  .c-flow-step__target {
    font-size: ${rem(14)};
    letter-spacing: 0.1em;
    line-height: calc(18 / 14);
    text-align: center;
    color: ${winterColor.fitMain};
    margin: ${rem(20)} 0 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
    }
  }

  .c-flow-step__subSteps {
    margin: ${rem(12)} 0 0;
    display: flex;
    flex-direction: column;
    gap: ${rem(15 + 10 * 2)};

    @media ${breakpoints.lg} {
      width: ${rem(520)};
      margin-inline: auto;
      gap: ${rem(50)};
    }
  }

  .c-flow-step__subStep {
    list-style: none;
    text-align: center;
    border-radius: ${rem(60)};
    border: 1px solid ${winterColor.fitMain};
    padding: ${rem(8)} ${rem(10)};
    margin: 0;
    position: relative;

    &.is-large {
      padding-top: ${rem(20)};
      padding-bottom: ${rem(16)};
    }

    &:not(:last-child) {
      &::after {
        position: absolute;
        content: '';
        width: ${rem(2)};
        height: ${rem(15)};
        background-color: ${winterColor.fitMain};
        top: calc(100% + ${rem(10)});
        inset-inline: 0;
        margin-inline: auto;

        @media ${breakpoints.lg} {
          width: ${rem(2)};
          height: ${rem(20)};
          top: calc(100% + ${rem(16)});
        }
      }
    }
  }

  .c-flow-step__subStepHead {
    font-size: ${rem(18)};
    line-height: 1;
    letter-spacing: 0.1em;
    color: ${winterColor.fitMain};
    margin: 0 0 ${rem(10)};

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
      margin-bottom: ${rem(5)};
    }
  }

  .c-flow-step__subStepText {
    font-size: ${rem(14)};
    line-height: calc(21 / 14);
    letter-spacing: 0.1em;
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      line-height: 1.8;
    }
  }

  .c-flow-step__label {
    margin-top: ${rem(18)};
    text-align: center;
    font-size: ${rem(16)};
    letter-spacing: 0.1em;
    color: ${winterColor.fitMain};
    border-radius: ${rem(60)};
    border: 1px solid currentColor;
    padding: ${rem(4)};
    margin: ${rem(20)} 0 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
      width: ${rem(520)};
      margin-inline: auto;
    }
  }

  .c-flow-step__campaign {
    margin: ${rem(10)} 0 0;
    font-size: ${rem(26)};
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: calc(39 / 26);
    text-align: center;
    color: ${winterColor.fitMain};

    @media ${breakpoints.lg} {
      font-size: ${rem(28)};
      width: ${rem(520)};
      margin-inline: auto;
      margin-top: ${rem(8)};
    }

    .is-underline {
      border-bottom: 1px solid ${winterColor.fitMain};
    }

    .is-small {
      font-size: ${rem(16)};

      @media ${breakpoints.lg} {
        font-size: ${rem(17)};
      }
    }
  }

  .c-flow-step__attentions {
    margin: ${rem(8)} 0 0;
    display: flex;
    flex-direction: column;
    list-style: none;

    @media ${breakpoints.lg} {
      font-size: ${rem(28)};
      width: ${rem(520)};
      margin-inline: auto;
      margin-top: ${rem(20)};
    }
  }

  .c-flow-step__attention {
    font-size: ${rem(10)};
    margin: 0;
    line-height: calc(16 / 10);

    @media ${breakpoints.lg} {
      font-size: ${rem(12)};
    }
  }

  > .l-inner {
    > .e-flow-box {
      > .e-flow-boxContent1 {
        > .e-flow-box-left {
          .e-flow-boxLine {
          }
        }

        > .e-flow-box-right {
        }
      }

      > .e-flow-boxContent2 {
        margin: ${rem(32 / 2)} auto ${rem(40 / 2)};
        padding: 0 ${rem(60 / 2)};

        @media ${breakpoints.lg} {
          margin: ${rem(0)} auto ${rem(40)};
          padding: 0 ${rem(40)};
        }

        > p {
          margin-bottom: 0;
        }

        > .e-flow-boxText {
          font-size: ${rem(32 / 2)};
          line-height: 1.4;
          text-align: center;

          @media ${breakpoints.lg} {
            font-size: ${rem(22)};
            line-height: 1.8;
          }

          > .e-flow-boxFsMid {
            font-size: ${rem(32 / 2)};

            @media ${breakpoints.lg} {
              font-size: ${rem(24)};
            }
          }

          > .e-flow-boxEmp {
            font-size: ${rem(56 / 2)};
            font-weight: 500;
            color: ${winterColor.main};
            display: block;
            margin-left: auto;
            margin-right: auto;
            background-image: linear-gradient(transparent 60%, #fff777 0);
            width: fit-content;

            @media ${breakpoints.lg} {
              font-size: ${rem(40)};
              display: inline-block;
              margin-left: ${rem(10)};
              background-image: linear-gradient(transparent 60%, #fff777 60%, #fff777 90%, transparent 90%);
            }

            > small {
              font-size: ${rem(36 / 2)};

              @media ${breakpoints.lg} {
                font-size: ${rem(26)};
              }
            }
          }
        }

        > .e-flow-boxNote {
          list-style: none;
          margin: ${rem(16 / 2)} auto 0;
          font-size: ${rem(20 / 2)};
          line-height: 1.6;
          width: 100%;
          max-width: ${rem(640 / 2)};

          @media ${breakpoints.lg} {
            margin: ${rem(8)} auto 0 auto;
            font-size: ${rem(13)};
            line-height: 1.8;
            width: ${rem(464)};
            max-width: ${rem(464)};
          }

          > li {
            margin-bottom: 0;
            text-indent: -1em;
            padding-left: 1em;
            display: inline-block;
          }
        }

        .e-flow-boxLine {
          height: ${rem(50 / 2)};
          border-left: 2px solid ${winterColor.main};
          margin: ${rem(16 / 2)} auto ${rem(16 / 2)} 50%;

          @media ${breakpoints.lg} {
            height: ${rem(38)};
            border-left-width: 3px;
            margin-top: ${rem(16)};
            margin-bottom: ${rem(0)};
          }
        }
      }
    }

    > .e-flow-arrow {
      width: ${rem(76 / 2)};
      height: ${rem(34 / 2)};
      margin: ${rem(32 / 2)} auto;

      @media ${breakpoints.lg} {
        width: ${rem(76)};
        height: ${rem(34)};
        margin: ${rem(24)} auto;
      }
    }
  }
`

export const FlowSection: (props: FlowSectionProps) => JSX.Element = StyledComponent

export default FlowSection
