import React from 'react'
import styled from 'styled-components'
import { breakpoints, innerWidth, rem, innerPadding, innerDummyPadding } from 'variables/_index'

export interface CloseSectionProps {
  readonly npProps?: string
}

type CloseSectionPropsClassName = CloseSectionProps & {
  readonly className: string
}

function Component({ className }: CloseSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-close-text">
          お申し込みは終了しました。
          <br />
          ※次年度の冬期講習の詳細が決まり次第
          <br className="u-hidden-pc" />
          ページ更新いたします。
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CloseSectionPropsClassName>`
  padding-top: ${rem(30)};
  padding-bottom: ${rem(0)};
  overflow: hidden;

  @media ${breakpoints.lg} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(0)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-close-text {
      margin: ${rem(16)} 0;
      line-height: 1.6;
      text-align: center;
      font-weight: bold;
      font-size: ${rem(18)};
      color: red;

      @media ${breakpoints.lg} {
        font-size: ${rem(24)};
      }
    }
  }
`

export const CloseSection: (props: CloseSectionProps) => JSX.Element = StyledComponent

export default CloseSection
