import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, rem, winterColor } from 'variables/_index'

export interface ContactGirlHighProps {
  readonly noProps?: string
}

type ContactGirlHighPropsClassName = ContactGirlHighProps & {
  readonly className: string
}

function Component({ className }: ContactGirlHighPropsClassName) {
  return (
    <div className={className}>
      <div className="c-main">
        <StaticImage
          className="c-main__image"
          src="../../../../assets/images/winter/contact/cta_girl_high.png"
          alt=""
          placeholder="none"
          loading="lazy"
        />
      </div>
      <div className="c-snow1">
        <div className="c-snow1__fadeIn">
          <StaticImage className="c-snow1__image" src="../../../../assets/images/winter/mv/snow/snow8.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
      <div className="c-snow2">
        <div className="c-snow2__fadeIn">
          <StaticImage className="c-snow2__image" src="../../../../assets/images/winter/mv/snow/snow4.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
      <div className="c-snow3">
        <div className="c-snow3__fadeIn">
          <StaticImage className="c-snow3__image" src="../../../../assets/images/winter/mv/snow/snow1.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
      <div className="c-snow4">
        <div className="c-snow4__fadeIn">
          <StaticImage className="c-snow4__image" src="../../../../assets/images/winter/mv/snow/snow4.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
      <div className="c-snow5">
        <div className="c-snow5__fadeIn">
          <StaticImage className="c-snow5__image" src="../../../../assets/images/winter/mv/snow/snow9.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<ContactGirlHighPropsClassName>`
  position: relative;
  display: inline-block;
  width: ${rem(203)};

  @media ${breakpoints.lg} {
    width: ${rem(256.48)};
  }

  @keyframes DropContactGirlHigh {
    0% {
      opacity: 0;
      transform: translateY(${rem(-28)});
    }

    70% {
      opacity: 1;
      transform: translateY(${rem(12)});
    }

    100% {
      opacity: 0;
      transform: translateY(${rem(12)});
    }
  }

  @keyframes MoveContactGirlHigh {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(${rem(10)});
    }
    100% {
      transform: translateY(0);
    }
  }

  .c-main {
  }

  .c-main__image {
    width: ${rem(174)};

    @media ${breakpoints.lg} {
      width: ${rem(247.85)};
    }
  }

  .c-snow1,
  .c-snow2,
  .c-snow3,
  .c-snow4,
  .c-snow5 {
    position: absolute;
    top: 0;
    line-height: 0;
  }

  .c-snow1 {
    width: ${rem(17)};
    top: ${rem(13)};
    right: calc(50% + ${rem(76)});
    animation: MoveContactGirlHigh 5.1s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(22)};
      top: ${rem(16)};
      right: calc(50% + ${rem(105)});
    }
  }

  .c-snow2 {
    width: ${rem(9)};
    top: ${rem(44)};
    right: calc(50% + ${rem(60)});
    animation: MoveContactGirlHigh 5.6s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(16)};
      top: ${rem(68)};
      right: calc(50% + ${rem(76)});
    }
  }

  .c-snow3 {
    width: ${rem(20)};
    top: ${rem(23)};
    right: calc(50% + ${rem(16)});
    animation: MoveContactGirlHigh 4.6s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(31.25)};
      top: ${rem(32)};
      right: calc(50% + ${rem(20)});
    }
  }

  .c-snow4 {
    width: ${rem(9)};
    top: ${rem(14)};
    right: calc(50% + ${rem(2)});
    animation: MoveContactGirlHigh 4.8s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(14)};
      top: ${rem(9)};
      right: calc(50% + ${rem(-1)});
    }
  }

  .c-snow5 {
    width: ${rem(19)};
    top: ${rem(14)};
    left: calc(50% + ${rem(74)});
    animation: MoveContactGirlHigh 6.2s ease-in-out infinite backwards;

    @media ${breakpoints.lg} {
      width: ${rem(26)};
      top: ${rem(18)};
      left: calc(50% + ${rem(102)});
    }
  }
`

export const ContactGirlHigh: (props: ContactGirlHighProps) => JSX.Element = StyledComponent

export default ContactGirlHigh
